import axios from 'axios'
import {AuthModel, BaseModel} from './_models'
import {IProfileDetails} from '../../accounts/components/settings/SettingsModel'
const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESS_TOKEN_URL = `${API_URL}auth/verify-token`
export const LOGIN_URL = `${API_URL}auth/login`
export const REGISTER_URL = `${API_URL}auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}auth/forgot-password`
export const REQUEST_RESET_URL = `${API_URL}auth/reset-password`
export const REQUEST_PROFILE_UPDATE_URL = `${API_URL}users/profile/`
// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<BaseModel>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<AuthModel>(GET_USER_BY_ACCESS_TOKEN_URL, {
    token,
  })
}

export function resetPassword(token: string, password: string) {
  return axios.post<BaseModel>(REQUEST_RESET_URL, {
    token,
    password,
  })
}
export function updateProfile(
  currentUser: any,
  profile: IProfileDetails,
  file: any,
  location: string
) {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('firstName', profile.firstName)
  formData.append('lastName', profile.lastName)
  formData.append('companyName', profile.companyName)
  formData.append('city', profile.city)
  formData.append('state', location)
  formData.append('bio', profile.bio)
  formData.append('timezone', profile.timezone)
  if(profile.userWebsites) {
    formData.append('userWebsites', JSON.stringify(profile.userWebsites))
  }
  if(profile.certificatesIds) {
    formData.append('certificatesIds', JSON.stringify(profile.certificatesIds));
  }

  if(profile.removeProfileImage) {
    formData.append('removeProfileImage', 'true');
  }

  return axios.post<BaseModel>(`${REQUEST_PROFILE_UPDATE_URL}${currentUser.id}`, formData)
}
