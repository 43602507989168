import React, {useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'

import CourseModuleForm from './CourseModuleForm'
import {useAuth} from '../../../modules/auth'
import {ResourceItem} from './ResourceItem'
import {useUpdateCourseModuleProgress} from '../../../queries/courses'
import ModuleDetailModal from './ModuleDetailModal'
import ModuleNotes from './notes/ModuleNotes'

interface ViewModuleProps {
  course?: any
}

const ViewModule: React.FC<ViewModuleProps> = ({course = {}}) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [showEditModuleModal, setShowEditModuleModal] = useState(false)
  const [sectionModule, setSectionModule] = useState<any>({})
  const [isTranscriptExpanded, setIsTranscriptExpanded] = useState(false)
  const [showModuleDetailModal, setShowModuleDetailModal] = useState(false)
  const sectionId = searchParams.get('sectionId')
  const moduleId: any = searchParams.get('moduleId')
  const {selectedCommunity} = useAuth()
  const updateProgressMutation = useUpdateCourseModuleProgress()
  const [showModuleNotes, setShowModuleNotes] = useState(false)

  const setDefaultModule = () => {
    let module
    for (const section of course.courseSections || []) {
      module = section?.courseSectionModules.find((sectionModule: any) => !!sectionModule.id)
      if (module) {
        break
      }
    }

    module = module || course.courseModules?.[0]

    if (module) {
      const queryParams = new URLSearchParams()
      if (module.sectionId !== null) {
        queryParams.set('sectionId', module.sectionId)
      }
      queryParams.set('moduleId', module.id)
      navigate(`?${queryParams.toString()}`)
    }
  }

  useEffect(() => {
    if (!moduleId) {
      setDefaultModule()
    }
  }, [sectionId, moduleId])

  useEffect(() => {
    const section = course.courseSections?.find((section: any) => section.id === Number(sectionId))
    let courseModule = section?.courseSectionModules?.find(
      (sectionModule: any) => sectionModule.id === Number(moduleId)
    )
    if (!courseModule) {
      courseModule = course.courseModules?.find((module: any) => module.id === Number(moduleId))
    }
    setSectionModule(courseModule || {})
  }, [course, sectionId, moduleId])

  const handleUpdateProgress = async () => {
    if (updateProgressMutation.isPending) {
      return
    }
    try {
      await updateProgressMutation.mutateAsync({
        courseId: course.uuid,
        sectionId,
        moduleId: sectionModule.id,
        progressData: {isCompleted: !sectionModule.moduleProgress?.isCompleted},
      })
    } catch {}
  }

  const handleShowForm = () => {
    setShowEditModuleModal(true)
  }

  const toggleTranscript = () => {
    setIsTranscriptExpanded(!isTranscriptExpanded)
  }

  const renderTranscript = () => {
    const {transcript} = sectionModule

    const words = transcript.split(' ')
    const wordCount = words.length

    const maxWordCount = 125

    const showFullTranscript = wordCount <= maxWordCount || isTranscriptExpanded

    const content = showFullTranscript ? transcript : `${words.slice(0, maxWordCount).join(' ')}...`

    const buttonText = showFullTranscript ? 'Show less' : 'Show more'

    return (
      <>
        <p className='fs-5 mt-6' style={{whiteSpace: 'pre-line'}}>
          {content}
        </p>
        {wordCount > maxWordCount && (
          <button className='btn btn-link p-0 fs-6 text-primary' onClick={toggleTranscript}>
            {buttonText}
          </button>
        )}
      </>
    )
  }

  return (
    <>
      <div className='bg-light p-8 rounded border border-secondary'>
        {sectionModule?.id ? (
          <React.Fragment>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='module-title'>
                <h1>{sectionModule.title}</h1>
              </div>
              <div className='align-items-center justify-content-center d-flex'>
                {selectedCommunity?.isMember && (
                  <div className='mr10'>
                    <i
                      className={`bi cursor-pointer fs-1 bi-check-circle ${
                        sectionModule.moduleProgress?.isCompleted ? 'text-success' : ''
                      }`}
                      onClick={handleUpdateProgress}
                    ></i>
                  </div>
                )}
                <div>
                  <button
                    className='border p-3 border-secondary bg-light rounded'
                    onClick={() => setShowModuleNotes(true)}
                  >
                    <i className='fa-solid fa-book fs-4'></i>
                  </button>
                </div>
                <div>
                  <button
                    className='border p-3 border-secondary bg-light mx-3 rounded'
                    onClick={() => setShowModuleDetailModal(true)}
                  >
                    <i className='fa-solid fa-expand fs-4'></i>
                  </button>
                  {showModuleDetailModal && (
                    <ModuleDetailModal
                      showModuleDetailModal={showModuleDetailModal}
                      setShowModuleDetailModal={setShowModuleDetailModal}
                      sectionModule={sectionModule}
                    />
                  )}
                </div>
                {selectedCommunity?.permissions.canCreateEditCourse && (
                  <button
                    className='border p-3 border-secondary bg-light rounded'
                    onClick={handleShowForm}
                  >
                    <i className='fa-solid fs-4 fa-pencil'></i>
                  </button>
                )}
              </div>
            </div>
            {sectionModule.videoUrl ? (
              <div className='mt-5 rounded'>
                <iframe
                  src={sectionModule.videoUrl}
                  className='rounded view-module-video'
                  title='Module Video'
                  style={{width: '100%', height: '400px'}}
                  allowFullScreen
                ></iframe>
              </div>
            ) : null}
            <div
              className='mt-7 fs-4'
              dangerouslySetInnerHTML={{__html: sectionModule.description}}
            ></div>
            <div className='mt-13'>
              {sectionModule.courseSectionModuleResources.length > 0 && (
                <div>
                  <p className='fw-bold fs-5'>Resources</p>
                  {sectionModule.courseSectionModuleResources.map((resource: any) => (
                    <ResourceItem
                      key={resource.id}
                      resource={resource}
                      handleEditResource={() => {}}
                      handleDeleteResource={() => {}}
                      canEdit={false}
                    />
                  ))}
                </div>
              )}
              {sectionModule.transcript && (
                <div className='mt-8'>
                  <p className='fw-bold fs-5'>Transcript</p>
                  {renderTranscript()}
                </div>
              )}
            </div>
            {showEditModuleModal && (
              <CourseModuleForm
                show={showEditModuleModal}
                handleClose={() => setShowEditModuleModal(false)}
                course={course}
                sectionId={sectionModule.sectionId}
                courseModule={sectionModule}
              />
            )}
          </React.Fragment>
        ) : (
          <div>No module found</div>
        )}
      </div>
      {showModuleNotes && (
        <div>
          <ModuleNotes
            sectionModule={sectionModule}
            moduleId={moduleId}
            showModuleNotes={showModuleNotes}
            setShowModuleNotes={setShowModuleNotes}
          />
        </div>
      )}
    </>
  )
}

export default ViewModule
