import {useEffect, useState} from 'react'
import {Form} from 'react-bootstrap'
import {
  useUpdateUserNotificationSettings,
  useGetUserNotificationSettings,
} from '../../queries/notifications'

interface INotificationSettings {
  feeds: boolean
  comments: boolean
  likes: boolean
  messages: boolean
  events: boolean
  leaderboard: boolean
}

type FeatureType = 'feeds' | 'comments' | 'likes' | 'messages' | 'events' | 'leaderboard'

const NotificationSettings: React.FC = () => {
  const defaultSettings = {
    feeds: false,
    comments: false,
    likes: false,
    messages: false,
    events: false,
    leaderboard: false,
  }
  const updateNotificationSettingsMutation = useUpdateUserNotificationSettings()
  const notificationSettingsResult = useGetUserNotificationSettings()
  const [notificationEmailSettings, setNotificationEmailSettings] =
    useState<INotificationSettings>(defaultSettings)
  const [notificationAppSettings, setNotificationAppSettings] =
    useState<INotificationSettings>(defaultSettings)

  useEffect(() => {
    const settings = notificationSettingsResult.data
    setNotificationAppSettings(settings?.appSettings || defaultSettings)
    setNotificationEmailSettings(settings?.emailSettings || defaultSettings)
  }, [notificationSettingsResult.data])

  const handleEmailToggle = (featureType: FeatureType, value: boolean) => {
    setNotificationEmailSettings({
      ...notificationEmailSettings,
      [featureType]: value,
    })

    try {
      updateNotificationSettingsMutation.mutate({
        featureType,
        isEmailNotificationEnabled: value,
        isAppNotificationEnabled: notificationAppSettings[featureType],
      })
    } catch {
      setNotificationEmailSettings({
        ...notificationEmailSettings,
        [featureType]: !value,
      })
    }
  }

  const handleAppToggle = (featureType: FeatureType, value: boolean) => {
    setNotificationAppSettings({
      ...notificationAppSettings,
      [featureType]: value,
    })

    try {
      updateNotificationSettingsMutation.mutate({
        featureType,
        isAppNotificationEnabled: value,
        isEmailNotificationEnabled: notificationEmailSettings[featureType],
      })
    } catch {
      setNotificationAppSettings({
        ...notificationAppSettings,
        [featureType]: !value,
      })
    }
  }

  return (
    <div className='w-100 mt-4 ml20 mb-5'>
      <h2 className='mb-0'>User Notifications</h2>
      <div className='d-flex align-items-center border rounded p-4 mb-3 bg-light mt-9 shadow-sm w-100'>
        <div className='w-30'></div>
        <div className='w-75 d-flex justify-content-center' style={{columnGap: '110px'}}>
          <h3>E-mail Notification</h3>
          <h3>App Notification</h3>
        </div>
      </div>

      <div className='d-flex align-items-center border rounded p-4 mb-3 bg-light mt-9 shadow-sm w-100'>
        <div className='d-flex flex-column w-30'>
          <p className='fs-4 fw-bold mb-0'>Feeds</p>
          <span className='text-muted fw-bold fs-7 mt-2'>When my feed highlighted / pined</span>
        </div>
        <div className='w-75 d-flex justify-content-center' style={{columnGap: '202px'}}>
          <Form.Check
            type='switch'
            label={notificationEmailSettings.feeds ? 'On' : 'Off'}
            checked={notificationEmailSettings.feeds}
            onChange={(e) => handleEmailToggle('feeds', e.target.checked)}
            className={`cursor-pointer fw-bold ${
              notificationEmailSettings.feeds ? 'text-primary' : 'text-secondary'
            }`}
          />

          <Form.Check
            type='switch'
            label={notificationAppSettings.feeds ? 'On' : 'Off'}
            checked={notificationAppSettings.feeds}
            onChange={(e) => handleAppToggle('feeds', e.target.checked)}
            id='app-feeds'
            name='app-feeds'
            className={`cursor-pointer fw-bold ${
              notificationAppSettings.feeds ? 'text-primary' : 'text-secondary'
            }`}
          />
        </div>
      </div>

      <div className='d-flex align-items-center border rounded p-4 mb-3 bg-light mt-9 shadow-sm w-100'>
        <div className='d-flex flex-column w-30'>
          <p className='fs-4 fw-bold mb-0'>Comments</p>
          <span className='text-muted fw-bold fs-7 mt-2'>When someone Comment on my feed</span>
        </div>
        <div className='w-75 d-flex justify-content-center' style={{columnGap: '202px'}}>
          <Form.Check
            type='switch'
            label={notificationEmailSettings.comments ? 'On' : 'Off'}
            checked={notificationEmailSettings.comments}
            onChange={(e) => handleEmailToggle('comments', e.target.checked)}
            className={`cursor-pointer fw-bold ${
              notificationEmailSettings.comments ? 'text-primary' : 'text-secondary'
            }`}
          />

          <Form.Check
            type='switch'
            label={notificationAppSettings.comments ? 'On' : 'Off'}
            checked={notificationAppSettings.comments}
            onChange={(e) => handleAppToggle('comments', e.target.checked)}
            className={`cursor-pointer fw-bold ${
              notificationAppSettings.comments ? 'text-primary' : 'text-secondary'
            }`}
          />
        </div>
      </div>

      <div className='d-flex align-items-center border rounded p-4 mb-3 bg-light mt-9 shadow-sm w-100'>
        <div className='d-flex flex-column w-30'>
          <p className='fs-4 fw-bold mb-0'>Likes</p>
          <span className='text-muted fw-bold fs-7 mt-2'>
            When someone like my feed <br />
            Or like my feed Comment
          </span>
        </div>
        <div className='w-75 d-flex justify-content-center' style={{columnGap: '202px'}}>
          <Form.Check
            type='switch'
            label={notificationEmailSettings.likes ? 'On' : 'Off'}
            checked={notificationEmailSettings.likes}
            onChange={(e) => handleEmailToggle('likes', e.target.checked)}
            className={`cursor-pointer fw-bold ${
              notificationEmailSettings.likes ? 'text-primary' : 'text-secondary'
            }`}
          />

          <Form.Check
            type='switch'
            label={notificationAppSettings.likes ? 'On' : 'Off'}
            checked={notificationAppSettings.likes}
            onChange={(e) => handleAppToggle('likes', e.target.checked)}
            className={`cursor-pointer fw-bold ${
              notificationAppSettings.likes ? 'text-primary' : 'text-secondary'
            }`}
          />
        </div>
      </div>

      <div className='d-flex align-items-center border rounded p-4 mb-3 bg-light mt-9 shadow-sm w-100'>
        <div className='d-flex flex-column w-30'>
          <p className='fs-4 fw-bold mb-0'>Messages</p>
          <span className='text-muted fw-bold fs-7 mt-2'>When someone send message to me</span>
        </div>
        <div className='w-75 d-flex justify-content-center' style={{columnGap: '202px'}}>
          <Form.Check
            type='switch'
            label={notificationEmailSettings.messages ? 'On' : 'Off'}
            checked={notificationEmailSettings.messages}
            onChange={(e) => handleEmailToggle('messages', e.target.checked)}
            className={`cursor-pointer fw-bold ${
              notificationEmailSettings.messages ? 'text-primary' : 'text-secondary'
            }`}
          />

          <Form.Check
            type='switch'
            label={notificationAppSettings.messages ? 'On' : 'Off'}
            checked={notificationAppSettings.messages}
            onChange={(e) => handleAppToggle('messages', e.target.checked)}
            className={`cursor-pointer fw-bold ${
              notificationAppSettings.messages ? 'text-primary' : 'text-secondary'
            }`}
          />
        </div>
      </div>

      <div className='d-flex align-items-center border rounded p-4 mb-3 bg-light mt-9 shadow-sm w-100'>
        <div className='d-flex flex-column w-30'>
          <p className='fs-4 fw-bold mb-0'>Events</p>
          <span className='text-muted fw-bold fs-7 mt-2'>Event Reminders</span>
        </div>
        <div className='w-75 d-flex justify-content-center' style={{columnGap: '202px'}}>
          <Form.Check
            type='switch'
            label={notificationEmailSettings.events ? 'On' : 'Off'}
            checked={notificationEmailSettings.events}
            onChange={(e) => handleEmailToggle('events', e.target.checked)}
            className={`cursor-pointer fw-bold ${
              notificationEmailSettings.events ? 'text-primary' : 'text-secondary'
            }`}
          />

          <Form.Check
            type='switch'
            label={notificationAppSettings.events ? 'On' : 'Off'}
            checked={notificationAppSettings.events}
            onChange={(e) => handleAppToggle('events', e.target.checked)}
            className={`cursor-pointer fw-bold ${
              notificationAppSettings.events ? 'text-primary' : 'text-secondary'
            }`}
          />
        </div>
      </div>

      <div className='d-flex align-items-center border rounded p-4 mb-3 bg-light mt-9 shadow-sm w-100'>
        <div className='d-flex flex-column w-30'>
          <p className='fs-4 fw-bold mb-0'>LeaderBoard</p>
          <span className='text-muted fw-bold fs-7 mt-2'>When my leaderboard Level Update</span>
        </div>
        <div className='w-75 d-flex justify-content-center' style={{columnGap: '202px'}}>
          <Form.Check
            type='switch'
            label={notificationEmailSettings.leaderboard ? 'On' : 'Off'}
            checked={notificationEmailSettings.leaderboard}
            onChange={(e) => handleEmailToggle('leaderboard', e.target.checked)}
            className={`cursor-pointer fw-bold ${
              notificationEmailSettings.leaderboard ? 'text-primary' : 'text-secondary'
            }`}
          />

          <Form.Check
            type='switch'
            label={notificationAppSettings.leaderboard ? 'On' : 'Off'}
            checked={notificationAppSettings.leaderboard}
            onChange={(e) => handleAppToggle('leaderboard', e.target.checked)}
            className={`cursor-pointer fw-bold ${
              notificationAppSettings.leaderboard ? 'text-primary' : 'text-secondary'
            }`}
          />
        </div>
      </div>
    </div>
  )
}

export default NotificationSettings
