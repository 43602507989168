import React, {useRef} from 'react'
import {Formik, Field, ErrorMessage, FieldProps} from 'formik'
import * as Yup from 'yup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {Editor} from '@tinymce/tinymce-react'
import {Offcanvas} from 'react-bootstrap'
import Image from '../../modules/common/Image'

import {useCreateNote, useUpdateNote} from '../../queries/notes'
import {useAuth} from '../../modules/auth'

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
})

interface NoteFormProps {
  show?: any
  handleClose: any
  note?: {
    id: number | string
    title: string
    description: string
    moduleId: number | null
  }
  backdrop?: string | boolean
  placement?: any
  moduleId?: number | null
}

const defaultNote = {
  id: '',
  title: '',
  description: '',
  moduleId: null,
}

const NoteForm: React.FC<NoteFormProps> = ({
  show,
  backdrop = 'static',
  placement = 'end',
  handleClose,
  note = defaultNote,
  moduleId,
}) => {
  const {mutateAsync: createNote} = useCreateNote()
  const {mutateAsync: updateNote} = useUpdateNote()
  const editorRef = useRef<any>(null)
  const {currentUser} = useAuth()

  return (
    <Offcanvas
      size='lg'
      show={show}
      onHide={handleClose}
      placement={placement}
      backdrop={backdrop}
      enforceFocus={false}
    >
      <Offcanvas.Header className='m-0 px-5 border-bottom-0' closeButton>
        <div className='d-flex'>
          <Image src={`${currentUser?.absoluteProfilePath}`} />
          <div className='d-block mx-3'>
            <h3 className='m-0'>
              {currentUser?.firstName} {currentUser?.lastName}
            </h3>
            <p className='text-gray m-0'>{note?.id ? 'Updating Note....' : ' Posting Note.... '}</p>
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className='px-5 py-3'>
        <Formik
          initialValues={{
            title: note.title,
            description: note.description,
            moduleId,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, {setSubmitting, resetForm}) => {
            try {
              const {title, description} = values
              if (!note?.id) {
                await createNote({title, description, moduleId})
                handleClose()
              } else {
                await updateNote({noteId: note?.id, notesData: values})
                resetForm()
                handleClose()
              }
            } catch {
              // Handle any errors here
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({handleSubmit, handleChange, isValid, dirty}) => (
            <Form className='h-75' onSubmit={handleSubmit}>
              <Form.Group controlId='formGroupTitle'>
                <Field
                  name='title'
                  type='text'
                  placeholder='Title'
                  className='border-0 fs-1 fw-bold form-control'
                  onChange={handleChange}
                />
                <ErrorMessage name='title' component='div' className='mx-4 mb-3 text-danger' />
              </Form.Group>
              <div className='mt-4'>
                <Field name='description'>
                  {({field}: FieldProps<string>) => (
                    <Editor
                      onInit={(evt, editor) => {
                        editorRef.current = editor
                        const editorContainer = editor.targetElm
                        editorContainer.style.border = 'none'
                        editorContainer.style.fontSize = '14px'
                        editorContainer.style.marginLeft = '10px'
                        editorContainer.style.outline = 'none'
                      }}
                      tinymceScriptSrc={'/tinymce/tinymce.js'}
                      id={'description'}
                      value={field.value || ''}
                      init={{
                        menubar: false,
                        statusbar: false,
                        branding: false,
                        toolbar: false,
                        inline: true,
                        placeholder: 'Write something...',
                        plugins: ['quickbars', 'lists'],
                        quickbars_insert_toolbar: '',
                        quickbars_selection_toolbar:
                          'h1 h2 h3 bold italic underline forecolor bullist numlist',
                      }}
                      onEditorChange={(e: any) => {
                        handleChange({target: {name: 'description', value: e}})
                      }}
                    />
                  )}
                </Field>
              </div>

              <hr className='text-gray mt-9' />
              <div className='d-flex align-items-end justify-content-end mt-10'>
                <button type='button' className='mx-3 btn btn-secondary' onClick={handleClose}>
                  Discard
                </button>
                <Button type='submit' className='btn btn-primary' disabled={!dirty || !isValid}>
                  {note?.id ? 'Update Note' : 'Submit'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default NoteForm
