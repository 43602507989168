import {FC} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {WithChildren} from '../../../../_metronic/helpers'

type Props = {
  to: string
  title: string
  icon?: string
  reloadDocument?: boolean
  titleChildren?: any
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  reloadDocument = false,
  titleChildren,
}) => {
const location = useLocation()
  return (
    <div className='menu-item d-flex justify-content-center w150 mx-12' style={{borderRight: '1px solid lightgray'}}>
      <Link
        className={`menu-link without-sub mr20 flex-column ${location.pathname === to && 'border-black border border-2 rounded'}`}
        to={to}
        reloadDocument={reloadDocument}
      >
        <div>
          {icon && (
            <span className='menu-icon'>
              <img src={icon} alt="svg-icon" />
            </span>
          )}
        </div>
        <div style={{color: '#7E8299'}} className='menu-title'>
          {title}
          {titleChildren}
        </div>
      </Link>
      {children}
    </div>
  )
}

export {AsideMenuItem}
