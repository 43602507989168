import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import moment from 'moment-timezone'

import {LayoutSplashScreen} from '../../../layout/core'
import {AuthModel, CommunityModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import apiClient from '../../../apis/apiClient'
import {useGetUnreadNotificationsCount} from '../../../queries/notifications'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  unreadMessagesCount: number
  fetchUnreadMessagesCount: () => Promise<void>
  selectedCommunity: CommunityModel | undefined
  setSelectedCommunity: Dispatch<SetStateAction<CommunityModel | undefined>>
  getUserTimezone: () => string
  unreadNotificationsCount: number
  setUnreadNotificationsCount: Dispatch<SetStateAction<number>>
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  unreadMessagesCount: 0,
  fetchUnreadMessagesCount: async () => {},
  selectedCommunity: undefined,
  setSelectedCommunity: () => {},
  getUserTimezone: () => moment.tz.guess(),
  unreadNotificationsCount: 0,
  setUnreadNotificationsCount: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0)
  const jsonCommunity = localStorage.getItem('selectedCommunity')
  const [selectedCommunity, setSelectedCommunity] = useState<CommunityModel | undefined>(
    jsonCommunity ? JSON.parse(jsonCommunity) : null
  )

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    setSelectedCommunity(undefined)
  }

  const fetchUnreadMessagesCount = async () => {
    try {
      const response = await apiClient.get(`/c/${selectedCommunity?.uuid}/messages/unread-count`)
      const messageCount = response.data?.data?.count
      setUnreadMessagesCount(messageCount)
    } catch (error) {
      console.error('Error fetching unread messages count:', error)
    }
  }

  const getUserTimezone = () => {
    return currentUser?.timezone || moment.tz.guess()
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        unreadMessagesCount,
        fetchUnreadMessagesCount,
        selectedCommunity: selectedCommunity,
        setSelectedCommunity,
        getUserTimezone,
        unreadNotificationsCount,
        setUnreadNotificationsCount,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {
    auth,
    logout,
    currentUser,
    setCurrentUser,
    fetchUnreadMessagesCount,
    setSelectedCommunity,
    selectedCommunity,
    setUnreadNotificationsCount,
  } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const unreadNotificationsResult = useGetUnreadNotificationsCount(!!currentUser)

  useEffect(() => {
    setUnreadNotificationsCount(unreadNotificationsResult.data?.unreadNotificationsCount)
  }, [unreadNotificationsResult.data])

  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          if (data) {
            const user: any = data.data
            const selectedUserCommunity = user.userCommunities?.find(
              (community: any) => community.id === selectedCommunity?.id
            )
            setCurrentUser({...user, timezone: user.timezone || moment.tz.guess()})
            moment.tz.setDefault(user.timezone || moment.tz.guess())
            setSelectedCommunity(selectedUserCommunity)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.data.tokens.access.token) {
      requestUser(auth.data.tokens.access.token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
  }, [])

  useEffect(() => {
    if (currentUser) {
      fetchUnreadMessagesCount()
      const intervalId = setInterval(fetchUnreadMessagesCount, 30000) // 30 seconds

      return () => clearInterval(intervalId) // Cleanup the interval on component unmount
    }
  }, [currentUser])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
