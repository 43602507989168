import {ComingSoon} from '../../modules/common/ComingSoon'

const ProjectIndex = () => {
  return (
    <>
      <ComingSoon />
    </>
  )
}

export {ProjectIndex}
