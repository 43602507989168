import React, {useState} from 'react'
import {Button, Form, Modal, Spinner} from 'react-bootstrap'
import moment from 'moment'

import FileUploader from '../../../modules/common/FileUploader'

const ResourceForm = ({
  showModal,
  onHideModal,
  handleAddResource,
  handleUpdateResource,
  resource = {},
}: {
  showModal: boolean
  onHideModal: () => void
  handleAddResource: (newResource: any) => void
  handleUpdateResource: (resource: any) => void
  resource?: any
}) => {
  const [resourceType, setResourceType] = useState(
    resource.attachment?.fileType !== 'url' ? 'File' : 'URL'
  )
  const [errorMessage, setErrorMessage] = useState('')
  const [isUploadingResource, setIsUploadingResource] = useState(false)
  const [resourceData, setResourceData] = useState<{label: string; url: string; resourceFile: any}>(
    {
      label: resource.resourceLabel || '',
      url: resource.attachment?.fileType === 'url' ? resource.attachment?.originalFileName : '',
      resourceFile: resource.attachment?.fileType !== 'url' ? resource.attachment : {},
    }
  )

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setResourceData({...resourceData, label: e.target.value})
  }

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setResourceData({...resourceData, url: e.target.value})
  }

  const handleResourceTypeChange = (type: any) => {
    setResourceType(type)
  }

  const onResourceUpload = (result: any) => {
    // originalFileName, signedFileName, fileType
    setResourceData({...resourceData, resourceFile: result})
  }

  const isURLValid = (url: string): boolean => {
    // Regular expression for validating URLs
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
    return urlRegex.test(url)
  }

  const isFormValid = () => {
    if (!resourceData.label) {
      setErrorMessage('Label field is required')
      return false
    }

    if (resourceType === 'URL' && !isURLValid(resourceData.url)) {
      setErrorMessage('Please enter a valid url')
      return false
    }

    if (resourceType === 'File' && !resourceData.resourceFile?.signedFileName) {
      setErrorMessage('Please upload resource file')
      return false
    }

    setErrorMessage('')
    return true
  }

  const onSubmit = () => {
    if (!isFormValid()) {
      return
    }
    const attachment =
      resourceType === 'File'
        ? resourceData.resourceFile
        : {
            signedFileName: resourceData.url,
            originalFileName: resourceData.url,
            fileType: 'url',
          }
    const newResource = {
      id: resource.id || moment().valueOf(),
      resourceLabel: resourceData.label,
      attachment: attachment,
    }

    if (resource.id) {
      handleUpdateResource(newResource)
    } else {
      handleAddResource(newResource)
    }
    onHideModal()
  }

  return (
    <Modal show={showModal}>
      <Modal.Header>
        <Modal.Title>{resource.id ? 'Edit Resource' : 'Add Resource'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className='text-danger my-3'>{errorMessage}</div>
          <Form.Group controlId='resourceName'>
            <Form.Control
              type='text'
              placeholder='Label'
              value={resourceData.label}
              onChange={handleLabelChange}
            />
          </Form.Group>

          <h5 className='mt-8'>Resource Type :</h5>
          <div className='d-flex flex-row align-items-center'>
            <Form.Group className='mt-6'>
              <Form.Check
                type='radio'
                id='urlRadio'
                label='URL'
                checked={resourceType === 'URL'}
                onChange={() => {
                  handleResourceTypeChange('URL')
                }}
                disabled={resource.id || resourceData.resourceFile?.signedFileName}
              />
            </Form.Group>

            <Form.Group className='mt-5 mx-9'>
              <Form.Check
                type='radio'
                id='fileRadio'
                label='Upload File'
                checked={resourceType === 'File'}
                onChange={() => {
                  handleResourceTypeChange('File')
                }}
                disabled={resource.id}
              />
            </Form.Group>
          </div>

          {resourceType === 'URL' && (
            <Form.Group controlId='urlInput' className='mt-5'>
              <Form.Control
                type='text'
                placeholder='Enter Your URL....'
                onChange={handleUrlChange}
                value={resourceData.url}
              />
            </Form.Group>
          )}

          {resourceType === 'File' && (
            <div className='mt-5 w-100'>
              {resourceData.resourceFile?.signedFileName && (
                <div>
                  {' '}
                  <i className='fa-solid bg-primary text-white p-2 rounded fs-6 cursor-pointer fa-file'></i>{' '}
                  {resourceData.resourceFile.originalFileName}
                </div>
              )}
              {!resourceData.resourceFile?.signedFileName && (
                <FileUploader
                  resourceType='courses'
                  isUploading={isUploadingResource}
                  setIsUploading={setIsUploadingResource}
                  onFileUpload={onResourceUpload}
                  btn={
                    <label htmlFor='fileInput' className='btn btn-light w-100'>
                      {isUploadingResource ? (
                        <>
                          <Spinner animation='border' size='sm' />
                          <span className='mx-3'>Uploading....</span>
                        </>
                      ) : (
                        <>
                          Upload File
                          <span>
                            <i className='bi fs-4 mx-3 fw-bold bi-upload'></i>
                          </span>
                        </>
                      )}
                    </label>
                  }
                />
              )}
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHideModal} disabled={isUploadingResource}>
          Close
        </Button>
        <Button variant='primary' onClick={onSubmit} disabled={isUploadingResource}>
          {resource.id ? 'Update' : 'Add'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ResourceForm
