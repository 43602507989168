import apiClient from './apiClient'
import {getSelectedCommunityId} from '../modules/common/utils'

interface GetApiParams {
  page?: number
  limit?: number
  search?: string
  sortField?: string
  sortOrder?: string
  customerId?: number | string
}

export const fetchUsers = async (params: GetApiParams): Promise<any> => {
  const response = await apiClient.get(`/communities/${getSelectedCommunityId()}/users`, {
    params,
  })
  return response.data?.data || []
}

export const fetchUserThreads = async (params: GetApiParams): Promise<any> => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/threads`, {
    params,
  })
  return response.data?.data || []
}

export const fetchUserDetails = async (id: number | string): Promise<any> => {
  const response = await apiClient.get(`/users/profile/${id}`)
  return response.data?.data || {}
}

export const fetchThreadMessages = async (
  id: number | string | null,
  params: GetApiParams
): Promise<any> => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/threads/${id}/messages`, {
    params,
  })
  return response.data?.data || []
}

export const postThread = async (data: any) => {
  const response = await apiClient.post(`/c/${getSelectedCommunityId()}/threads`, data)
  return response.data?.data || []
}

export const postThreadMessage = async (id: any, data: any) => {
  const response = await apiClient.post(
    `/c/${getSelectedCommunityId()}/threads/${id}/messages`,
    data
  )
  return response.data?.data || []
}

export const updateThreadStatus = async (id: any, data: any) => {
  const response = await apiClient.put(`/c/${getSelectedCommunityId()}/threads/${id}`, data)
  return response.data?.data || {}
}
