const LevelsInfo = ({communityName}: {communityName: string | undefined}) => {
  return (
    <div className='p-5'>
      <div>
        <h3 className='modal-title'>{communityName} points</h3>
      </div>
      <div>
        <div className='mb-6 mt-6'>
          <h5 className='fw-bold'>Points</h5>
          <p className='fs-6'>
            You earn points when other members like your posts or comments. 1 like = 1 point. This
            encourages users to produce quality content and interact with other members in their
            community.
          </p>
        </div>
        <div className='mb-6'>
          <h5 className='fw-bold'>Levels</h5>
          <p className='fs-6'>
            As you gain points, you level up. Your level is shown at the bottom right of your
            avatar. The number of points required to get to the next level is shown under your
            avatar on your profile page.
          </p>
        </div>
        <div className='row'>
          <div className='col'>
            <p className='fs-6'>Level 1 - 0 points</p>
            <p className='fs-6'>Level 2 - 5 points</p>
            <p className='fs-6'>Level 3 - 20 points</p>
            <p className='fs-6'>Level 4 - 65 points</p>
            <p className='fs-6'>Level 5 - 155 points</p>
          </div>
          <div className='col'>
            <p className='fs-6'>Level 6 - 515 points</p>
            <p className='fs-6'>Level 7 - 2,015 points</p>
            <p className='fs-6'>Level 8 - 8,015 points</p>
            <p className='fs-6'>Level 9 - 33,015 points</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LevelsInfo
