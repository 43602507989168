import {getSelectedCommunityId} from '../modules/common/utils'
import apiClient from './apiClient'

export const getUserNotificationSettings = async () => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/notifications/settings`)
  return response.data?.data || []
}

export const getUserNotifications = async () => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/notifications`)
  return response.data?.data || []
}

export const getUnreadNotificationsCount = async () => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/notifications/unread`)
  return response.data?.data || []
}

export const updateUserNotificationSettings = async (data: any) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/notifications/settings`,
    data
  )
  return response.data?.data || {}
}

export const readUserNotification = async (notificationId?: number) => {
  const response = await apiClient.put(`/c/${getSelectedCommunityId()}/notifications/read`, {
    notificationId,
  })
  return response.data?.data || {}
}
