import {Fragment} from 'react'
import UserChats from './UserChats'
import UserChatMessages from './UserChatMessages'

import './GovChat.scss'

function GovChatIndex() {
  return (
    <Fragment>
      <div className='w-25 user-chat-left-section'>
        <UserChats />
      </div>

      <div className='w-85 user-chat-main-section d-flex justify-content-center'>
        <UserChatMessages />
      </div>
    </Fragment>
  )
}

export default GovChatIndex
