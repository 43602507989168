import {Link, useLocation} from 'react-router-dom'
import {useAuth} from '../../../modules/auth/core/Auth'
import {Badge} from 'react-bootstrap'
import {isProfileIncomplete} from '../../../modules/common/utils'

const SubHeader = () => {
  const location = useLocation()
  const {currentUser, selectedCommunity} = useAuth()
  const isPremiumUser = selectedCommunity?.isPremiumUser
  const isProfileIncompleted = isProfileIncomplete(currentUser)

  return (
    <div className='lg-header'>
      <ul className={`nav justify-content-center ${isProfileIncompleted && 'incomplete-profile'}`}>
        <li className='nav-item'>
          <Link
            className={`nav-link fs-4 ${location.pathname === '/feeds/public' && 'active-link'}`}
            aria-current='page'
            to='/feeds/public'
          >
            Community
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link fs-4' to='/coming-soon'>
            CRM
          </Link>
        </li>
        {isPremiumUser && (
          <li className='nav-item'>
            <Link className='nav-link fs-4' to='/coming-soon'>
              Pipeline
            </Link>
          </li>
        )}
        <li className='nav-item'>
          <Link className='nav-link fs-4' to='/coming-soon'>
            Bid Board
          </Link>
        </li>
        {isPremiumUser && (
          <li className='nav-item'>
            <Link
              className={`nav-link fs-4 ${location.pathname === '/support' && 'active-link'}`}
              to={`/support?userId=${currentUser?.id}`}
            >
              Support
            </Link>
          </li>
        )}
        <li className='nav-item'>
          <Link
            className={`nav-link fs-4 ${location.pathname === '/courses' && 'active-link'}`}
            to={`/courses`}
          >
            Classroom
          </Link>
        </li>
        {isPremiumUser && (
          <li className='nav-item'>
            <Link
              className={`nav-link fs-4 ${
                location.pathname.startsWith('/govchat') && 'active-link'
              }`}
              aria-current='page'
              to='/govchat'
            >
              GovChat
              <Badge className='position-absolute' style={{top: '75px'}} bg='success'>
                <span className='text-white'>AI</span>
              </Badge>
            </Link>
          </li>
        )}
      </ul>
    </div>
  )
}

export default SubHeader
