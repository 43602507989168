import React from 'react'
import {Modal} from 'react-bootstrap'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'

import {usePostNewThread} from '../../../queries/threads'

interface NewThreadModalProps {
  showChatModal: boolean
  setShowChatModal: (boolean: boolean) => void
  selectedUser: any
}

const NewThreadModal: React.FC<NewThreadModalProps> = ({
  showChatModal,
  setShowChatModal,
  selectedUser,
}) => {
  const validationSchema = Yup.object().shape({
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required'),
  })

  const initialValues = {
    subject: '',
    message: '',
  }

  const {mutateAsync: postNewThread, isPending: isSending} = usePostNewThread()
  return (
    <div>
      <Modal
        size='lg'
        show={showChatModal}
        onHide={() => setShowChatModal(false)}
        centered
        backdrop='static'
      >
        <Modal.Header closeButton>
          <h6>Start chat with {selectedUser.fullName}</h6>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, {resetForm}) => {
              try {
                await postNewThread({...values, customerId: selectedUser.id})
                setShowChatModal(false)
                resetForm()
              } catch {}
            }}
          >
            {({errors, touched}) => (
              <Form>
                <div>
                  <div className='d-flex my-2 justify-content-between align-items-center'>
                    <div>
                      <h5 className='required'>Subject</h5>
                    </div>
                    <div className='w-75'>
                      <Field
                        name='subject'
                        className={`w-100 py-4 px-2 rounded border-0 bg-light outline-none ${
                          touched.subject && errors.subject && 'is-invalid'
                        }`}
                        type='text'
                        placeholder='Enter subject here'
                      />
                      {touched.subject && errors.subject && (
                        <div className='invalid-feedback'>{errors.subject}</div>
                      )}
                    </div>
                  </div>
                  <div className='d-flex mt-5 mb-3 justify-content-between align-items-start'>
                    <div>
                      <h6 className='required'>Message</h6>
                    </div>
                    <div className='w-75'>
                      <Field
                        name='message'
                        as='textarea'
                        className={`w-100 p-2 rounded border-0 bg-light outline-none resize-none ${
                          touched.message && errors.message && 'is-invalid'
                        }`}
                        rows={6}
                        placeholder='Enter message here'
                      />
                      {touched.message && errors.message && (
                        <div className='invalid-feedback'>{errors.message}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end '>
                  <button className='btn btn-primary' type='submit'>
                    {isSending ? 'Sending...' : 'Send'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default NewThreadModal
