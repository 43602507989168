import {Spinner} from 'react-bootstrap'

const GovChatSpinner = () => {
  return (
    <div className=' d-flex align-items-center'>
      <div>
        <img className='w20 ml5 mr15 govchat-logo' src='/media/logos/ai-logo.svg' alt='ai-logo' />
      </div>
      <span className='ml5 fs-4'>Govchat is thinking...</span>
      <Spinner size='sm' className='ml10' animation='grow' variant='primary' />
    </div>
  )
}

export default GovChatSpinner
