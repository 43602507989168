import React, {useEffect, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {useNavigate, useSearchParams} from 'react-router-dom'

import CustomDropDown from '../../../modules/common/CustomDropDown'
import CourseModuleForm from './CourseModuleForm'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import {useDeleteCourseModule, useUpdateModuleSection} from '../../../queries/courses'
import {useAuth} from '../../../modules/auth'

interface CourseModuleProps {
  sectionModule: any
  course: any
  sectionStatus?: string
}

const CourseModule: React.FC<CourseModuleProps> = ({course, sectionModule, sectionStatus}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const deleteMutation = useDeleteCourseModule()
  const {selectedCommunity} = useAuth()
  const updateModuleSection = useUpdateModuleSection()
  const [showChangeSectionModal, setShowChangeSectionModal] = useState(false)
  const [showEditModuleModal, setShowEditModuleModal] = useState(false)
  const [showDeleteModuleModal, setShowDeleteModuleModal] = useState(false)
  const [isHoveringModule, setIsHoveringModule] = useState(false)
  const [newSectionId, setNewSectionId] = useState(sectionModule.sectionId || 'none')
  const [selectedModuleId, setSelectedModuleId] = useState<number>()
  const moduleId = searchParams.get('moduleId')

  useEffect(() => {
    if (moduleId) setSelectedModuleId(Number(moduleId))
  }, [moduleId])

  const handleViewModule = () => {
    const queryParams = new URLSearchParams()

    if (sectionModule.sectionId) {
      queryParams.set('sectionId', sectionModule.sectionId)
    }
    queryParams.set('moduleId', sectionModule.id)

    navigate(`?${queryParams.toString()}`)
  }

  const handleUpdateModuleSection = async () => {
    try {
      if (newSectionId) {
        await updateModuleSection.mutateAsync({
          courseId: course.uuid,
          moduleId: sectionModule.id,
          newSectionId: newSectionId === 'none' ? null : newSectionId,
        })
        setShowChangeSectionModal(false)
        setNewSectionId(0)
        navigate(`?sectionId=${newSectionId}&moduleId=${sectionModule.id}`)
      }
    } catch {}
  }

  const handleDeleteSectionModule = async () => {
    try {
      await deleteMutation.mutateAsync({
        courseId: course?.uuid,
        sectionId: sectionModule.sectionId,
        moduleId: sectionModule.id,
      })
      setShowDeleteModuleModal(false)
      navigate('')
    } catch {}
  }

  const moduleOptions = [
    {label: 'Edit Module', handler: () => setShowEditModuleModal(true)},
    {label: 'Change Section', handler: () => setShowChangeSectionModal(true)},
    {label: 'Delete Module', handler: () => setShowDeleteModuleModal(true)},
  ]

  return (
    <div
      className={`MenuItemTitleWrapper d-flex align-items-center justify-content-between ${
        selectedModuleId === sectionModule.id ? 'bg-secondary' : ''
      }`}
      onClick={handleViewModule}
      onMouseEnter={() => setIsHoveringModule(true)}
      onMouseLeave={() => setIsHoveringModule(false)}
    >
      <div>
        {sectionStatus === 'draft'
          ? `(Draft) ${
              sectionModule.title.length > 30
                ? sectionModule.title.slice(0, 30) + '...'
                : sectionModule.title
            }`
          : sectionModule.title.length > 30
          ? sectionModule.title.slice(0, 30) + '...'
          : sectionModule.title}
      </div>
      {selectedCommunity?.permissions.canCreateEditCourse && (
        <div
          className='CustomDropDown'
          style={{visibility: isHoveringModule ? 'visible' : 'hidden'}}
        >
          <CustomDropDown elementId='module-dropdown' options={moduleOptions} />
        </div>
      )}
      <div>
        <div>
          {!isHoveringModule && (
            <i
              className={` cursor-pointer fs-1  position-absolute ${
                sectionModule.moduleProgress?.isCompleted
                  ? 'bi mx-9 bi-check-circle text-success'
                  : ''
              }`}
              style={{top: '10px', right: '-16px'}}
            ></i>
          )}
        </div>
      </div>

      {showChangeSectionModal && (
        <Modal
          show={showChangeSectionModal}
          centered
          onHide={() => setShowChangeSectionModal(false)}
          backdrop='static'
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Section</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Select
              className='form-select form-select-solid'
              onChange={(e: any) => setNewSectionId(e.target.value)}
              defaultValue={sectionModule.sectionId}
            >
              <option value={'none'}>None</option>
              {course?.courseSections.map((section: any) => (
                <option key={section.id} value={section.id}>
                  {section.sectionName}
                </option>
              ))}
            </Form.Select>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              disabled={updateModuleSection.isPending}
              onClick={() => setShowChangeSectionModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              disabled={updateModuleSection.isPending}
              onClick={handleUpdateModuleSection}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showEditModuleModal && (
        <CourseModuleForm
          show={showEditModuleModal}
          handleClose={() => setShowEditModuleModal(false)}
          course={course}
          sectionId={sectionModule.sectionId}
          courseModule={sectionModule}
        />
      )}
      {showDeleteModuleModal && (
        <ConfirmationModal
          showModal={showDeleteModuleModal}
          onHideModal={() => setShowDeleteModuleModal(false)}
          subtitle='Are you sure you want to delete this module?'
          onConfirm={handleDeleteSectionModule}
          onCancel={() => setShowDeleteModuleModal(false)}
          disableBtns={deleteMutation.isPending}
        />
      )}
    </div>
  )
}

export default CourseModule
