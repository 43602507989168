import copy from 'copy-to-clipboard'
import Markdown from 'react-markdown'
import {toast} from 'react-toastify'
import {useAuth} from '../../../modules/auth'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const ChatMessage = ({msgData}: {msgData: any}) => {
  const {currentUser} = useAuth()

  const renderTooltip = (props: any) => (
    <Tooltip id='button-tooltip' {...props}>
      Copy to clipboard
    </Tooltip>
  )
  return (
    <div key={msgData.id} className='align-items-center'>
      <div className={`py-2 d-flex ${msgData.isAiResponse && 'align-items-center'}`}>
        {msgData.isAiResponse && (
          <div
            className={`d-flex ${
              msgData.message.length > 20 ? 'align-items-start' : 'align-items-center'
            }`}
          >
            <div>
              <img className='w20 ml15' src='/media/logos/ai-logo.svg' alt='ai-logo' />
            </div>
            <div className={`ml25 fs-4 ${msgData.message.length < 40 && 'mt-3'} message-response`}>
              <Markdown>{msgData.message}</Markdown>
              <div>
                <OverlayTrigger
                  placement='bottom'
                  delay={{show: 200, hide: 250}}
                  overlay={renderTooltip}
                >
                  <i
                    className='fa-regular fa-copy mx-1 cursor-pointer fs-5'
                    onClick={() => {
                      copy(msgData.message)
                      toast.success('Text copied to clipboard!')
                    }}
                  ></i>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        )}

        {!msgData.isAiResponse && (
          <div
            className={`d-flex ${
              msgData.message.length > 90 ? 'align-items-start' : 'align-items-center'
            }`}
          >
            <div className='p-2'>
              <img
                className='h40 w40 rounded-circle'
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
                src={currentUser?.absoluteProfilePath}
                alt='img-icon'
              />
            </div>
            <div className='mx-3 fs-4'>{msgData.message}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ChatMessage
