import {CommunityModel} from '../modules/auth'
import {getSelectedCommunityId} from '../modules/common/utils'
import apiClient from './apiClient'

export const putCommunitySettings = async (updateCommunity: any) => {
  const response = await apiClient.put(`/communities/${getSelectedCommunityId()}`, updateCommunity)
  return response.data || {}
}

export const getCommunitySettings = async () => {
  const response = await apiClient.get(`/communities/${getSelectedCommunityId()}`)
  return response.data?.data || {}
}

export const getCommunityLeaderBoard = async () => {
  const response = await apiClient.get(`/communities/${getSelectedCommunityId()}/leaderboards`)
  return response.data?.data || {}
}

export const getCommunityLeaderBoardByType = async (type: string) => {
  const response = await apiClient.get(
    `/communities/${getSelectedCommunityId()}/leaderboards/${type}`
  )
  return response.data?.data || {}
}
