import {useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {Button} from 'react-bootstrap'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import Select from 'react-select'

import RecurringRule from './recurring/RecurringRule'
import {useCreateSingleEvent, useUpdateEvent, useUpdateSeries} from '../../../queries/events'
import {RecOptionsProp, durationOptions, locationOptions} from '../utils.events'
import {
  getTimezoneOptions,
  rewriteDateInOtherTimezone,
  createDateFromMoment,
} from '../../../modules/common/utils'

import 'react-datepicker/dist/react-datepicker.css'
import SelectContentType from '../../../modules/common/SelectContentType'

const defaultRecOptions: RecOptionsProp = {
  freq: '3',
  interval: 1,
  byweekday: [],
  bymonthday: [],
}

const EventsForm = ({
  onSubmitEventForm,
  selectedEvent,
  actionType,
}: {
  onSubmitEventForm?: any
  selectedEvent?: any
  actionType?: string
}) => {
  const [isRecurring, setIsRecurring] = useState(false)
  const [recOptions, setRecOptions] = useState<RecOptionsProp>(defaultRecOptions)
  const {mutateAsync: createSingleEvent, isPending} = useCreateSingleEvent()
  const {mutateAsync: updateSingleEvent} = useUpdateEvent()
  const {mutateAsync: updateSeries} = useUpdateSeries()
  const timezoneOptions = getTimezoneOptions()
  const startDateMoment = selectedEvent?.startDate
    ? moment.utc(selectedEvent?.startDate).tz(selectedEvent?.timezone)
    : null

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .max(100, 'Event title is no longer than 100 characters')
      .required('Title is required'),
    description: Yup.string().optional(),
    startDate: Yup.date().required('Start Date is required'),
    timezone: Yup.string().required('Timezone is required'),
    eventLength: Yup.number().required('Event length is required'),
    location: Yup.object().shape({
      type: Yup.string().optional(),
      value: Yup.string().optional(),
    }),
    packageId: Yup.string().optional(),
  })

  const initialValues = {
    title: selectedEvent?.originalTitle || selectedEvent?.title || '',
    description: selectedEvent?.description || '',
    startDate: startDateMoment ? createDateFromMoment(startDateMoment) : new Date(),
    timezone: selectedEvent?.timezone || moment.tz.guess(),
    eventLength: selectedEvent?.eventLength || 3600,
    location: selectedEvent?.location || {},
    packageId: selectedEvent?.packageId || '',
  }

  const onSubmit = async (values: any, {resetForm}: any) => {
    const startDate = values.startDate
    const eventData = {
      ...values,
      location: values.location.type && values.location.value ? values.location : undefined,
      startDate: rewriteDateInOtherTimezone(startDate, values.timezone),
      recOptions: isRecurring ? recOptions : undefined,
    }
    if (selectedEvent?.parentEventId) {
      await updateSeries({
        eventId: selectedEvent?.parentEventId,
        eventData: {
          ...eventData,
          recOptions: undefined,
          originalTimestamp: selectedEvent.originalTimestamp,
          actionType,
        },
      })
    } else if (selectedEvent) {
      await updateSingleEvent({eventId: selectedEvent?.id, eventData})
    } else {
      await createSingleEvent(eventData)
    }
    if (onSubmitEventForm) {
      onSubmitEventForm()
    }
    resetForm()
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({values, setFieldValue}) => (
        <Form>
          <div>
            <label className='my-2 required fs-6 fw-bold' htmlFor='title'>
              Title
            </label>
            <Field
              type='text'
              id='title'
              name='title'
              className='form-control'
              placeholder='Title'
            />
            <ErrorMessage name='title' component='div' className='text-danger' />
          </div>

          <div className='row'>
            <div className='col-4'>
              <label className='my-2 required fs-6 fw-bold d-block' htmlFor='startDate'>
                Start Date
              </label>
              <DatePicker
                showTimeSelect
                className='form-control'
                selected={values.startDate}
                onChange={(date) => setFieldValue('startDate', date)}
                name='startDate'
                timeInputLabel='Time:'
                dateFormat='MM/dd/yyyy h:mm aa'
              />
              <ErrorMessage name='startDate' component='div' className='text-danger' />
            </div>

            <div className='col-4'>
              <label className='my-2 required fs-6 fw-bold' htmlFor='duration'>
                Duration
              </label>
              <select
                id='duration'
                name='duration'
                className='form-control'
                value={values.eventLength}
                onChange={(e) => setFieldValue('eventLength', Number(e.target.value))}
              >
                {durationOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className='col-4'>
              <label className='my-2 required fs-6 fw-bold d-block' htmlFor='timezone'>
                Timezone
              </label>
              <Select
                id='timezone'
                name='timezone'
                className='form-control p-0'
                value={timezoneOptions.find((option) => option.value === values.timezone)}
                onChange={(selectedOption: any) => setFieldValue('timezone', selectedOption.value)}
                options={timezoneOptions}
              />
              <ErrorMessage name='timezone' component='div' className='text-danger' />
            </div>
          </div>

          <div className='d-flex'>
            <div>
              <label className='my-2 fs-6 fw-bold' htmlFor='location.type'>
                Location
              </label>
              <Select
                id='locationType'
                name='location.type'
                className='w200'
                value={locationOptions.find((option: any) => option.value === values.location.type)}
                onChange={(option: any) => setFieldValue('location.type', option.value)}
                options={locationOptions}
              />
              <ErrorMessage name='location.type' component='div' className='text-danger' />
            </div>
            <div className='ml15 w-100'>
              <label className='my-2 fs-6 fw-bold' htmlFor='location.value'>
                {values.location?.type === 'address' ? 'Address' : 'Link'}
              </label>
              <Field
                type='text'
                id='locationValue'
                name='location.value'
                className='form-control'
                placeholder={values.location?.type === 'address' ? 'Address' : 'Link'}
                value={values.location.value}
                onChange={(e: any) => setFieldValue('location.value', e.target.value)}
              />
              <ErrorMessage name='location.value' component='div' className='text-danger' />
            </div>
          </div>

          <div className='form-check my-4 mb-6'>
            <input
              className='form-check-input cursor-pointer'
              type='checkbox'
              id='recurringEvent'
              checked={isRecurring}
              disabled={selectedEvent?.parentEventId}
              onChange={() => setIsRecurring(!isRecurring)}
            />
            <label
              className='form-check-label fs-6 fw-bold text-black cursor-pointer'
              htmlFor='recurringEvent'
            >
              Recurring event
            </label>
          </div>

          <div>
            <SelectContentType
              value={values.packageId}
              handleOnChange={(e) => setFieldValue('packageId', e.target.value)}
              label='Who can attend this Event:'
            />
          </div>

          {isRecurring && (
            <RecurringRule
              startDate={values.startDate}
              recOptions={recOptions || {}}
              setRecOptions={setRecOptions}
            />
          )}

          <div>
            <label className='my-2 fs-6 fw-bold' htmlFor='description'>
              Description
            </label>
            <Field
              type='text'
              as='textarea'
              rows={3}
              id='description'
              name='description'
              className='form-control'
              placeholder='Description'
            />
          </div>

          <div className='d-end my-3'>
            <Button variant='primary' type='submit' disabled={isPending}>
              {isPending ? 'Loading...' : 'Submit'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default EventsForm
