const PreviewAttachments = ({
  attachments,
  selectedAttachmentIndex,
  setSelectedAttachmentIndex,
  handleModalClose,
}: any) => {
  const selectedAttachment = attachments[selectedAttachmentIndex]
  const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL
  const attachmentUrl = s3BaseUrl + selectedAttachment.signedFileName

  const onClickNext = () => {
    if (selectedAttachmentIndex < attachments.length - 1) {
      setSelectedAttachmentIndex(selectedAttachmentIndex + 1)
    }
  }

  const onClickPrevious = () => {
    if (selectedAttachmentIndex > 0) {
      setSelectedAttachmentIndex(selectedAttachmentIndex - 1)
    }
  }

  const hasUnsupportedFormat = () => {
    if (!selectedAttachment || !selectedAttachment.fileType) {
      return true
    }

    // List of supported file types
    const supportedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg', 'media']

    if (!supportedTypes.includes(selectedAttachment.fileType)) {
      return true
    }

    return false
  }

  const downloadAttachment = () => {
    const anchor = document.createElement('a')
    anchor.href = attachmentUrl
    anchor.download = selectedAttachment.originalFileName
    anchor.click()
  }

  return (
    <div>
      <div
        className='modal fade show d-block'
        tabIndex={-1}
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
        onClick={handleModalClose}
      >
        <div className='modal-dialog modal-fullscreen' role='document'>
          <div className='modal-content attachments-modal' onClick={(e) => e.stopPropagation()}>
            <div className='modal-header border-0 py-5'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <h2 className='text-light text-size'>{selectedAttachment.originalFileName}</h2>
                <div>
                  {selectedAttachment.fileType.startsWith('image/') && (
                    <div onClick={downloadAttachment}>
                      <svg
                        viewBox='0 0 33 40'
                        className='svg-icon border-0 h40 w40 rounded-circle px-4 py-1 bg-light mx-3 cursor-pointer'
                        fill='black'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M0 40H32.9412V35.2941H0V40ZM32.9412 14.1176H23.5294V0H9.41177V14.1176H0L16.4706 30.5882L32.9412 14.1176Z'></path>
                      </svg>
                    </div>
                    // <i className='bi text-dark border-0 rounded-circle p-3 bg-light fs-2 mr10 cursor-pointer bi-download'></i>
                  )}
                </div>
              </div>
              <div>
                <i
                  className='bi bi-x-lg button-close fw-bold border-0 rounded-circle px-3 py-2 bg-light cursor-pointer'
                  onClick={handleModalClose}
                  style={{fontSize: '22px'}}
                ></i>
              </div>
            </div>
            <div className='modal-body'>
              <div
                style={{width: '94%'}}
                className='d-flex justify-content-between align-items-center position-absolute top-50 z-3 mx-5 feed-attachment-arrow-button'
              >
                <button
                  type='button'
                  disabled={selectedAttachmentIndex === 0}
                  onClick={onClickPrevious}
                  className={`bg-light shadow rounded-circle py-2 px-3 border-0 ${
                    selectedAttachmentIndex === 0 ? 'cursor-not-allow opacity-8' : 'cursor-pointer'
                  }`}
                >
                  <i className='bi bi-chevron-left text-dark'></i>
                </button>
                <button
                  type='button'
                  disabled={selectedAttachmentIndex === attachments.length - 1}
                  onClick={onClickNext}
                  className={`shadow border-0 bg-light rounded-circle py-2 px-3 ${
                    selectedAttachmentIndex === attachments.length - 1
                      ? 'cursor-not-allow opacity-8'
                      : 'cursor-pointer'
                  }`}
                >
                  <i className='bi bi-chevron-right text-dark'></i>
                </button>
              </div>
              <div className='d-flex justify-content-center image-attachment'>
                {selectedAttachment.fileType.startsWith('image/') && (
                  <img className='w-65 h-100' src={attachmentUrl} alt='img' />
                )}
                {(selectedAttachment.fileType.startsWith('application/pdf') ||
                  selectedAttachment.fileType === 'media') && (
                  <iframe
                    src={
                      selectedAttachment.fileType === 'media'
                        ? selectedAttachment.signedFileName
                        : attachmentUrl
                    }
                    title={selectedAttachment.originalFileName}
                    style={{height: '80vh'}}
                    allowFullScreen
                    className='w-65 video-size'
                  ></iframe>
                )}
                {hasUnsupportedFormat() && (
                  <div style={{padding: '15%'}}>
                    <div className='card text-center' style={{width: '34rem'}}>
                      <div className='card-body'>
                        <h1 className='card-title my-6'>No preview available</h1>
                        <p className='card-text my-6 fs-5 fw-bold'>
                          for {selectedAttachment.originalFileName}
                        </p>
                        <button
                          onClick={downloadAttachment}
                          className='btn btn-primary w-100 my-6 fw-bold'
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviewAttachments
