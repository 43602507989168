import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query'
import {toast} from 'react-toastify'

import {
  THREAD_USERS_QUERY_KEY,
  THREADS_QUERY_KEY,
  THREAD_MESSAGES_QUERY_KEY,
} from '../constants/reactQueryKeys'
import * as threadsApi from '../apis/threads'
import {useAuth} from '../modules/auth'
import {parseError} from '../modules/common/utils'

const defaultFetchQueryOptions = {
  cacheTime: 0,
  keepPreviousData: true,
  refetchOnWindowFocus: false,
  staleTime: 0,
}

export const useFetchUsers = () => {
  const {selectedCommunity} = useAuth()
  return useQuery({
    queryKey: [THREAD_USERS_QUERY_KEY],
    queryFn: () => threadsApi.fetchUsers({page: 1, limit: 1000}),
    enabled: selectedCommunity?.permissions.canManageThreads,
    ...defaultFetchQueryOptions,
  })
}

export const useFetchThreads = ({userId}: {userId: string}) => {
  return useQuery({
    queryKey: [THREADS_QUERY_KEY, {userId}],
    queryFn: () => threadsApi.fetchUserThreads({page: 1, limit: 1000, customerId: userId}),
    enabled: !!userId,
    refetchInterval: 120 * 1000,
    ...defaultFetchQueryOptions,
  })
}

export const useFetchThreadMessages = ({threadId}: {threadId: string}) => {
  return useQuery({
    queryKey: [THREAD_MESSAGES_QUERY_KEY, {threadId}],
    queryFn: () => threadsApi.fetchThreadMessages(threadId, {page: 1, limit: 1000}),
    enabled: !!threadId,
    refetchInterval: 30 * 1000,
    ...defaultFetchQueryOptions,
  })
}

export const usePostNewThread = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (threadData: any) => threadsApi.postThread(threadData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [THREADS_QUERY_KEY],
      })
      toast.success('Thread created successfully')
    },
    onError: (error) => {
      toast.error(parseError(error))
    },
  })
}

export const usePostNewThreadMessage = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({id, message}: {id: any; message: any}) =>
      threadsApi.postThreadMessage(id, {message}),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [THREAD_MESSAGES_QUERY_KEY],
      })
    },
    onError: (error) => {
      toast.error(parseError(error))
    },
  })
}

export const useUpdateThread = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({id, status}: {id: any; status: any}) =>
      threadsApi.updateThreadStatus(id, {status}),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [THREADS_QUERY_KEY],
      })
      toast.success('Thread updated successfully')
    },
    onError: (error) => {
      toast.error(parseError(error))
    },
  })
}
