import React from 'react'
import Image from '../../../modules/common/Image'
import {Link} from 'react-router-dom'

interface LeaderBoardActivityProps {
  title: string
  leaderBoardStats: any
}

const getBadgeStyle = (index: number) => {
  switch (index) {
    case 0:
      return {
        backgroundColor: 'rgb(40,167,69)',
        color: 'white',
      }
    case 1:
      return {
        backgroundColor: 'rgb(255,193,7)',
        color: 'white',
      }
    case 2:
      return {
        backgroundColor: 'rgb(0,123,255)',
        color: 'white',
      }
    default:
      return {
        backgroundColor: 'transparent',
        color: 'transparent',
      }
  }
}

const LeaderBoardActivity: React.FC<LeaderBoardActivityProps> = ({title, leaderBoardStats}) => {
  return (
    <div className='border border-secondary p-7 ' style={{width: '33%'}}>
      <h3 className='mb-7'>{title}</h3>
      <hr />
      <div className='d-flex flex-column overflow-y-scroll' style={{maxHeight: '250px'}}>
        {leaderBoardStats?.map((stat: any, index: number) => (
          <div
            key={stat.user.id}
            className='d-flex justify-content-between align-items-center mb-4 '
          >
            <div className='d-flex align-items-center'>
              <div
                className='rounded-circle w30 h30 d-flex align-items-center justify-content-center fs-4 fw-bold'
                style={getBadgeStyle(index)}
              >
                {index + 1}
              </div>
              <Image
                src={stat.user.absoluteProfilePath}
                className='rounded-circle ms-3 object-cover object-top w40 h40'
              />
              <Link to={`/view-profile/${stat.user.id}`} className='fw-bold ms-3 text-black fs-6'>
                {stat.user.fullName}
              </Link>
            </div>
            <div>
              <p className='text-primary fw-bold mt-2 fs-6'>
                {title.includes('all') ? '' : '+'} {stat.totalPoints}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LeaderBoardActivity
