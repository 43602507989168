import {useState, KeyboardEvent, useEffect} from 'react'
import clsx from 'clsx'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {Button} from 'react-bootstrap'

import {useLayout} from '../../core'
import {Topbar} from './Topbar'
import {useAuth} from '../../../modules/auth'
import HeaderMessage from './HeaderMessage'
import SubHeader from './SubHeader'
import {isProfileIncomplete} from '../../../modules/common/utils'
import Image from '../../../modules/common/Image'
import HeaderNotification from './HeaderNotification'

import './HeaderWrapper.css'

export function HeaderWrapper() {
  const {classes, attributes} = useLayout()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const q = searchParams.get('q') || ''
  const {currentUser, selectedCommunity} = useAuth()
  const [search, setSearch] = useState(q)
  const btnClass = 'btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
  const isProfileIncompleted = isProfileIncomplete(currentUser)
  const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // If Enter key is pressed, perform search
      onSearch()
    }
  }

  const onSearch = () => {
    search && navigate(`/search?q=${search}`)
  }

  useEffect(() => {
    setSearch(q)
  }, [q])

  return (
    <div className='header-wrapper bg-light'>
      <div
        id='kt_header'
        className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
        {...attributes.headerMenu}
      >
        {/* begin::Brand */}
        <div className='d-flex align-items-center justify-content-center header-brand-toggle w-30'>
          <div className='d-flex align-items-center'>
            <Image
              src={`${
                selectedCommunity?.iconPic
                  ? `${s3BaseUrl}${selectedCommunity?.iconPic}`
                  : '/media/misc/menu-header-bg.jpg'
              }`}
            />
            <Link
              to={'/feeds/public'}
              className='border-0 text-primary cursor-pointer mt-1 fw-bold mx-1 fs-4'
            >
              {selectedCommunity?.name}
            </Link>
          </div>

          {/* begin::Aside toggle */}
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-color-primary w-30px h-30px'
              id='kt_aside_mobile_toggle'
            >
              <i className='bi bi-list fs-1'></i>
            </div>
          </div>
          {/* end::Aside toggle */}
        </div>
        {/* end::Brand */}
        <div className='d-none lg-header d-md-flex justify-content-between w-100 mx-5 align-items-center'>
          <div className='d-flex align-items-center rounded bg-white w-75'>
            <i
              className={`fa-solid fa-magnifying-glass ml10 ${
                isProfileIncompleted && 'incomplete-profile'
              }`}
            ></i>
            <input
              type='text'
              className={`form-control border-0 bg-transparent ${
                isProfileIncompleted && 'incomplete-profile'
              }`}
              placeholder='Search'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={isProfileIncompleted}
            />
            <Button variant='primary' disabled={isProfileIncompleted} onClick={onSearch}>
              Search
            </Button>
          </div>
          <div className={clsx('d-flex align-items-center')}>
            <div>
              <Link
                className={`nav-link fs-4 ${isProfileIncompleted && 'incomplete-profile'}`}
                to='/feeds/public'
              >
                <i style={{color: '#D0D2DA'}} className={`fa-solid fa-home fs-1 ${btnClass}`}></i>
              </Link>
            </div>
            <div className={` ${isProfileIncompleted && 'incomplete-profile'}`}>
              <HeaderMessage btnClass={btnClass} />
            </div>
            <div>
              <HeaderNotification />
            </div>
            <Topbar />
          </div>
        </div>
      </div>
      <SubHeader />
    </div>
  )
}
