import {FC, useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import {DrawerComponent} from '../../../_metronic/assets/ts/components'
import {WithChildren} from '../../../_metronic/helpers'
import {AsideDefault} from './aside/AsideDefault'

const excludePaths = [
  '/settings',
  '/view-profile',
  '/govchat',
  '/courses/detail',
  '/community-settings',
]

const Content: FC<WithChildren> = ({children}) => {
  const location = useLocation()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const showAsideMenu = isMobile || !excludePaths.some((path) => location.pathname.startsWith(path))

  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  return (
    <div id='kt_content_container' className='d-flex container-fluid content-container w-100'>
      {showAsideMenu && <AsideDefault />}
      <div
        className={`kt-content-inner ${
          showAsideMenu ? 'w-80' : 'w-95'
        } d-flex justify-content-center`}
      >
        {children}
      </div>
    </div>
  )
}

export {Content}
