import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {toast} from 'react-toastify'

import * as feedsApi from '../apis/feeds'
import {FEEDS_QUERY_KEY, FEED_LIKE_QUERY_KEY} from '../constants/reactQueryKeys'
import {parseError} from '../modules/common/utils'

interface GetFeedsParams {
  userId?: string
  isHighlighted?: boolean
  categoryId?: string
  page?: string | number
}
export const useGetFeeds = ({userId, isHighlighted, categoryId, page = '1'}: GetFeedsParams) => {
  return useQuery({
    queryKey: [FEEDS_QUERY_KEY, {page, categoryId, userId, isHighlighted}],
    queryFn: () => feedsApi.fetchFeeds({page, limit: 30, categoryId, userId, isHighlighted}),
  })
}

export const useCreateFeed = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (feedData: any) => feedsApi.postFeed(feedData),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [FEEDS_QUERY_KEY]})
      toast.success('Feed created successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useUpdateFeed = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({feedId, feedData}: {feedId: string | number; feedData: any}) =>
      feedsApi.updateFeed(feedId, feedData),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [FEEDS_QUERY_KEY]})
      toast.success('Feed updated successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useDeleteFeed = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (feedId: number) => feedsApi.deleteFeed(feedId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [FEEDS_QUERY_KEY]})
      toast.success('Feed deleted successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useUpdateHighlightFeed = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({feedId, feedData}: {feedId: string | number; feedData: any}) =>
      feedsApi.addFeedHighlight(feedId, feedData),
    onSuccess: (data, {feedData}) => {
      queryClient.invalidateQueries({queryKey: [FEEDS_QUERY_KEY]})
      feedData.isHighlighted && toast.success('Feed highlighted successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useGetFeedLikes = (feedId: number) => {
  return useQuery({
    queryKey: [FEED_LIKE_QUERY_KEY, feedId],
    queryFn: () => feedsApi.fetchFeedLikes(feedId),
  })
}

export const useAddFeedLike = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (feedId: number) => feedsApi.postFeedLike(feedId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [FEEDS_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useDeleteFeedLike = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (feedId: number) => feedsApi.deleteFeedLike(feedId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [FEEDS_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}
