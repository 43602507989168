import React, {useEffect, useState} from 'react'

import LoadingSvg from '../feedsSvg/LoadingSvg'
import PreviewAttachments from './PreviewAttachments'

interface FeedAttachmentProps {
  isUploading?: boolean
  attachments: any
  handleDeleteFile?: any
  hideDeleteIcon?: boolean
}

const FeedAttachments: React.FC<FeedAttachmentProps> = ({
  isUploading,
  attachments,
  handleDeleteFile,
  hideDeleteIcon = true,
}) => {
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState(null)
  const [showAttachmentModal, setShowAttachmentModal] = useState(false)

  useEffect(() => {
    if (showAttachmentModal) {
      const inputElement = document.getElementById('modalInput')
      inputElement?.focus()
    }
  }, [showAttachmentModal])

  const handleModalShow = () => {
    setShowAttachmentModal(true)
  }

  const handleModalClose = () => {
    setShowAttachmentModal(false)
  }

  const handleAttachmentClick = (attachmentIndex: any) => {
    setSelectedAttachmentIndex(attachmentIndex)
    setShowAttachmentModal(true)
    handleModalShow()
  }

  const renderFeedAttachment = (attachment: any) => {
    const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL
    const imageUrl = s3BaseUrl + attachment.signedFileName

    switch (attachment.fileType) {
      case 'application/pdf':
        return (
          <img
            className='h150 w150 rounded-2 m-2 cursor-pointer'
            src='/media/svg/general/pdf.svg'
            alt='PDF-Icon'
          />
        )
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return (
          <img
            className='h150 w150 rounded-2 m-2 cursor-pointer'
            src='/media/svg/general/xls.svg'
            alt='Excel-Icon'
          />
        )
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return (
          <img
            className='h150 w150 rounded-2 m-2 cursor-pointer'
            src='/media/svg/general/doc.svg'
            alt='Docs-Icon'
          />
        )
      case 'media':
        return (
          <div className='position-relative w250 h150'>
            <div className='position-absolute top-0 m-2 left-0 w-100 h-100 z-1 pointer-event bg-black-50 opacity-8 rounded'></div>
            <iframe
              src={attachment.signedFileName}
              title={attachment.originalFileName}
              allowFullScreen
              className='h150 w250 rounded-2 m-2 cursor-pointer z-0'
            ></iframe>
          </div>
        )
      case 'image/jpeg':
      case 'image/png':
      case 'image/jpg':
        return (
          <img
            className='h150 w150 rounded-2 m-2 cursor-pointer object-fit-cover object-center'
            src={imageUrl}
            alt='Img-Icon'
          />
        )
      default:
        return null
    }
  }
  return (
    <div className='d-flex align-items-center overflow-x-scroll'>
      {attachments?.length > 0 && (
        <div className={`${attachments?.length > 1 && 'd-flex'}`}>
          {attachments &&
            attachments?.map((attachment: any, index: any) => (
              <div key={index} className='m-2'>
                {hideDeleteIcon && (
                  <div
                    style={{
                      zIndex: 10,
                      left: attachment.fileType === 'media' ? '210px' : '110px',
                      top: '50px',
                    }}
                    className='position-relative'
                  >
                    <i
                      onClick={() => handleDeleteFile(index)}
                      className='bi p-3 fs-2 attachment-icon rounded-circle cursor-pointer bi-x'
                    ></i>
                  </div>
                )}
                <div onClick={() => handleAttachmentClick(index)}>
                  {renderFeedAttachment(attachment)}
                  {showAttachmentModal && (
                    <PreviewAttachments
                      selectedAttachmentIndex={selectedAttachmentIndex}
                      setSelectedAttachmentIndex={setSelectedAttachmentIndex}
                      attachments={attachments}
                      handleModalClose={handleModalClose}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      )}

      {isUploading && (
        <div className='min-h150 min-w150 h150 w150 rounded-2 mx-5 mt-9 bg-light d-flex justify-content-center align-items-center'>
          <LoadingSvg />
        </div>
      )}
    </div>
  )
}

export default FeedAttachments
