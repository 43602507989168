import {useEffect, useState} from 'react'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'

import CourseModule from './CourseModule'
import {useAuth} from '../../../modules/auth'
import {useUpdateCourseModuleOrder} from '../../../queries/courses'

interface CourseModuleProps {
  course?: any
}

export const CourseModules = ({course}: CourseModuleProps) => {
  const {selectedCommunity} = useAuth()
  const courseModuleOrderMutation = useUpdateCourseModuleOrder()
  const [courseModules, setCourseModules] = useState(course.courseModules)

  useEffect(() => {
    setCourseModules(course.courseModules)
  }, [course.courseModules])

  const handleModuleDragEnd = async (result: any) => {
    if (!result.destination) {
      return
    }

    const [oldsectionModules, reorderedSectionModules] = [
      Array.from(courseModules),
      Array.from(courseModules),
    ]
    const [removed] = reorderedSectionModules.splice(result.source.index, 1)
    reorderedSectionModules.splice(result.destination.index, 0, removed)

    setCourseModules(reorderedSectionModules)

    try {
      await courseModuleOrderMutation.mutateAsync({
        courseId: course.uuid,
        moduleId: result.draggableId,
        order: result.destination.index + 1,
      })
    } catch {
      setCourseModules(oldsectionModules)
    }
  }

  return (
    <DragDropContext onDragEnd={handleModuleDragEnd}>
      <Droppable droppableId='sectionModules'>
        {(provided: any) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {courseModules?.map((courseModule: any, index: number) => (
              <Draggable
                key={courseModule.id}
                draggableId={`${courseModule.id}`}
                index={index}
                isDragDisabled={!selectedCommunity?.permissions.canCreateEditCourse}
              >
                {(provided: any) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <CourseModule
                      key={courseModule.id}
                      course={course}
                      sectionModule={courseModule}
                      sectionStatus={courseModule.status}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
