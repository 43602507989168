import {getSelectedCommunityId} from '../modules/common/utils'
import apiClient from './apiClient'

interface GetApiParams {
  page: number | string
  limit?: number
}

export const postCategory = async (categoriesData: any) => {
  const response = await apiClient.post(
    `/communities/${getSelectedCommunityId()}/categories`,
    categoriesData
  )
  return response.data?.data || {}
}

export const getCategories = async (params: GetApiParams): Promise<any> => {
  const response = await apiClient.get(`/communities/${getSelectedCommunityId()}/categories`, {
    params,
  })
  return response.data?.data || []
}

export const putCategory = async (categoryId: number, categoriesData: any) => {
  const response = await apiClient.put(
    `/communities/${getSelectedCommunityId()}/categories/${categoryId}`,
    categoriesData
  )
  return response.data?.data || {}
}

export const deleteCategory = async (categoryId: number) => {
  const response = await apiClient.delete(
    `/communities/${getSelectedCommunityId()}/categories/${categoryId}`
  )
  return response.data?.data || {}
}
