import {Fragment} from 'react'
import {useParams} from 'react-router-dom'

import CourseSections from './components/CourseSections'
import ViewModule from './components/ViewModule'
import FetchingSpinner from '../../modules/common/FetchingSpinner'
import {useGetCourseById} from '../../queries/courses'
import {CourseModules} from './components/CourseModules'

function CourseDetail() {
  const routeParams = useParams()
  const {courseId} = routeParams
  const courseResult = useGetCourseById(courseId || '')
  return (
    <>
      <div className='w-100 d-flex view-module-container'>
        {courseResult.isLoading && <FetchingSpinner />}
        {courseResult.data && (
          <Fragment>
            <div className='p-9 module-left-section' style={{width: '30%'}}>
              <CourseSections course={courseResult.data} />
              <CourseModules course={courseResult.data} />
            </div>
            <div className='p-9 module-right-section' style={{width: '70%'}}>
              <ViewModule course={courseResult.data} />
            </div>
          </Fragment>
        )}
      </div>
    </>
  )
}

export default CourseDetail
