import React, {useState, useEffect, Fragment} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import moment from 'moment'

import {states} from '../../../modules/common/utils'
import FeedsIndex from '../../feeds/FeedsIndex'
import CoursesIndex from '../../courses/CoursesIndex'
interface ViewProfileHeaderProps {
  user: any
}

const ViewProfileHeader: React.FC<ViewProfileHeaderProps> = ({user}) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const tab = searchParams.get('tab')
  const [currentTab, setCurrentTab] = useState(tab || 'about')

  const findLocation = (value: string) => {
    const loc = states.find((x) => x.value === value)
    return loc?.name
  }

  useEffect(() => {
    setCurrentTab(tab || 'about')
  }, [tab])

  const AboutSection = () => (
    <div className='d-flex flex-row card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-body p-0 py-9'>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>City</label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>{user?.city}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>State</label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>{user && findLocation(user.state)}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Timezone</label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>{user?.timezone}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Bio</label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>{user?.bio}</span>
          </div>
        </div>

        {user?.userCertifications?.length > 0 && (
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Certificate</label>
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
                {user?.userCertifications
                  ?.map((certification: any) => certification?.certificate?.name)
                  .join(', ')}
              </span>
            </div>
          </div>
        )}
        {user?.userWebsites?.length > 0 && (
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Website</label>
            <div className='col-lg-8 fv-row'>
              {user?.userWebsites?.map((website: any, index: number) => (
                <Fragment key={index}>
                  <a
                    href={website.websiteUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='fw-bold cursor-pointer fs-6'
                  >
                    {website.websiteUrl}
                  </a>
                  {index < user.userWebsites.length - 1 && <span> | </span>}
                </Fragment>
              ))}
            </div>
          </div>
        )}
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Subscription Status</label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>{user?.subscription?.status}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Subscription End</label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>
              {moment.unix(user?.subscription?.endTime).format('DD/MM/YYYY')}
            </span>
          </div>
        </div>
      </div>
    </div>
  )

  const FeedsSecton = () => {
    return (
      <div className='mt-12 w-100'>
        <FeedsIndex userId={user?.id} />
      </div>
    )
  }

  const CoursesSecton = () => {
    return (
      <div>
        <CoursesIndex />
      </div>
    )
  }

  return (
    <>
      <div className='d-flex overflow-auto h-55px view-profile-tabs'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item'>
            <div
              onClick={() => navigate('?tab=about')}
              className={`${
                currentTab === 'about' ? 'active' : ''
              } nav-link text-active-primary me-6 cursor-pointer`}
            >
              About
            </div>
          </li>
          <li className='nav-item'>
            <div
              onClick={() => navigate('?tab=feeds')}
              className={`${
                currentTab === 'feeds' ? 'active' : ''
              } nav-link text-active-primary me-6 cursor-pointer`}
            >
              Feeds
            </div>
          </li>
          <li className='nav-item'>
            <div
              onClick={() => navigate('?tab=courses')}
              className={`${
                currentTab === 'courses' ? 'active' : ''
              } nav-link text-active-primary me-6 cursor-pointer`}
            >
              Courses
            </div>
          </li>
        </ul>
      </div>

      <div className='my-5'>
        {currentTab === 'about' && AboutSection()}
        {currentTab === 'feeds' && FeedsSecton()}
        {currentTab === 'courses' && CoursesSecton()}
      </div>
    </>
  )
}

export default ViewProfileHeader
