import {useState} from 'react'
import {Card, Dropdown} from 'react-bootstrap'

import {useDeleteNotes} from '../../queries/notes'
import moment from 'moment'
import {ConfirmationModal} from '../../modules/common/ConfirmationModal'
import NoteForm from './NoteForm'

import './notes.scss'

function truncateText(text: string, maxLength: number) {
  if (text.length <= maxLength) {
    return text
  }
  return text.slice(0, maxLength) + '...'
}

function NoteCard({note, index}: {note: any; index: number}) {
  const [showEditNoteModal, setShowEditNoteModal] = useState(false)
  const [showDropdown, setShowDropdown] = useState(Array(3).fill(false))
  const {mutateAsync: deleteNote, isPending: isDeletingNote} = useDeleteNotes()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const maxLength = 200

  const handleDeleteNote = async (id: any) => {
    try {
      await deleteNote(note.id)
      setShowDeleteModal(false)
    } catch (error) {
      console.error('Error deleting note:', error)
    }
  }

  const handleDropdownToggle = (index: any) => {
    const newShowDropdown = [...showDropdown]
    newShowDropdown[index] = !newShowDropdown[index]
    setShowDropdown(newShowDropdown)
  }

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <div>
      <div className='mt-8 rounded'>
        <Card className='notes-card p-7 pb-0 mb-3'>
          <div className='cursor-pointer d-flex justify-content-between align-items-start'>
            <div className='w-100'>
              <div className='d-flex justify-content-between'>
                <div>
                  <h1 className='text-break'>{note.title}</h1>
                </div>
                <div>
                  <Dropdown show={showDropdown[index]} onToggle={() => handleDropdownToggle(index)}>
                    <Dropdown.Toggle variant='none' id={`dropdown-basic-${index}`} size='sm'>
                      <i className='fa-solid fs-2 feed-menu-icon fa-ellipsis p-0 m-0'></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className='cursor-pointer'
                        onClick={() => setShowEditNoteModal(true)}
                      >
                        <i className='fa fa-pencil mx-2'></i> Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        className='cursor-pointer text-danger'
                        onClick={() => setShowDeleteModal(true)}
                      >
                        <i className='fa fa-trash mx-2 text-danger'></i> Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div onClick={toggleExpand}>
                <p
                  className='fs-5 mt-5'
                  dangerouslySetInnerHTML={{
                    __html: expanded ? note.description : truncateText(note.description, maxLength),
                  }}
                />
                {note.description.length > maxLength && (
                  <button
                    className='expand-btn text-primary bg-transparent border-0 float-right'
                    onClick={toggleExpand}
                  >
                    {expanded ? 'Less' : 'Expand'}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className='mt-3 px-4'>
            <p className='text-end text-gray fw-bold'>{moment(note?.createdAt).fromNow()}</p>
          </div>
        </Card>
      </div>
      {showEditNoteModal && (
        <NoteForm
          show={showEditNoteModal}
          handleClose={() => setShowEditNoteModal(false)}
          note={note}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          showModal={showDeleteModal}
          onHideModal={() => setShowDeleteModal(false)}
          onCancel={() => setShowDeleteModal(false)}
          title='Delete Note'
          subtitle='Are you sure you want to delete this note?'
          onConfirm={() => handleDeleteNote(note.id)}
          disableBtns={isDeletingNote}
        />
      )}
    </div>
  )
}

export default NoteCard
