import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {toast} from 'react-toastify'

import * as resourcesApi from '../apis/resources'
import {RESOURCES_QUERY_KEY, RESOURCE_BY_ID_QUERY_KEY} from '../constants/reactQueryKeys'
import {parseError} from '../modules/common/utils'

export const useGetResources = (params: {
  folderId?: string | null
  page?: number | string
  limit?: number | string
  search?: string | null
  type?: string
}) => {
  return useQuery({
    queryKey: [
      RESOURCES_QUERY_KEY,
      params.folderId,
      params.page,
      params.limit,
      params.search,
      params.type,
    ],
    queryFn: () => resourcesApi.fetchResources(params),
    gcTime: 15 * 1000,
  })
}

export const useGetResourceById = (id: number) => {
  return useQuery({
    queryKey: [RESOURCE_BY_ID_QUERY_KEY, id],
    queryFn: () => resourcesApi.fetchResourceById(id),
    enabled: !!id,
  })
}

export const useUpdateResourceTitle = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({id, newTitle}: {id: number; newTitle: string}) =>
      resourcesApi.updateResourceTitle(id, newTitle),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [RESOURCE_BY_ID_QUERY_KEY],
      })
      toast.success('Resource title updated successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useDeleteResource = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: number) => resourcesApi.deleteResource(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [RESOURCES_QUERY_KEY],
      })
      toast.success('Resource deleted successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const usePostResourcesFolder = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (folderData: any) => resourcesApi.postResourcesFolder(folderData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [RESOURCES_QUERY_KEY],
      })
      toast.success('Folder created successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useUpdateResourcesFolder = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({folderId, folderData}: {folderId: number | string; folderData: any}) =>
      resourcesApi.updateResourcesFolder(folderId, folderData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [RESOURCES_QUERY_KEY],
      })
      toast.success('Folder updated successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useMoveResource = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({folderId, folderData}: {folderId: any; folderData: any}) =>
      resourcesApi.moveResource(folderId, folderData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [RESOURCES_QUERY_KEY],
      })
      toast.success('Resource path updated successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}
