import {useQuery} from '@tanstack/react-query'

import * as usersApi from '../apis/users'
import {USER_STRIPE_PORTAL_QUERY_KEY} from '../constants/reactQueryKeys'

export const useGetStripePortal = (userId: number | undefined) => {
  return useQuery({
    queryKey: [USER_STRIPE_PORTAL_QUERY_KEY, {userId}],
    queryFn: () => usersApi.getStripePortal(),
    enabled: !!userId,
    refetchInterval: 120 * 1000,
  })
}
