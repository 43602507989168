/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import {Modal} from 'react-bootstrap'
import {toast} from 'react-toastify'

import {useAuth} from '../../../../app/modules/auth'
import apiClient from '../../../../app/apis/apiClient'
import {isProfileIncomplete} from '../../../../app/modules/common/utils'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout, selectedCommunity} = useAuth()
  const [show, setShow] = useState(false)

  const isProfileIncompleted = isProfileIncomplete(currentUser)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const validationSchema = Yup.object().shape({
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required'),
  })

  const initialValues = {
    subject: '',
    message: '',
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
      data-popper-placement='bottom-start'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.firstName} {currentUser?.lastName}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link
          to={`/view-profile/${currentUser?.id}`}
          className={`menu-link px-5 ${isProfileIncompleted && 'incomplete-profile'}`}
        >
          View Profile
        </Link>
      </div>

      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to={'settings'} className='menu-link px-5'>
          Profile Settings
        </Link>
      </div>

      {selectedCommunity?.isCommunityAdmin && (
        <>
          <div className='separator my-2'></div>

          <div className='menu-item px-5'>
            <Link to={'community-settings'} className='menu-link px-5'>
              Community Settings
            </Link>
          </div>
        </>
      )}

      {selectedCommunity?.isMember && (
        <>
          <div className='separator my-2'></div>

          <div className='menu-item px-5'>
            <div className='menu-link px-5' onClick={handleShow}>
              Send Feedback
            </div>
          </div>

          <Modal show={show} onHide={handleClose} centered size='lg'>
            <Modal.Header closeButton>
              <div>
                <h1>Send feedback</h1>
                <h5 className='fw-normal'>Share Your Thoughts with Us!</h5>
              </div>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                  try {
                    await apiClient.post('/users/send-feedback', values)
                    setShow(false)
                    toast.success('Feedback sent successfully!', {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    })
                  } catch (error) {
                    console.log('error: ', error)
                  }
                }}
              >
                {({errors, touched}) => (
                  <Form>
                    <div>
                      <div className='d-flex my-2 justify-content-between align-items-center'>
                        <div>
                          <h5>Subject</h5>
                        </div>
                        <div className='w-75'>
                          <Field
                            name='subject'
                            className={`w-100 py-4 px-2 rounded border-0 bg-light ${
                              touched.subject && errors.subject ? 'is-invalid' : ''
                            }`}
                            style={{outline: 'none'}}
                            type='text'
                            placeholder='Enter subject here'
                          />
                          {touched.subject && errors.subject && (
                            <div className='invalid-feedback'>{errors.subject}</div>
                          )}
                        </div>
                      </div>
                      <div className='d-flex my-3 justify-content-between align-items-center'>
                        <div>
                          <h6>Message</h6>
                        </div>
                        <div className='w-75'>
                          <Field
                            name='message'
                            as='textarea'
                            className={`w-100 p-2 rounded border-0 bg-light ${
                              touched.message && errors.message ? 'is-invalid' : ''
                            }`}
                            style={{resize: 'none', outline: 'none'}}
                            rows={6}
                            placeholder='Enter message here'
                          />
                          {touched.message && errors.message && (
                            <div className='invalid-feedback'>{errors.message}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-end '>
                      <button className='btn btn-primary' type='submit'>
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </>
      )}
      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
