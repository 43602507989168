import {Fragment, useEffect} from 'react'
import {useParams} from 'react-router-dom'

import {useGetUserChatMessages} from '../../queries/aiChat'
import ChatMessages from './components/ChatMessages'

import './GovChat.scss'

function UserChatMessages() {
  const routeParams = useParams()
  const {chatId = ''} = routeParams

  const chatMessagesResult = useGetUserChatMessages({chatId, page: 1, limit: 1000})

  useEffect(() => {
    if (chatId) {
      chatMessagesResult.refetch()
    }
  }, [chatId])

  return (
    <Fragment>
      <div className='d-flex flex-column h-100 position-relative w-80'>
        <ChatMessages
          isFetching={chatMessagesResult.isFetching}
          chatMessages={chatMessagesResult.data?.chatMessages}
          chatId={chatId}
        />
      </div>
    </Fragment>
  )
}

export default UserChatMessages
