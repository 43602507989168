import Image from '../../../modules/common/Image'

const RecentFeedComments = ({totalCommentsCount = 0, comments}: any) => {
  const limitedComments = comments.slice(0, 5)

  return (
    <div className='d-flex py-1 rounded justify-content-start align-items-center flex-wrap'>
      <div className='mx-2'>
        <i className='fa-regular fa-comment fs-2 cursor-default'></i>
        <span className='fs-3 mx-3 fw-medium cursor-default text-gray'>{totalCommentsCount}</span>
      </div>
      {limitedComments.length > 0 && (
        <div className={`d-flex ml10 ${limitedComments.length >= 5 && 'px-4'}`}>
          {limitedComments.map((commentUser: any, index: number) => (
            <div key={index} style={{marginLeft: limitedComments.length >= 5 ? '-20px' : '0px'}}>
              <Image src={`${commentUser?.user?.absoluteProfilePath}`} className='mx-1 img-round' />
            </div>
          ))}
          {limitedComments.length >= 5 && comments.length !== 5 && (
            <div
              style={{marginLeft: '-20px'}}
              className='rounded-circle h40 w40 d-flex justify-content-center align-items-center bg-secondary img-feed '
            >
              +{totalCommentsCount - 5}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default RecentFeedComments
