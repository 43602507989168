import React, {useEffect, useState} from 'react'
import {Accordion} from 'react-bootstrap'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'

import CustomDropDown from '../../../modules/common/CustomDropDown'
import CourseSectionModule from './CourseModule'
import {CourseSectionForm} from './CourseSectionForm'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import {useDeleteCourseSection, useUpdateCourseModuleOrder} from '../../../queries/courses'
import {useAuth} from '../../../modules/auth'

interface CourseSectionProps {
  section: any
  handleAddModule: any
  course: any
}

export const CourseSection: React.FC<CourseSectionProps> = ({course, section, handleAddModule}) => {
  const deleteCourseSectionMutation = useDeleteCourseSection()
  const courseModuleOrderMutation = useUpdateCourseModuleOrder()
  const {selectedCommunity} = useAuth()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [showEditSectionModal, setShowEditSectionModal] = useState(false)
  const [showDeleteSectionModal, setShowDeleteSectionModal] = useState(false)
  const [sectionModules, setSectionModules] = useState(section.courseSectionModules)

  useEffect(() => {
    setSectionModules(section.courseSectionModules)
  }, [section.courseSectionModules])

  const sectionOptions = [
    {
      label: 'Add Module',
      handler: () => handleAddModule(section.id),
    },
    {
      label: 'Edit Section',
      handler: () => setShowEditSectionModal(true),
    },
    {
      label: 'Delete Section',
      handler: () => setShowDeleteSectionModal(true),
    },
  ]

  const handleDeleteCourseSection = async () => {
    try {
      await deleteCourseSectionMutation.mutateAsync({courseId: course.uuid, sectionId: section.id})
    } catch {}
  }

  const handleModuleDragEnd = async (result: any) => {
    if (!result.destination) {
      return
    }

    const [oldsectionModules, reorderedSectionModules] = [
      Array.from(sectionModules),
      Array.from(sectionModules),
    ]
    const [removed] = reorderedSectionModules.splice(result.source.index, 1)
    reorderedSectionModules.splice(result.destination.index, 0, removed)

    setSectionModules(reorderedSectionModules)

    try {
      await courseModuleOrderMutation.mutateAsync({
        courseId: course.uuid,
        moduleId: result.draggableId,
        order: result.destination.index + 1,
      })
    } catch {
      setSectionModules(oldsectionModules)
    }
  }

  return (
    <Accordion.Item eventKey={`${section.id}`}>
      <Accordion.Button
        className='fw-bold cursor-pointer'
        onMouseEnter={() => setIsDropdownOpen(true)}
        onMouseLeave={() => setIsDropdownOpen(false)}
        as={'div'}
      >
        <div className='d-flex justify-content-between align-items-center w-100'>
          <h4>
            {section.status === 'draft'
              ? `(Draft) ${
                  section.sectionName.length > 22
                    ? section.sectionName.slice(0, 22) + '...'
                    : section.sectionName
                }`
              : section.sectionName.length > 22
              ? section.sectionName.slice(0, 22) + '...'
              : section.sectionName}
          </h4>
          {selectedCommunity?.permissions.canCreateEditCourse && isDropdownOpen && (
            <CustomDropDown
              className='mx-3'
              elementId='section-dropdown'
              options={sectionOptions}
            />
          )}
        </div>
      </Accordion.Button>
      <Accordion.Body className='p-0'>
        <DragDropContext onDragEnd={handleModuleDragEnd}>
          <Droppable droppableId='sectionModules'>
            {(provided: any) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {sectionModules.map((sectionModule: any, index: number) => (
                  <Draggable
                    key={sectionModule.id}
                    draggableId={`${sectionModule.id}`}
                    index={index}
                    isDragDisabled={!selectedCommunity?.permissions.canCreateEditCourse}
                  >
                    {(provided: any) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <CourseSectionModule
                          key={sectionModule.id}
                          course={course}
                          sectionModule={sectionModule}
                          sectionStatus={sectionModule.status}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Accordion.Body>

      {showEditSectionModal && (
        <CourseSectionForm
          showModal={showEditSectionModal}
          onHideModal={() => setShowEditSectionModal(false)}
          course={course}
          section={section}
        />
      )}
      {showDeleteSectionModal && (
        <ConfirmationModal
          showModal={showDeleteSectionModal}
          subtitle='Are you sure you want to delete this course section?'
          onHideModal={() => setShowDeleteSectionModal(false)}
          disableBtns={deleteCourseSectionMutation.isPending}
          onConfirm={handleDeleteCourseSection}
          onCancel={() => setShowDeleteSectionModal(false)}
        />
      )}
    </Accordion.Item>
  )
}
