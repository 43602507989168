import {toast} from 'react-toastify'
import apiClient from './apiClient'
import {getSelectedCommunityId} from '../modules/common/utils'

interface FetchResourcesParams {
  page?: number | string
  limit?: number | string
  search?: string | null
  sortField?: string
  sortOrder?: string
  folderId?: string | null
  type?: string
}

export const fetchResources = async (params: FetchResourcesParams): Promise<any> => {
  try {
    const response = await apiClient.get(`/c/${getSelectedCommunityId()}/resources`, {
      params,
    })
    return response.data?.data
  } catch (error) {
    throw error
  }
}

export const fetchResourceById = async (id: number): Promise<any> => {
  try {
    const response = await apiClient.get(`/c/${getSelectedCommunityId()}/resources/${id}`)
    return response.data?.data
  } catch (error) {
    throw error
  }
}

export const uploadResource = async (
  {file, folderId}: {file: File; folderId: string | null},
  onUploadProgress: (progressEvent: ProgressEvent) => void
) => {
  const formData = new FormData()
  formData.append('file', file)
  if (folderId) {
    formData.append('folderId', folderId)
  }
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  }

  return apiClient.post(`/c/${getSelectedCommunityId()}/resources/upload`, formData, config)
}

export const updateResourceTitle = async (id: number, newTitle: string): Promise<any> => {
  try {
    const response = await apiClient.put(`/c/${getSelectedCommunityId()}/resources/${id}`, {
      newTitle,
    })
    toast.success('Successesfully updated resource title', {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    return response.data
  } catch (error) {
    toast.error('An error occured!', {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
  }
}

export const deleteResource = async (id: number) => {
  const response = await apiClient.delete(`/c/${getSelectedCommunityId()}/resources/${id}`)
  return response.data
}

export const postResourcesFolder = async (folderData: any) => {
  const response = await apiClient.post(
    `/c/${getSelectedCommunityId()}/resources/folder`,
    folderData
  )
  return response.data
}

export const updateResourcesFolder = async (folderId: number | string, folderData: any) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/resources/folder/${folderId}`,
    folderData
  )
  return response.data
}

export const moveResource = async (folderId: any, folderData: any) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/resources/${folderId}/move`,
    folderData
  )
  return response.data
}
