import {useState, useMemo} from 'react'

import FetchingSpinner from '../../../modules/common/FetchingSpinner'
import Image from '../../../modules/common/Image'

import './Users.css'

interface UsersListProps {
  users: [] | any
  selectedUser: any
  onClickUser: any
  isFetching: boolean
}

const UsersList: React.FC<UsersListProps> = ({users, selectedUser, onClickUser, isFetching}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const filteredUsers = useMemo(() => {
    if (searchQuery) {
      return users?.filter((user: any) => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase()
        return fullName.includes(searchQuery.toLowerCase())
      })
    }
    return users
  }, [searchQuery, users])

  return (
    <div style={{maxWidth: '100%', minWidth: '200px'}} className='card leftCard p-3'>
      <div style={{border: '1px solid lightgray'}} className='input-group rounded mb-2'>
        <span className='input-group-text bg-transparent border-0' id='basic-addon1'>
          <i className='fa-solid fa-magnifying-glass'></i>
        </span>
        <input
          type='text'
          className='form-control m-0 py-3 px-0 border-0'
          placeholder='Search'
          aria-label='Search'
          aria-describedby='basic-addon1'
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
      </div>
      {isFetching && <FetchingSpinner />}
      {!isFetching && (
        <div className='overflow-y-scroll users-container' style={{height: '65.5vh'}}>
          {filteredUsers?.length > 0 &&
            filteredUsers?.map((user: any) => (
              <div
                key={user.id}
                className={`d-flex cursor-pointer justify-content-start my-3 align-items-center ${
                  selectedUser.id === user.id && 'selected-user p-2 rounded'
                }`}
                onClick={() => onClickUser(user)}
              >
                <Image src={`${user.absoluteProfilePath}`} />

                <div className='text-start'>
                  <p style={{fontWeight: 'bold'}} className='mx-3 my-0'>
                    {user.fullName.length > 12 ? user.fullName.slice(0, 12) + '...' : user.fullName}
                  </p>
                  <p className='mx-3 my-0 text-gray'>
                    {user.companyName?.length > 12
                      ? user.companyName.slice(0, 12) + '...'
                      : user.companyName}
                  </p>
                </div>
              </div>
            ))}
          {filteredUsers?.length === 0 && <p>No User(s) found!</p>}
        </div>
      )}
    </div>
  )
}

export default UsersList
