import Image from '../../../modules/common/Image'
import {Link} from 'react-router-dom'
import {useGetCommunitiesLeaderBoardType} from '../../../queries/communitySettings'

function LeaderBoardCard({type = 'thirty-day'}: {type?: string}) {
  const {data: leaderBoardCardResult} = useGetCommunitiesLeaderBoardType(type)

  const getBadgeStyle = (index: number) => {
    switch (index) {
      case 0:
        return {
          backgroundColor: 'rgb(40,167,69)',
          color: 'white',
        }
      case 1:
        return {
          backgroundColor: 'rgb(255,193,7)',
          color: 'white',
        }
      case 2:
        return {
          backgroundColor: 'rgb(0,123,255)',
          color: 'white',
        }
      default:
        return {
          backgroundColor: 'transparent',
          color: 'black',
        }
    }
  }

  return (
    <div>
      <div className='mt-9 mb-6 p-6 rounded-2' style={{backgroundColor: 'rgb(239,246,255)'}}>
        <div>
          <h3 className='mb-7'>LeaderBoard (30-day)</h3>
          <div className='d-flex flex-column'>
            {leaderBoardCardResult?.slice(0, 5).map((Result: any, index: number) => (
              <div key={index} className='d-flex justify-content-between align-items-center mb-4'>
                <div className='d-flex align-items-center'>
                  <div
                    className='rounded-circle w30 h30 d-flex align-items-center justify-content-center fs-4 fw-bold'
                    style={getBadgeStyle(index)}
                  >
                    {index + 1}
                  </div>
                  <Image
                    src={Result.user.absoluteProfilePath}
                    className='rounded-circle ms-3 object-cover object-top w40 h40'
                  />
                  <Link
                    to={`/view-profile/${Result.user.id}`}
                    className='fw-bold ms-3 text-black fs-6'
                  >
                    {Result.user.fullName}
                  </Link>
                </div>
                <div>
                  <p className='text-primary fw-bold mt-2'>+ {Result.totalPoints}</p>
                </div>
              </div>
            ))}
            <div className='text-center'>
              <hr />
              <Link to={'/leaderboard'} className='text-primary text-center'>
                See All LeaderBoards
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeaderBoardCard
