function EmptyMessage({message = 'No records found'}: {message?: string}) {
  return (
    <div className='w-100 d-flex flex-column align-items-center justify-content-center pt-5'>
      <img src='/media/illustrations/sigma-1/4-dark.png' className='w-50' alt='' />
      <h5 className='text-center text-black mt-3'>{message}</h5>
    </div>
  )
}

export default EmptyMessage
