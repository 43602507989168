import {useInfiniteQuery, useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {toast} from 'react-toastify'

import * as chatsApi from '../apis/aiChat'
import {AI_CHAT_QUERY_KEY, AI_CHAT_MESSAGE_QUERY_KEY} from '../constants/reactQueryKeys'
import {parseError} from '../modules/common/utils'

export const useGetUserChats = () => {
  return useInfiniteQuery({
    queryKey: [AI_CHAT_QUERY_KEY],
    queryFn: ({pageParam = 1}) => chatsApi.getUserChats({page: pageParam, limit: 10}),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.chats.length < 10) {
        return undefined
      }
      return pages.length + 1
    },
    initialPageParam: 1,
  })
}

export const useGetUserChatMessages = (params: {
  page: number | string
  limit: number | string
  chatId: string
}) => {
  return useQuery({
    queryKey: [AI_CHAT_MESSAGE_QUERY_KEY, params.page, params.limit, params.chatId],
    queryFn: () => chatsApi.getUserChatMessages(params),
    enabled: !!params.chatId,
  })
}

export const usePostNewChat = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({chatData}: {chatData: any}) => chatsApi.postNewChat(chatData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [AI_CHAT_QUERY_KEY, AI_CHAT_MESSAGE_QUERY_KEY],
      })
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const usePostChatMessage = () => {
  return useMutation({
    mutationFn: ({chatId, chatData}: {chatId: string; chatData: any}) =>
      chatsApi.postChatMessage(chatId, chatData),
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const useDeleteUserChat = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (chatId: string) => chatsApi.deleteUserChat(chatId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [AI_CHAT_QUERY_KEY],
      })
      toast.success('Chat deleted successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}
