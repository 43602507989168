import React, {useMemo, useState} from 'react'
import {Container, Button, Badge, Table} from 'react-bootstrap'
import moment from 'moment'
import {useTable, usePagination, useSortBy, useGlobalFilter} from 'react-table'

import FetchingSpinner from '../../modules/common/FetchingSpinner'
import InvitesTableHeader from './components/InvitesTableHeader'
import {useGetInvites, useResendInvites} from '../../queries/invites'
import {useSearchParams} from 'react-router-dom'
interface Column {
  Header: string
  accessor: string
  Cell?: (row: any) => JSX.Element | string
  disableSortBy?: boolean
}

const InvitesIndex = () => {
  const [searchParams] = useSearchParams()
  const invitePage = searchParams.get('page')
  const role = searchParams.get('role')
  const status = searchParams.get('status')
  const invitesResult = useGetInvites({page: invitePage, role, status})
  const resendInvitesMutation = useResendInvites()

  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [resendingInviteId, setResendingInviteId] = React.useState(null)

  const usersInvites = invitesResult.data?.usersInvites
  const data = usersInvites?.users || []

  const columns: Column[] = useMemo(
    () => [
      {Header: 'EMAIL', accessor: 'email'},
      {Header: 'ROLE', accessor: 'role'},
      {
        Header: 'INVITATION STATUS',
        accessor: 'invitationStatus',
        Cell: (row: any) => {
          return (
            <div className='d-flex align-items-center justify-content-center'>
              {row.value === 'pending' && (
                <Badge bg='light-success' className='text-success fw-bolder'>
                  {row.value}
                </Badge>
              )}
              {row.value === 'accepted' && (
                <Badge bg='light-primary' className='text-primary fw-bolder'>
                  {row.value}
                </Badge>
              )}
              {row.value === 'failed' && (
                <Badge bg='light-danger' className='text-danger fw-bolder'>
                  {row.value}
                </Badge>
              )}
            </div>
          )
        },
      },
      {
        Header: 'EXPIRES ON',
        accessor: 'loginExpiresOn',
        Cell: (row: any) => {
          const formattedDate = moment(row.value).format('DD MMM, YYYY')
          return formattedDate
        },
      },
      {
        Header: 'ACTIONS',
        accessor: 'ID',
        disableSortBy: true,
        Cell: (row: any) => {
          const {original: inviteData} = row.row

          const onResendInvite = async (inviteId: any) => {
            try {
              setResendingInviteId(inviteId)
              await resendInvitesMutation.mutateAsync([inviteId])
            } catch {
            } finally {
              setResendingInviteId(null)
            }
          }

          if (inviteData.invitationStatus !== 'accepted') {
            return (
              <Button
                style={{color: '#009ef7', textDecoration: 'underline'}}
                variant='link'
                disabled={resendingInviteId === inviteData.id || !!selectedRows.length}
                onClick={() => onResendInvite(inviteData.id)}
              >
                Resend Invite
              </Button>
            )
          }

          return '' // Ensure consistent return type
        },
      },
    ],
    [selectedRows] // Dependencies array, empty for static columns
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    state: {pageIndex, globalFilter},
    setGlobalFilter,
  } = useTable(
    {
      // @ts-ignore
      columns,
      data,
      // @ts-ignore
      initialState: {pageIndex: 0, pageSize: 10},
      manualPagination: false,
      manualSortBy: false,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value || ''
    setGlobalFilter(value)
    toggleAllRowsSelection(false)
  }

  const toggleRowSelection = (row: any) => {
    if (selectedRows.includes(row.id)) {
      setSelectedRows(selectedRows.filter((rowId: any) => rowId !== row.id))
    } else {
      setSelectedRows([...selectedRows, row.id])
    }
  }

  const toggleAllRowsSelection = (isSelectAll: boolean) => {
    setSelectedRows(
      isSelectAll
        ? data.filter((row: any) => row?.invitationStatus !== 'accepted').map((row: any) => row.id)
        : []
    )
  }

  return (
    <div className='w-100'>
      <InvitesTableHeader
        bulkSelection={!!selectedRows.length}
        globalFilter={globalFilter}
        handleSearch={handleSearch}
        selectedRows={selectedRows}
      />
      {invitesResult.isLoading && <FetchingSpinner />}
      {!invitesResult.isLoading && (
        <Container className='mt-5'>
          <Table striped bordered hover {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()} className='text-gray'>
                  <th>
                    <div className='form-check'>
                      <input
                        type='checkbox'
                        className='form-check-input cursor-pointer'
                        onChange={(e) => toggleAllRowsSelection(e.target.checked)}
                        checked={
                          selectedRows.length > 0 &&
                          selectedRows.length >=
                            data.filter((row: any) => row?.invitationStatus !== 'accepted').length
                        }
                      />
                    </div>
                  </th>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{cursor: 'pointer'}}
                    >
                      {column.render('Header')}
                      {column.isSorted &&
                        (column.isSortedDesc ? (
                          <i className='fa-solid fa-chevron-down mx-2'></i>
                        ) : (
                          <i className='fa-solid fa-chevron-up mx-2'></i>
                        ))}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row: any) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    <td>
                      <div className='form-check'>
                        <input
                          type='checkbox'
                          className='form-check-input cursor-pointer'
                          onChange={() => toggleRowSelection(row.original)}
                          checked={selectedRows.includes(row.original?.id)}
                          disabled={row.original?.invitationStatus === 'accepted'}
                        />
                      </div>
                    </td>
                    {row.cells.map((cell: any) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </Table>
          {data.length === 0 && <em className='mt-5 italic'>No data found</em>}
          {data.length > 0 && (
            <div className='d-end align-items-center'>
              <Button
                variant='primary'
                size='sm'
                className='mx-1'
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {'<'}
              </Button>
              <Button
                variant='primary'
                size='sm'
                className='mx-1'
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {'>'}
              </Button>
              <span>
                Page
                <strong>
                  {pageIndex + 1} of {data.length}
                </strong>
              </span>
            </div>
          )}
        </Container>
      )}
    </div>
  )
}

export default InvitesIndex
