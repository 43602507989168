import apiClient from './apiClient'

import {getSelectedCommunityId} from '../modules/common/utils'
interface GetApiParams {
  page: any
  limit?: number
  search?: string
  role?: string | null
  status?: string | null
}

export const fetchInvites = async (params: GetApiParams): Promise<any> => {
  const response = await apiClient.get(`/users/c/${getSelectedCommunityId()}/invites`, {
    params,
  })
  return response.data?.data || {}
}

export const postInviteUser = async (inviteData: any): Promise<any> => {
  const response = await apiClient.post(`/users/c/${getSelectedCommunityId()}/invites`, inviteData)
  return response.data?.data || {}
}

export const postBulkInvites = async (formData: any): Promise<any> => {
  const response = await apiClient.post(
    `/users/c/${getSelectedCommunityId()}/invites/bulk`,
    formData
  )
  return response.data?.data || {}
}

export const postResendInvites = async (inviteIds: any): Promise<any> => {
  const response = await apiClient.post(`/users/c/${getSelectedCommunityId()}/invites/resend`, {
    inviteIds,
  })
  return response.data?.data || {}
}

export const deleteInvites = async (inviteIds: any): Promise<any> => {
  const response = await apiClient.delete(`/users/c/${getSelectedCommunityId()}/invites`, {
    data: {inviteIds},
  })
  return response.data?.data || {}
}
