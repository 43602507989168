import {useEffect, useState} from 'react'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import moment from 'moment'

import {
  useDeleteResource,
  useGetResources,
  useUpdateResourcesFolder,
} from '../../../queries/resources'
import CustomDropdown from '../../../modules/common/CustomDropDown'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import RenderFileIcon from '../../../modules/common/RenderFileIcon'
import EmptyMessage from '../../../modules/common/EmptyMessage'
import {fetchResourceById} from '../../../apis/resources'
import FolderForm from './FolderForm'
import FetchingSpinner from '../../../modules/common/FetchingSpinner'
import MoveResourceModal from './MoveResourceModal'

const ResourcesList = ({showBlock}: {showBlock: boolean}) => {
  const [selectedResource, setSelectedResource] = useState<any>(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showResourcesMoveModal, setShowResourcesMoveModal] = useState(false)
  const [showFolderModal, setShowFolderModal] = useState(false)
  const deleteResourcesMutation = useDeleteResource()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const folderId = searchParams.get('folderId')
  const search = searchParams.get('search')
  const resourcesResult = useGetResources({page: 1, limit: 100, folderId, search})
  const resourcesData = resourcesResult.data || {}
  const {parentFolder, currentFolder} = resourcesData

  useEffect(() => {
    if (folderId && currentFolder?.uuid !== folderId) {
      resourcesResult.refetch()
    }
  }, [folderId])

  useEffect(() => {
    resourcesResult.refetch()
  }, [search])

  const handleEdit = (resource: any) => {
    setSelectedResource(resource)
    setShowFolderModal(true)
  }

  const moveResource = (resource: any) => {
    setSelectedResource(resource)
    setShowResourcesMoveModal(true)
  }

  const handleDelete = (resourceId: any) => {
    deleteResourcesMutation.mutate(resourceId)
    setShowDeleteModal(false)
    setSelectedResource(null)
  }

  const confirmDelete = (resource: any) => {
    setSelectedResource(resource)
    setShowDeleteModal(true)
  }

  const downloadResource = async (resourceId: any) => {
    try {
      const resourceDetail = await fetchResourceById(resourceId)
      window.open(resourceDetail.fileUrl)
    } catch (e) {
      console.error(e || 'An error occurred while downloading resource')
    }
  }

  const handleClose = () => {
    setShowFolderModal(false)
    setSelectedResource(null)
  }

  const {mutate: updateFolder} = useUpdateResourcesFolder()

  return (
    <div className='container'>
      {currentFolder && (
        <nav aria-label='breadcrumb mb-3'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item fs-6 fw-bold'>
              <Link to={parentFolder?.uuid ? `?folderId=${parentFolder.uuid}` : '/resources'}>
                {parentFolder?.name ? parentFolder.name : 'My Files'}
              </Link>
            </li>
            <li className='breadcrumb-item active fs-6 fw-bold' aria-current='page'>
              {currentFolder.name}
            </li>
          </ol>
        </nav>
      )}
      <div className={`${!showBlock && 'd-flex flex-wrap'}`}>
        {resourcesData?.resources?.length === 0 && (
          <div className='w-75'>
            <EmptyMessage message='This folder is empty' />
          </div>
        )}
        {resourcesResult.isFetching && <FetchingSpinner />}
        {resourcesData?.resources?.map((resource: any) => (
          <div
            key={resource.id}
            className={`${showBlock ? 'd-block' : 'd-flex align-items-center'}`}
          >
            <div
              className={`d-flex justify-content-between min-w150 ${
                showBlock ? 'align-items-center h150' : 'h200'
              } border rounded px-3 m-2 ${resource.resourceType !== 'folder' && 'py-3'}`}
            >
              <div
                className={`${!showBlock ? 'd-block' : 'd-flex align-items-center'} cursor-pointer`}
                onClick={() => {
                  if (resource.resourceType === 'folder') {
                    navigate(`?folderId=${resource.uuid}`)
                  } else {
                    downloadResource(resource.uuid)
                  }
                }}
              >
                <div className='text-center'>
                  <RenderFileIcon
                    fileType={resource?.attachment?.fileType || resource.resourceType}
                    className='mb-3 w120'
                  />
                </div>
                <div
                  className={`${
                    !showBlock
                      ? 'd-block text-center'
                      : 'd-flex align-items-center justify-content-between w-100 ml10'
                  }`}
                >
                  <div>
                    <h4>
                      {resource.name.length > 15
                        ? resource.name.slice(0, 15) + '...'
                        : resource.name}
                    </h4>
                    <p className='text-gray'>{moment(resource.updatedAt).format('D/M/YYYY')}</p>
                  </div>
                </div>
              </div>

              <CustomDropdown
                elementId={selectedResource}
                options={[
                  {
                    label: 'Move to',
                    handler: () => moveResource(resource),
                  },
                  ...(resource.resourceType === 'folder'
                    ? [
                        {
                          label: 'Edit Folder',
                          handler: () => handleEdit(resource),
                        },
                      ]
                    : []),
                  {
                    label: `Delete ${resource.resourceType === 'folder' ? 'Folder' : 'File'}`,
                    handler: () => confirmDelete(resource),
                  },
                ]}
                className={`${!showBlock && 'mt-2'}`}
              />
            </div>
          </div>
        ))}
        {showFolderModal && (
          <FolderForm
            showFolderModal={showFolderModal}
            handleClose={handleClose}
            updateFolder={updateFolder}
            selectedResource={selectedResource}
          />
        )}
        {showDeleteModal && (
          <ConfirmationModal
            showModal={showDeleteModal}
            onHideModal={() => setShowDeleteModal(false)}
            disableBtns={false}
            onConfirm={() => handleDelete(selectedResource.uuid)}
            onCancel={() => setShowDeleteModal(false)}
          />
        )}
        {showResourcesMoveModal && (
          <MoveResourceModal
            showResourcesMoveModal={showResourcesMoveModal}
            setShowResourcesMoveModal={setShowResourcesMoveModal}
            resource={selectedResource}
          />
        )}
      </div>
    </div>
  )
}

export default ResourcesList
