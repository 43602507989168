import React from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {Button, Modal} from 'react-bootstrap'

import {useCreateCategory, useUpdateCategory} from '../../queries/categories'

interface CategoryFormProps {
  showModal: boolean
  handleClose: () => void
  categoryData?: any
}

const CategoriesForm: React.FC<CategoryFormProps> = ({showModal, handleClose, categoryData}) => {
  const {mutate: createCategory, isPending: isCreating} = useCreateCategory()
  const {mutate: updateCategory, isPending: isUpdating} = useUpdateCategory()
  const isEditCategory = categoryData?.id

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').max(30, 'Name must be 30 characters or less'),
    description: Yup.string().optional().max(150, 'Description must be 150 characters or less'),
  })

  return (
    <Modal show={showModal} onHide={handleClose} backdrop='static' size='lg' centered>
      <Modal.Header closeButton>
        <Modal.Title>{isEditCategory ? 'Edit Category' : 'Add Category'}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-5 py-3'>
        <Formik
          initialValues={{
            name: categoryData?.name || '',
            description: categoryData?.description || '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting, resetForm}) => {
            try {
              if (isEditCategory) {
                updateCategory({categoryId: categoryData.id, categoriesData: values})
              } else {
                createCategory(values)
              }
              resetForm()
              handleClose()
            } catch {}

            setSubmitting(false)
          }}
        >
          {({isSubmitting, isValid, values}) => (
            <Form>
              <div className='form-group'>
                <label htmlFor='name' className='fs-5 mt-3 fw-bold required'>
                  Name
                </label>
                <Field
                  type='text'
                  className='form-control'
                  id='name'
                  name='name'
                  placeholder='Enter category name'
                />
                <ErrorMessage name='name' component='div' className='text-danger' />
              </div>
              <div className='d-end text-gray'>{values.name.length}/30</div>
              <div className='form-group'>
                <label htmlFor='description' className='fs-5 mt-3 fw-bold'>
                  Description
                </label>
                <Field
                  component='textarea'
                  className='form-control'
                  id='description'
                  name='description'
                  placeholder='Enter category description'
                />
              </div>
              <div className='d-end text-gray'>{values.description.length}/150</div>
              <div className='mt-5 d-end'>
                <Button
                  type='submit'
                  variant='primary'
                  className='mr15'
                  disabled={isSubmitting || !isValid}
                >
                  {isCreating || isUpdating
                    ? 'Submitting...'
                    : isEditCategory
                    ? 'Update Category'
                    : 'Add Category'}
                </Button>
                <Button type='button' variant='secondary' onClick={handleClose}>
                  Discard
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default CategoriesForm
