import {useState, useEffect, Fragment} from 'react'
import {useQuery} from '@tanstack/react-query'
import {useNavigate, useSearchParams} from 'react-router-dom'

import {searchInCommunity} from '../../apis/feeds'
import FetchingSpinner from '../../modules/common/FetchingSpinner'
import UserCommunityCard from '../../modules/common/UserCommunityCard'
import {Pagination} from '../../hooks/Pagination'
import FeedCard from './components/FeedCard'
import MemberCard from './components/MemberCard'

const GlobalSearchIndex = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const tab = searchParams.get('tab') || 'community'
  const page = searchParams.get('page') || '1'
  const search = searchParams.get('q') || ''
  const [currentTab, setCurrentTab] = useState(tab)

  const searchResults = useQuery({
    queryKey: ['feeds-search-results'],
    queryFn: () => searchInCommunity({search, page: page || 1, limit: 10}),
    enabled: !!search,
  })

  useEffect(() => {
    setCurrentTab(tab)
  }, [tab])

  useEffect(() => {
    searchResults.refetch()
  }, [page, search])

  const handlePage = (page: number) => {
    let params = `?q=${search}&tab=${tab}&page=${page}`
    navigate(params)
  }

  const feedsResults = searchResults?.data?.feedsResults ?? []
  const usersResults = searchResults?.data?.usersResults ?? []

  return (
    <>
      <div className='kt-content-main'>
        {searchResults.isFetching && <FetchingSpinner />}
        {!searchResults.isFetching && (
          <div className='w-100'>
            <div className='bg-light d-flex px-4 py-3 mb-3 rounded cursor-pointer'>
              <div className='border-0 mx-5 mt-1'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap d-flex justify-content-center'>
                  <li className='nav-item'>
                    <div
                      onClick={() => navigate(`?q=${search}&tab=community`)}
                      className={`${
                        currentTab === 'community' ? 'active' : ''
                      } nav-link text-active-primary me-6 cursor-pointer`}
                    >
                      Community
                      <span className='mx-2'>({feedsResults?.totalItems})</span>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div
                      onClick={() => navigate(`?q=${search}&tab=members`)}
                      className={`${
                        currentTab === 'members' ? 'active' : ''
                      } nav-link text-active-primary me-6 cursor-pointer`}
                    >
                      Members
                      <span className='mx-2'>({usersResults?.totalItems})</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              {currentTab === 'community' && (
                <Fragment>
                  {!feedsResults?.feeds.length && (
                    <em className='px-3 fw-bold'>No Records found</em>
                  )}
                  {feedsResults?.feeds.map((feed: any) => (
                    <FeedCard feed={{...feed, isPinned: false}} searchKeyword={search} />
                  ))}
                  {feedsResults?.totalPages > 1 && (
                    <Pagination
                      page={feedsResults.page}
                      totalPages={feedsResults.totalPages}
                      handlePagination={handlePage}
                    />
                  )}
                </Fragment>
              )}
              {currentTab === 'members' && (
                <Fragment>
                  {!usersResults?.users.length && (
                    <em className='px-3 fw-bold'>No Records found</em>
                  )}
                  {usersResults?.users.map((user: any) => (
                    <MemberCard user={user} searchKeyword={search} />
                  ))}
                  <div className='d-flex mt-3 justify-content-center align-items-center'>
                    {usersResults?.totalPages > 1 && (
                      <Pagination
                        page={usersResults.page}
                        totalPages={usersResults.totalPages}
                        handlePagination={handlePage}
                      />
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        )}
      </div>
      <div className='kt-content-right'>
        <div className={`kt-content-right_fixed`}>
          <UserCommunityCard />
        </div>
      </div>
    </>
  )
}

export default GlobalSearchIndex
