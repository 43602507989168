import {useState} from 'react'
import {Button} from 'react-bootstrap'

import {useGetCategories, useDeleteCategory} from '../../queries/categories'
import CategoriesForm from './CategoriesForm'
import CustomDropdown from '../../modules/common/CustomDropDown'
import {ConfirmationModal} from '../../modules/common/ConfirmationModal'

const CategoriesIndex = () => {
  const {data: categories} = useGetCategories()
  const deleteCategoryMutation = useDeleteCategory()
  const [showModal, setShowModal] = useState(false)
  const [currentCategory, setCurrentCategory] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [categoryToDelete, setCategoryToDelete] = useState(null)

  const categoriesData = categories?.categories

  const handleShow = () => setShowModal(true)
  const handleClose = () => {
    setShowModal(false)
    setCurrentCategory(null)
  }

  const handleEdit = (category: any) => {
    setCurrentCategory(category)
    handleShow()
  }

  const confirmDelete = (categoryId: any) => {
    setCategoryToDelete(categoryId)
    setShowDeleteModal(true)
  }

  const handleDelete = () => {
    if (categoryToDelete) {
      deleteCategoryMutation.mutate(categoryToDelete)
      setShowDeleteModal(false)
      setCategoryToDelete(null)
    }
  }

  return (
    <div className='container my-5'>
      <div className='d-end'>
        <Button variant='primary' onClick={handleShow}>
          Add Category
        </Button>
      </div>
      {showModal && (
        <CategoriesForm
          showModal={showModal}
          handleClose={handleClose}
          categoryData={currentCategory}
        />
      )}

      <div>
        {categoriesData?.map((category: any) => (
          <div
            key={category.id}
            className='d-flex justify-content-between align-items-center'
            style={{borderBottom: '1px solid #e1e3ea'}}
          >
            <div className='m-5'>
              <h2>{category.name}</h2>
              <p>{category.description}</p>
            </div>
            <div>
              <CustomDropdown
                elementId={category.id}
                options={[
                  {
                    label: 'Edit',
                    handler: () => handleEdit(category),
                  },
                  {
                    label: 'Delete',
                    handler: () => confirmDelete(category.id),
                  },
                ]}
              />
              {showDeleteModal && (
                <ConfirmationModal
                  showModal={showDeleteModal}
                  onHideModal={() => setShowDeleteModal(false)}
                  onCancel={() => setShowDeleteModal(false)}
                  title='Delete Category'
                  subtitle='Are you sure you want to delete category?'
                  onConfirm={handleDelete}
                  disableBtns={deleteCategoryMutation.isPending}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CategoriesIndex
