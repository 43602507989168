import {getSelectedCommunityId} from '../modules/common/utils'
import apiClient from './apiClient'

interface GetApiParams {
  page: number
  limit?: number
}

export const fetchCourses = async (params: GetApiParams): Promise<any> => {
  // expecting courses to be fetched from /courses
  const response = await apiClient.get('/users/wp/courses', {
    params,
  })
  return response?.data?.data || []
}

export const postCourse = async (courseData: any) => {
  const response = await apiClient.post(`/c/${getSelectedCommunityId()}/courses`, courseData)
  return response.data?.data || {}
}

export const putCourse = async (courseId: number, courseData: any) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/courses/${courseId}`,
    courseData
  )
  return response.data?.data || {}
}

export const putCourseOrder = async (courseId: string, order: number) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/courses/${courseId}/order/${order}`
  )
  return response.data?.data || {}
}

export const deleteCourse = async (courseId: number) => {
  const response = await apiClient.delete(`/c/${getSelectedCommunityId()}/courses/${courseId}`)
  return response.data?.data || {}
}

export const getCourses = async (params: GetApiParams): Promise<any> => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/courses`, {
    params,
  })
  return response.data?.data || []
}

export const getCourse = async (courseId: string | undefined): Promise<any> => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/courses/${courseId}`)
  return response.data?.data || {}
}

export const postCourseSection = async (courseId: number, sectionData: any) => {
  const response = await apiClient.post(
    `/c/${getSelectedCommunityId()}/courses/${courseId}/sections`,
    sectionData
  )
  return response.data?.data || {}
}

export const updateCourseSection = async (
  courseId: number,
  sectionId: number,
  sectionData: any
) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/courses/${courseId}/sections/${sectionId}`,
    sectionData
  )
  return response.data?.data || {}
}

export const updateCourseSectionOrder = async (
  courseId: string,
  sectionId: number,
  order: number
) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/courses/${courseId}/sections/${sectionId}/order/${order}`
  )
  return response.data?.data || {}
}

export const deleteCourseSection = async (courseId: number, sectionId: number) => {
  const response = await apiClient.delete(
    `/c/${getSelectedCommunityId()}/courses/${courseId}/sections/${sectionId}`
  )
  return response.data?.data || {}
}

export const postCourseModule = async (courseId: number, moduleData: any) => {
  const response = await apiClient.post(
    `/c/${getSelectedCommunityId()}/courses/${courseId}/modules`,
    moduleData
  )
  return response.data?.data || {}
}

export const updateCourseModule = async (courseId: number, moduleId: number, moduleData: any) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/courses/${courseId}/modules/${moduleId}`,
    moduleData
  )
  return response.data?.data || {}
}

export const updateCourseModuleOrder = async (
  courseId: string,
  moduleId: number,
  order: number
) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/courses/${courseId}/modules/${moduleId}/order/${order}`
  )
  return response.data?.data || {}
}

export const updateModuleSection = async (
  courseId: string,
  moduleId: number,
  newSectionId: number
) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/courses/${courseId}/modules/${moduleId}/update-section`,
    {newSectionId}
  )
  return response.data?.data || {}
}

export const deleteCourseModule = async (courseId: number, moduleId: number) => {
  const response = await apiClient.delete(
    `/c/${getSelectedCommunityId()}/courses/${courseId}/modules/${moduleId}`
  )
  return response.data?.data || {}
}

export const postCourseModuleProgress = async (
  courseId: number,
  moduleId: number,
  progressData: any
) => {
  const response = await apiClient.post(
    `/c/${getSelectedCommunityId()}/courses/${courseId}/modules/${moduleId}/update-progress`,
    progressData
  )
  return response.data?.data || {}
}
