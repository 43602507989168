import React, {useEffect, useState, Fragment, useRef} from 'react'

import {usePostChatMessage, usePostNewChat} from '../../../queries/aiChat'
import {useNavigate} from 'react-router-dom'
import ChatMessage from './ChatMessage'
import ChatInput from './ChatInput'
import WelcomeScreen from './WelcomeScreen'
import GovChatSpinner from './GovChatSpinner'
import FetchPlaceholder from '../../../modules/common/FetchPlaceholder'

interface ChatMessagesProps {
  chatMessages: any
  chatId: string
  isFetching: boolean
}

const ChatMessages: React.FC<ChatMessagesProps> = ({chatMessages = [], isFetching, chatId}) => {
  const navigate = useNavigate()
  const postNewChat = usePostNewChat()
  const postChatMessage = usePostChatMessage()
  const [messages, setMessages] = useState(chatMessages)
  const [inputText, setInputText] = useState('')
  const [pendingMessage, setPendingMessage] = useState<string | null>(null)
  const chatMessagesRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setMessages(chatMessages)
    setTimeout(scrollToBottom, 100)
  }, [chatMessages])

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight
    }
  }

  const sendMessage = async () => {
    let message = inputText.trim()
    setInputText('')
    if (!message) {
      return
    }
    setPendingMessage(message)
    setTimeout(scrollToBottom, 100)

    try {
      let response = chatId
        ? await postChatMessage.mutateAsync({chatId, chatData: {message}})
        : await postNewChat.mutateAsync({chatData: {message}})

      setMessages([...messages, ...response.messages])
      setPendingMessage(null)
      if (!chatId) {
        navigate(`/govchat/${response.chat?.uuid}`)
      }
      setTimeout(scrollToBottom, 100)
    } catch {
      setPendingMessage(null)
      setInputText(message)
    }
  }

  useEffect(() => {
    setInputText('')
  }, [chatId])

  return (
    <Fragment>
      {isFetching && !pendingMessage && <FetchPlaceholder />}
      <div style={{height: '70vh', overflowY: 'scroll'}} ref={chatMessagesRef}>
        {!chatId && !pendingMessage && (
          <WelcomeScreen onSelectDefaultMessage={(message: string) => setInputText(message)} />
        )}

        {messages?.map((msgData: any) => (
          <ChatMessage key={msgData.id} msgData={msgData} />
        ))}

        {pendingMessage && <ChatMessage msgData={{isAiResponse: false, message: pendingMessage}} />}

        {(postChatMessage.isPending || postNewChat.isPending) && <GovChatSpinner />}
      </div>

      <ChatInput
        inputText={inputText}
        sendMessage={sendMessage}
        setInputText={setInputText}
        isPending={postNewChat.isPending || postNewChat.isPending}
      />
    </Fragment>
  )
}

export default ChatMessages
