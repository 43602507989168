import {Link, useNavigate} from 'react-router-dom'
import moment from 'moment'
import {Fragment} from 'react'
import './CommunityIndex.css'
import Image from '../../modules/common/Image'

const CommunityCard = ({users}: any) => {
  const navigate = useNavigate()

  return (
    <div className='d-block w-100 h-fit mt-5'>
      {users &&
        users.map((user: any) => (
          <div
            key={user?.id}
            style={{width: '100%'}}
            className='connection-card rounded text-center border shadow-sm py-2 px-5 d-flex align-items-center'
          >
            <div className='d-flex w-100'>
              <div className='py-3'>
                <Image src={`${user?.absoluteProfilePath}`} className='mb-4 img-round' />
              </div>
              <div className='text-start mx-5 py-3 w-100'>
                <div className='d-flex w-100 justify-content-between'>
                  <div>
                    <Link
                      to={`/view-profile/${user?.id}`}
                      className='menu-link card-title fs-4 fw-bold'
                    >
                      {user.firstName} {user.lastName}
                    </Link>
                    <p className='mb-1'>
                      {user.companyName && ` ${user.companyName} | `}
                      {user.createdAt &&
                        `Joined ${moment.unix(user.createdAt).format('MMM DD, YYYY')}`}
                    </p>
                    <p className='text-black fw-bold'>{user.bio || ''}</p>
                  </div>
                  <div className='mb-5' style={{marginRight: '-16px'}}>
                    <button
                      onClick={() => navigate(`/messages/${user.id}`)}
                      className='p-4 rounded border-0 bg-blue-100 text-primary fw-bold align-items-center'
                      style={{width: '95px'}}
                    >
                      <i className='fa-solid text-primary mx-2 fa-envelope'></i> Chat
                    </button>
                  </div>
                </div>

                <div className='d-flex justify-content-between community-card-inner-section'>
                  <div className='w-50'>
                    <div className='d-flex align-items-center my-2 justify-content-start'>
                      <span className='bg-blue-100 mr-2 py-1 px-2 rounded-circle'>
                        <i className='text-primary fa-solid fa-envelope'></i>
                      </span>
                      <p className='card-text mx-2 text-gray fw-bold'>{user.email}</p>
                    </div>
                  </div>

                  <div className='w-50'>
                    <div className='d-flex align-items-start my-3 justify-content-start'>
                      <span className='bg-blue-100 mr-2 py-1 px-2 rounded-circle'>
                        <i className='text-primary fa-solid fa-location-dot'></i>
                      </span>
                      <p className='card-text mx-2 text-gray fw-bold'>
                        {`${user.state || ''} ${user.city || ''} `}
                      </p>
                    </div>
                  </div>
                </div>
                {user?.userWebsites.length > 0 && (
                  <div className='d-flex align-items-center mb-5 justify-content-start w-100'>
                    <span className='bg-blue-100 mr-2 py-1 px-2 rounded-circle'>
                      <i className='text-primary fa-solid fa-link'></i>
                    </span>
                    <p className='card-text mx-2 text-gray fw-bold'>
                      {user?.userWebsites?.map((website: any, index: number) => (
                        <Fragment key={index}>
                          <a
                            href={website.websiteUrl}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='fw-bold cursor-pointer fs-6 word-wrap'
                          >
                            {website.websiteUrl}
                          </a>
                          {index < user.userWebsites.length - 1 && <span> | </span>}
                        </Fragment>
                      ))}
                    </p>
                  </div>
                )}
                {user?.userCertifications.length > 0 && (
                  <div className='d-flex align-items-start justify-content-start'>
                    <span className='bg-blue-100 mr-2 py-1 px-2 rounded-circle'>
                      <i className='text-primary fa-solid fs-6 fa-file'></i>
                    </span>
                    <p className='card-text mx-2 text-gray fw-bold'>
                      <span className='fw-bold fs-6'>
                        {user?.userCertifications
                          ?.map((certification: any) => certification?.certificate?.name)
                          .join(', ')}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default CommunityCard
