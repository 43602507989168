import {FormatedDateDifferenceComment} from '../../../modules/common/utils'
import Image from '../../../modules/common/Image'

const MessageRow = ({
  messageData,
  isRecipientMessage,
}: {
  messageData: any
  isRecipientMessage: boolean
}) => {
  const formatMessage = (message: string) => {
    const messages = message?.split(' ')
    return messages.map((message, index) => {
      if (message.startsWith('http://') || message.startsWith('https://')) {
        return (
          <a
            className='comments-link'
            key={index}
            href={message}
            target='_blank'
            rel='noopener noreferrer'
          >
            {index === 0 ? message : ` ${message}`}
          </a>
        )
      } else {
        return <span key={index}>{index === 0 ? message : ` ${message}`}</span>
      }
    })
  }
  return (
    <div className='chat-item-sender d-block' key={messageData.id}>
      <div className='d-block'>
        <div
          className={`d-flex ${
            isRecipientMessage ? 'justify-content-end mx-5' : 'justify-content-start'
          }`}
        >
          <div className='d-flex align-items-baseline'>
            <div>
              <Image src={`${messageData.sender.absoluteProfilePath}`} />
            </div>
            <div className='d-flex justify-content-end'>
              <p className='mx-2'>
                {messageData.sender.firstName} {messageData.sender.lastName}
              </p>
              <p style={{color: 'rgb(161, 165, 183)'}}>
                {FormatedDateDifferenceComment(messageData.createdAt)}
              </p>
            </div>
          </div>
        </div>
        <div
          className={`d-flex ${
            isRecipientMessage ? 'justify-content-end mx-5' : 'justify-content-start'
          }`}
        >
          <div
            className='chat-msg p-3 rounded my-2'
            style={{
              maxWidth: '50%',
              backgroundColor: isRecipientMessage ? '#eff6ff' : 'var(--bs-light)',
            }}
          >
            {formatMessage(messageData.message)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageRow
