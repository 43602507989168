import React, {useState, useRef} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {Formik} from 'formik'
import * as Yup from 'yup'

import {useCreateCourse, useUpdateCourse} from '../../../queries/courses'
import SelectContentType from '../../../modules/common/SelectContentType'

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .max(50, 'Course title cannot exceed 50 characters'),
  description: Yup.string()
    .required('Description is required')
    .max(150, 'Course description cannot exceed 150 characters'),
  status: Yup.string().optional(),
  packageId: Yup.string().optional(),
})

interface CoursesFormProps {
  show?: any
  handleClose: any
  course?: {
    id: number | string
    uuid: string
    title: string
    description: string
    coverPic: string
    status: string
    packageId: string
  }
}

const defaultCourse = {
  id: '',
  uuid: '',
  title: '',
  description: '',
  coverPic: '',
  status: 'published',
  packageId: '',
}

const CoursesForm: React.FC<CoursesFormProps> = ({show, handleClose, course = defaultCourse}) => {
  const {mutateAsync: createCourse} = useCreateCourse()
  const {mutateAsync: updateCourse} = useUpdateCourse()
  const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL
  const [previewImage, setPreviewImage] = useState<string>(
    course.coverPic ? `${s3BaseUrl}${course.coverPic}` : ''
  )
  const [selectedCover, setSelectedCover] = useState<any>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null
    setSelectedCover(file)
    if (file) {
      setPreviewImage(URL.createObjectURL(file))
    } else {
      setPreviewImage('/media/avatars/blank.png')
    }
  }

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        aria-labelledby='example-modal-sizes-title-lg'
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>{course?.id ? 'Edit Course' : 'Add Course'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              title: course.title,
              description: course.description,
              status: course.status || 'published',
              packageId: course.packageId,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              try {
                const formData = new FormData()
                if (selectedCover) formData.append('file', selectedCover)
                formData.append('title', values.title)
                formData.append('description', values.description)
                formData.append('status', values.status)
                formData.append('packageId', values.packageId)
                if (course.uuid) {
                  await updateCourse({courseId: course.uuid, courseData: formData})
                } else {
                  await createCourse(formData)
                }
                resetForm()
                setSelectedCover(null)
                setPreviewImage('')
                handleClose()
              } catch {
              } finally {
                setSubmitting(false)
              }
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              isSubmitting,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                  <Form.Control
                    type='text'
                    placeholder='Course name'
                    autoFocus
                    name='title'
                    value={values.title}
                    onChange={handleChange}
                    isInvalid={touched.title && !!errors.title}
                  />
                  <div className='d-flex align-items-center justify-content-between'>
                    <div>
                      {errors.title && touched.title && (
                        <div className='text-danger'>{errors.title}</div>
                      )}
                    </div>
                    <div>
                      <p className='text-muted mt-3'>
                        {values.title?.length} / {50}
                      </p>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                  <Form.Control
                    as='textarea'
                    rows={3}
                    placeholder='Course description'
                    name='description'
                    value={values.description}
                    onChange={handleChange}
                    isInvalid={touched.description && !!errors.description}
                  />
                  <div className='d-flex align-items-center justify-content-between'>
                    <div>
                      {errors.description && touched.description && (
                        <div className='text-danger'>{errors.description}</div>
                      )}
                    </div>
                    <div>
                      <p className='text-muted mt-3'>
                        {values.description?.length} / {150}
                      </p>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group>
                  <SelectContentType
                    value={values.packageId}
                    handleOnChange={(e) => setFieldValue('packageId', e.target.value)}
                    label='Who can see this Courses:'
                  />
                </Form.Group>
                <Form.Group controlId='formFile' className='mb-3'>
                  <div className='d-flex'>
                    <div onClick={() => fileInputRef.current?.click()} className='cursor-pointer'>
                      <img
                        src={previewImage || '/media/avatars/blank.png'}
                        className='rounded object-center object-cover'
                        alt='Profile Preview'
                        style={{
                          width: '365px',
                          height: '188px',
                        }}
                      />
                    </div>
                    <div className='mx-6'>
                      <div>
                        <h5 className='fw-bold'>Cover</h5>
                        <p className='text-gray'>460 x 752 px</p>
                      </div>
                      <Button
                        onClick={() => fileInputRef.current?.click()}
                        type='button'
                        variant='secondary'
                      >
                        Change
                      </Button>
                      <input
                        ref={fileInputRef}
                        type='file'
                        accept='image/*'
                        style={{display: 'none'}}
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                </Form.Group>
                <div className='d-flex justify-content-between align-items-center mt-9'>
                  <div className={`form-check form-switch align-items-center`}>
                    <input
                      className='form-check-input cursor-pointer'
                      type='checkbox'
                      id='flexSwitchCheckDefault'
                      name='isActive'
                      checked={values.status === 'published'}
                      onChange={() => {
                        const newStatus = values.status === 'published' ? 'draft' : 'published'
                        setFieldValue('status', newStatus)
                      }}
                    />
                    <span className='fs-5 fw-bold'>
                      {values.status === 'published' ? 'Publish' : 'Draft'}
                    </span>
                  </div>
                  <div>
                    <Button className='mx-3' variant='light' onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      variant='primary'
                      type='submit'
                      disabled={isSubmitting || Object.keys(errors).length !== 0}
                    >
                      {course?.id ? 'Update Course' : 'Add Course'}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default CoursesForm
