export default function EditSvg() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
      <g filter='url(#filter0_d_492_1871)'>
        <rect x='5.75' y='5.5' width='21' height='21' rx='6' fill='white' />
      </g>
      <path
        d='M16.88 11.8L12.775 16.145C12.62 16.31 12.47 16.635 12.44 16.86L12.255 18.48C12.19 19.065 12.61 19.465 13.19 19.365L14.8 19.09C15.025 19.05 15.34 18.885 15.495 18.715L19.6 14.37C20.31 13.62 20.63 12.765 19.525 11.72C18.425 10.685 17.59 11.05 16.88 11.8Z'
        stroke='black'
        strokeWidth='0.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.1954 12.525C16.4104 13.905 17.5304 14.96 18.9204 15.1'
        stroke='black'
        strokeWidth='0.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <filter
          id='filter0_d_492_1871'
          x='0.75'
          y='0.5'
          width='31'
          height='31'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_492_1871' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_492_1871'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  )
}
