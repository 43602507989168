import {getSelectedCommunityId} from '../modules/common/utils'
import apiClient from './apiClient'

interface GetApiParams {
  page?: number | string
  limit?: number | string
  chatId?: string
}

export const postNewChat = async (chatData: any) => {
  const response = await apiClient.post(`/c/${getSelectedCommunityId()}/ai-chat`, chatData)
  return response.data?.data || {}
}

export const postChatMessage = async (chatId: string, chatData: any) => {
  const response = await apiClient.post(
    `/c/${getSelectedCommunityId()}/ai-chat/${chatId}`,
    chatData
  )
  return response.data?.data || {}
}

export const getUserChats = async (params: GetApiParams): Promise<any> => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/ai-chat`, {
    params,
  })
  return response.data?.data || []
}

export const getUserChatMessages = async (params: GetApiParams): Promise<any> => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/ai-chat/${params.chatId}`, {
    params: {
      limit: params.limit,
      page: params.page,
    },
  })
  return response.data?.data || []
}

export const deleteUserChat = async (chatId: string) => {
  const response = await apiClient.delete(`/c/${getSelectedCommunityId()}/ai-chat/${chatId}`)
  return response.data?.data || {}
}
