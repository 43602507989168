import Highlighter from 'react-highlight-words'
import {useState} from 'react'

import {FormatedDateDifference, FormatedDateDifferenceComment} from '../../../modules/common/utils'
import ProfilePreview from '../../../modules/common/ProfilePreview'
import FeedDetailModal from '../../feeds/components/FeedDetailModal'
import Image from '../../../modules/common/Image'

const FeedIndexCard = ({feed, searchKeyword}: any) => {
  const MAX_DESCRIPTION_LENGTH = 160
  const [showFeedDetailModal, setShowFeedDetailModal] = useState(false)

  const renderFeedAttachment = () => {
    const imageAttachment = feed._attachments?.find((attachment: any) =>
      attachment.fileType.includes('image')
    )
    if (imageAttachment) {
      return (
        <div className='w-15 feed-attachments'>
          <img
            className='h100 w100 rounded mb-2'
            src={`${process.env.REACT_APP_S3_BASE_URL}${imageAttachment.signedFileName}`}
            alt='img'
          />
        </div>
      )
    }
  }

  const renderHighlightedDescription = (description: string) => {
    let exceedLimit = description.length > MAX_DESCRIPTION_LENGTH
    const getHighlightedDescription = (text: string) => {
      return text.replace(
        new RegExp(searchKeyword, 'gi'),
        (match) => `<mark class="gov-highlight">${match}</mark>`
      )
    }

    const searchIndex = description.toLowerCase().indexOf(searchKeyword.toLowerCase())
    if (searchIndex > -1) {
      return (
        <p
          style={{fontSize: '16px', wordBreak: 'break-word'}}
          dangerouslySetInnerHTML={{
            __html: getHighlightedDescription(
              `${description.substring(searchIndex, searchIndex + MAX_DESCRIPTION_LENGTH)} ${
                exceedLimit ? '...' : ''
              }`
            ),
          }}
        ></p>
      )
    }

    return (
      <p
        style={{fontSize: '16px', wordBreak: 'break-word'}}
        dangerouslySetInnerHTML={{
          __html: getHighlightedDescription(
            `${description.substring(0, MAX_DESCRIPTION_LENGTH)} ${exceedLimit ? '...' : ''}`
          ),
        }}
      ></p>
    )
  }

  return (
    <div className='p-0 my-4 rounded feeds-card'>
      <div className='container-fluid shadow-sm bg-light py-5 feeds-inner-card'>
        <div>
          <div className='d-flex align-items-start'>
            <Image
              src={`${feed.user?.absoluteProfilePath}`}
              className='w40 h40 rounded-circle object-cover object-top'
            />
            <div>
              <ProfilePreview user={feed.user} />
              <p className='mx-2'> Posted {FormatedDateDifference(feed.createdAt)}</p>
            </div>
          </div>
          <div
            className={`cursor-pointer ${
              feed?._attachments?.length > 0 && 'd-flex justify-content-between align-items-start'
            }`}
            onClick={() => setShowFeedDetailModal(true)}
          >
            <div className={`${feed?._attachments?.length > 0 ? 'w-85' : 'w-100'}`}>
              <h1>
                <Highlighter
                  highlightClassName='gov-highlight'
                  searchWords={[searchKeyword]}
                  autoEscape={true}
                  textToHighlight={feed.title}
                />
              </h1>
              <div>{renderHighlightedDescription(feed.description)}</div>
            </div>
            {renderFeedAttachment()}
          </div>
        </div>
        <div>
          <div className='comments-section'>
            {feed.comments?.slice(0, 2).map((comment: any, index: number) => (
              <div key={index} className='comment'>
                <div className='d-flex my-3 align-items-start position-relative'>
                  <Image
                    src={`${comment.user?.absoluteProfilePath}`}
                    className='w40 h40 rounded-circle object-cover object-top'
                  />
                  <div
                    style={{border: '1px solid #cdcdcd'}}
                    className='mx-3 py-3 overflow-x-scroll w-100 bg-light rounded'
                  >
                    <p className='my-0 mx-3'>
                      {comment.user && `${comment.user.firstName} ${comment.user.lastName}`}
                      <span style={{marginLeft: '5px'}} className='text-gray'>
                        {FormatedDateDifferenceComment(comment.createdAt)}
                      </span>
                    </p>
                    <div className='w-100'>
                      <p className='my-0 mx-3'>
                        <Highlighter
                          highlightClassName='gov-highlight'
                          searchWords={[searchKeyword]}
                          textToHighlight={comment.comment}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <FeedDetailModal
        showFeedDetailModal={showFeedDetailModal}
        feed={feed}
        handleModalClose={() => setShowFeedDetailModal(false)}
      />
    </div>
  )
}

export default FeedIndexCard
