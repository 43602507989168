import React from 'react'
import {Button, Dropdown, Form} from 'react-bootstrap'
import {useNavigate, useSearchParams} from 'react-router-dom'

function InvitesFilter() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const role = searchParams.get('role') ?? ''
  const status = searchParams.get('status') ?? ''
  const [filterOptions, setFilterOptions] = React.useState<any>({role, status})
  const [isFilterOpen, setIsFilterOpen] = React.useState(false)

  const onChangeFilterOption = (value: string, type: string) => {
    setFilterOptions({...filterOptions, [type]: value})
  }

  const handleApplyFilter = () => {
    setIsFilterOpen(false)
    let params = '?tab=invites'
    if (filterOptions.role) {
      params += `&role=${filterOptions.role}`
    }

    if (filterOptions.status) {
      params += `&status=${filterOptions.status}`
    }
    navigate(params)
  }

  const handleResetFilter = () => {
    setFilterOptions({role: '', status: ''})
    setIsFilterOpen(false)
    navigate('?tab=invites')
  }

  return (
    <div>
      <Dropdown show={isFilterOpen} onToggle={(isFilterOpen) => setIsFilterOpen(isFilterOpen)}>
        <Dropdown.Toggle
          className={`hide-dropdown-toggle-icon btn mx-3 d-flex align-items-center ${
            filterOptions.role || filterOptions.status ? 'btn-primary' : 'btn-light-primary'
          }`}
          id='dropdown-invites'
        >
          <i className='fa-solid fa-filter mx-1'></i> Filter
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className='w-300px w-md-325px'>
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
            </div>
            <div className='content-divider'></div>
            <div className='px-7 py-5' data-kt-user-table-filter='form'>
              <div className='mb-10'>
                <Form.Label className='fs-6 fw-bold'>Role:</Form.Label>
                <Form.Select
                  className='form-select form-select-solid'
                  onChange={(e) => onChangeFilterOption(e.target.value, 'role')}
                  value={filterOptions.role}
                >
                  <option value=''>Select</option>
                  <option value='free_member'>Free Member</option>
                  <option value='member'>Member</option>
                  <option value='moderator'>Moderator</option>
                  <option value='admin'>Community Admin</option>
                </Form.Select>
              </div>

              <div className='mb-10'>
                <Form.Label className='fs-6 fw-bold'>Invitation Status:</Form.Label>
                <Form.Select
                  className='form-select form-select-solid'
                  onChange={(e) => onChangeFilterOption(e.target.value, 'status')}
                  value={filterOptions.status}
                >
                  <option value=''>Select</option>
                  <option value='pending'>Pending</option>
                  <option value='accepted'>Accepted</option>
                  <option value='failed'>Failed</option>
                </Form.Select>
              </div>

              <div className='d-end'>
                <Button
                  variant='light'
                  className='btn-active-light-primary fw-bold me-2 px-6'
                  onClick={handleResetFilter}
                >
                  Reset
                </Button>
                <Button variant='primary' className='fw-bold px-6' onClick={handleApplyFilter}>
                  Apply
                </Button>
              </div>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default InvitesFilter
