import React, {useState} from 'react'
import moment from 'moment'
import {Button, Modal} from 'react-bootstrap'
import {AddToCalendarButton} from 'add-to-calendar-button-react'

import {useAuth} from '../../../modules/auth'
import {useDeleteEvent, useDeleteEventSeries} from '../../../queries/events'
import RecUpdateOptions from './recurring/RecUpdateOptions'

interface EventDetailModalProps {
  showEventDetailModal: boolean
  handleEventDetailModalClose: any
  selectedEvent: any
  selectedCommunity: any
  showEditEventModal: () => void
  setSelectedOption: any
  selectedOption: any
  setShowEventDetailModal: (show: boolean) => void
}

const EventDetailModal: React.FC<EventDetailModalProps> = ({
  showEventDetailModal,
  handleEventDetailModalClose,
  selectedEvent,
  selectedCommunity,
  showEditEventModal,
  setSelectedOption,
  selectedOption,
  setShowEventDetailModal,
}) => {
  const [showDeleteSeriesModal, setShowDeleteSeriesModal] = useState(false)
  const [showEditSeriesModal, setShowEditSeriesModal] = useState(false)
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false)
  const {getUserTimezone} = useAuth()
  const {mutateAsync: deleteSingleEvent} = useDeleteEvent()
  const {mutateAsync: deleteEventSeries} = useDeleteEventSeries()
  const startDateMoment = moment.tz(selectedEvent?.startDate, getUserTimezone())
  const endDateMoment = moment.tz(selectedEvent?.endDate, getUserTimezone())
  const formattedDate =
    startDateMoment.format('dddd, MMMM Do [@] hh:mm A - ') + endDateMoment.format('hh:mm A')

  const deleteEvent = async () => {
    try {
      const eventData: {originalTimestamp: string; actionType?: string} = {
        originalTimestamp: selectedEvent?.originalTimestamp,
      }
      if (selectedOption === 'following') {
        eventData.actionType = 'following'
      } else if (selectedOption === 'all') {
        eventData.actionType = 'all'
      }

      await deleteEventSeries({eventId: selectedEvent.parentEventId, eventData})
      setShowDeleteSeriesModal(false)
    } catch (error) {
      console.log('An error occurred', error)
    } finally {
      handleEventDetailModalClose()
    }
  }

  const toggleExpand = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded)
  }

  const showFullDescription = selectedEvent?.description?.length <= 180 || isDescriptionExpanded

  return (
    <div>
      <Modal show={showEventDetailModal} onHide={handleEventDetailModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='bg-light w-100 d-flex justify-content-center align-items-center h200'>
            <i style={{fontSize: '40px'}} className='fa-solid fa-calendar'></i>
          </div>
          <div className='d-flex flex-wrap justify-content-between align-items-center'>
            <h2 className='mb-3 mt-5'>{selectedEvent?.originalTitle}</h2>
            <div>
              {selectedCommunity?.isCommunityAdmin && (
                <React.Fragment>
                  <Button
                    variant='danger'
                    className='mx-1 px-4'
                    size='sm'
                    onClick={async () => {
                      if (selectedEvent && !selectedEvent?.parentEventId) {
                        await deleteSingleEvent(selectedEvent.id)
                        handleEventDetailModalClose()
                      } else {
                        setShowEventDetailModal(false)
                        setShowDeleteSeriesModal(true)
                      }
                    }}
                  >
                    <i className='fa fa-trash'></i>
                  </Button>
                  <Button
                    variant='primary'
                    className='mx-1 px-4'
                    size='sm'
                    onClick={() => {
                      selectedEvent?.parentEventId
                        ? setShowEditSeriesModal(true)
                        : showEditEventModal()
                      setShowEventDetailModal(false)
                    }}
                  >
                    <i className='fa fa-pencil'></i>
                  </Button>
                </React.Fragment>
              )}
            </div>
          </div>
          <p className='fs-5 mt-3'>
            <i className='fa-solid fa-calendar fs-5 mx-1'></i> {formattedDate} ({getUserTimezone()})
          </p>
          <div className='d-flex mb-3'>
            {(selectedEvent?.location?.type === 'zoom' ||
              selectedEvent?.location?.type === 'google') && (
              <img
                src={`${
                  selectedEvent?.location?.type === 'zoom'
                    ? '/media/icons/zoom.png'
                    : '/media/icons/meet.png'
                }`}
                className='h20 w20 mx-1'
                alt='img'
              />
            )}
            {selectedEvent?.location?.type === 'link' && <i className='bi bi-link fs-2 mx-1'></i>}
            {selectedEvent?.location?.type === 'address' && (
              <i className='fa-solid fa-location-dot fs-2 mx-1'></i>
            )}
            {selectedEvent?.location?.type !== 'address' && (
              <a
                href={selectedEvent?.location?.value}
                target='_blank'
                className='fs-6 mx-1 comments-link'
                rel='noreferrer'
              >
                {selectedEvent?.location?.value}
              </a>
            )}
            {selectedEvent?.location?.type === 'address' && (
              <p className='fs-6 mx-1'>{selectedEvent?.location?.type}</p>
            )}
          </div>
          <p className='fs-5 m-0'>
            {showFullDescription
              ? selectedEvent?.description
              : selectedEvent?.description?.slice(0, 180) + '...'}
          </p>
          {selectedEvent?.description?.length >= 180 && (
            <button
              className='text-primary bg-transparent border-0 my-1 p-0'
              onClick={toggleExpand}
            >
              {showFullDescription ? 'Read less' : 'Read more'}
            </button>
          )}

          <div>
            <p className='fs-5 fw-bold mt-3'>Add to Calendar</p>
            <AddToCalendarButton
              name={selectedEvent?.originalTitle || selectedEvent?.title}
              description={selectedEvent?.description}
              options={['Google', 'Outlook.com', 'Apple', 'Yahoo']}
              startDate={moment(selectedEvent?.startDate).format('YYYY-MM-DD')}
              endDate={moment(selectedEvent?.endDate).format('YYYY-MM-DD')}
              startTime={moment(selectedEvent?.startDate).format('HH:mm:ss')}
              endTime={moment(selectedEvent?.endDate).format('HH:mm:ss')}
              timeZone={getUserTimezone()}
              location={selectedEvent?.location?.value}
              buttonsList
              hideTextLabelButton
              buttonStyle='round'
            ></AddToCalendarButton>
          </div>
        </Modal.Body>
      </Modal>

      {showEditSeriesModal && (
        <RecUpdateOptions
          title='Edit Event'
          selectedOption={selectedOption}
          showRecOptionsModal={showEditSeriesModal}
          setShowRecOptionsModal={setShowEditSeriesModal}
          setSelectedOption={setSelectedOption}
          onSubmit={() => {
            setShowEditSeriesModal(false)
            showEditEventModal()
          }}
        />
      )}

      {showDeleteSeriesModal && (
        <RecUpdateOptions
          title='Delete Event'
          selectedOption={selectedOption}
          showRecOptionsModal={showDeleteSeriesModal}
          setShowRecOptionsModal={setShowDeleteSeriesModal}
          setSelectedOption={setSelectedOption}
          onSubmit={deleteEvent}
        />
      )}
    </div>
  )
}

export default EventDetailModal
