import React from 'react'

interface ImageProps {
  src: string
  alt?: string
  className?: string
}

const Image: React.FC<ImageProps> = ({src, alt = 'img', className = 'img-round'}) => {
  return (
    <img
      src={src}
      className={className}
      alt={alt}
      onError={(e: any) => {
        e.target.src = '/media/avatars/blank.png'
      }}
    />
  )
}

export default Image
