import {FC} from 'react'
import {Button, Modal} from 'react-bootstrap'

interface RecUpdateOptionsProps {
  title: string
  selectedOption: string
  showRecOptionsModal: boolean
  setShowRecOptionsModal: (show: boolean) => void
  setSelectedOption: (option: string) => void
  onSubmit: () => void
}

const RecUpdateOptions: FC<RecUpdateOptionsProps> = ({
  title,
  selectedOption,
  showRecOptionsModal,
  setShowRecOptionsModal,
  setSelectedOption,
  onSubmit,
}) => {
  return (
    <Modal show={showRecOptionsModal} onHide={() => setShowRecOptionsModal(false)} centered>
      <Modal.Header closeButton className='py-4'>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='form-check mb-3'>
          <input
            className='form-check-input'
            type='radio'
            name='updateOption'
            id='updateSingle'
            value=''
            checked={selectedOption === ''}
            onChange={() => setSelectedOption('')}
          />
          <label className='form-check-label text-black' htmlFor='updateSingle'>
            This event
          </label>
        </div>
        <div className='form-check mb-3'>
          <input
            className='form-check-input'
            type='radio'
            name='updateOption'
            id='updateFollowing'
            value='following'
            onChange={() => setSelectedOption('following')}
          />
          <label className='form-check-label text-black' htmlFor='updateFollowing'>
            This and following events
          </label>
        </div>
        <div className='form-check mb-3'>
          <input
            className='form-check-input'
            type='radio'
            name='updateOption'
            id='updateAll'
            value='all'
            onChange={() => setSelectedOption('all')}
          />
          <label className='form-check-label text-black' htmlFor='updateAll'>
            All events
          </label>
        </div>
        <div className='d-end'>
          <Button
            variant='secondary'
            className='mx-1'
            onClick={() => setShowRecOptionsModal(false)}
          >
            Cancel
          </Button>
          <Button variant='primary' className='mx-1' onClick={onSubmit}>
            Submit
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default RecUpdateOptions
