import {useState, useRef, useEffect} from 'react'
import EmojiPicker from 'emoji-picker-react'
import {Button} from 'react-bootstrap'

import {useAuth} from '../../../modules/auth'
import InsertLinkModal from '../../../modules/common/InsertLinkModal'
import Attachments from './FeedAttachments'
import FileUploader from '../../../modules/common/FileUploader'
import {useCreateFeedComment, useUpdateFeedComment} from '../../../queries/feedComments'
import Image from '../../../modules/common/Image'

interface FeedCommentsFormProps {
  feedId: number
  commentsLength?: number
  parentComment?: any
  toggleReplyField?: any
  editedComment?: any
  setEditingIndex?: any
}

const FeedCommentsForm: React.FC<FeedCommentsFormProps> = ({
  feedId,
  commentsLength = 0,
  parentComment,
  toggleReplyField,
  editedComment,
  setEditingIndex,
}) => {
  const createCommentMutation = useCreateFeedComment()
  const updateCommentMutation = useUpdateFeedComment()
  const [loading, setLoading] = useState(false)
  const [newComment, setNewComment] = useState(editedComment?.comment || '')
  const [isUploading, setIsUploading] = useState(false)
  const [showLinkModal, setShowLinkModal] = useState(false)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [commentAttachments, setCommentAttachments] = useState<any>(
    editedComment?._attachments || []
  )
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const emojiPickerRef = useRef<HTMLDivElement | null>(null)
  const {currentUser} = useAuth()

  const handleLinkClose = () => setShowLinkModal(false)
  const handleVideoClose = () => setShowVideoModal(false)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target as Node)) {
        setShowEmojiPicker(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const saveComment = async () => {
    try {
      setLoading(true)
      if (editedComment) {
        await updateCommentMutation.mutateAsync({
          feedId,
          commentId: editedComment.id,
          commentData: {
            attachments: commentAttachments,
            comment: newComment.trim(),
          },
        })
        setEditingIndex(null)
      } else {
        if (newComment.trim() !== '') {
          await createCommentMutation.mutateAsync({
            feedId,
            commentData: {
              comment: newComment,
              attachments: commentAttachments,
              parentCommentId: parentComment?.id,
            },
          })
        }
        setNewComment('')
        setCommentAttachments([])
      }
    } catch {
    } finally {
      setLoading(false)
      toggleReplyField()
    }
  }

  const onFileUpload = (attachments: any) => {
    setCommentAttachments([...commentAttachments, attachments])
  }

  const handleLinkInsert = (link: string) => {
    setNewComment((prevComment: any) => prevComment + ' ' + link)
  }

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState: any) => !prevState)
  }

  const onEmojiClick = (emojiObject: any) => {
    setNewComment((prevInput: any) => prevInput + emojiObject.emoji)
  }

  const onVideoInsert = (url: string) => {
    onFileUpload({
      fileType: 'media',
      originalFileName: url,
      signedFileName: url,
    })
  }

  const handleDeleteFile = (index: number) => {
    const updatedAttachments = [...commentAttachments]
    updatedAttachments.splice(index, 1)
    setCommentAttachments(updatedAttachments)
  }

  return (
    <div className='w-100'>
      <div
        style={{
          marginTop: commentsLength > 0 ? '20px' : '10px',
          marginLeft: parentComment?.id ? '60px' : '0px',
        }}
      >
        <div className='d-flex'>
          <Image src={`${currentUser?.absoluteProfilePath}`} />
          <div className='bg-light comments-card rounded mx-2 py-3'>
            <div className='px-3 rounded d-flex justify-content-between align-items-center'>
              <textarea
                name='myTextArea'
                rows={1}
                className='outline-none bg-transparent border-0 w-75 resize-none feed-comment'
                value={newComment}
                placeholder={`${parentComment?.id ? 'Reply...' : 'Write a comment...'}`}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault()
                    saveComment()
                  }
                }}
                onChange={(e) => setNewComment(e.target.value)}
              ></textarea>
              <div className='feed-comment-icons'>
                <FileUploader
                  resourceType='comments'
                  setIsUploading={setIsUploading}
                  isUploading={isUploading}
                  onFileUpload={onFileUpload}
                />
                <i onClick={toggleEmojiPicker} className='bi bi-emoji-smile fs-2 custom-icon'></i>
                <i
                  onClick={() => setShowLinkModal(true)}
                  className='bi bi-link fs-2 custom-icon'
                ></i>
                <i
                  onClick={() => setShowVideoModal(true)}
                  className='bi bi-youtube fs-2 custom-icon'
                ></i>
                {showLinkModal && (
                  <InsertLinkModal
                    handleClose={handleLinkClose}
                    showModal={showLinkModal}
                    onLinkInsert={handleLinkInsert}
                  />
                )}
                {showVideoModal && (
                  <InsertLinkModal
                    handleClose={handleVideoClose}
                    showModal={showVideoModal}
                    onLinkInsert={onVideoInsert}
                    type='video'
                  />
                )}
                {showEmojiPicker && (
                  <div
                    ref={emojiPickerRef}
                    className='position-absolute z-3'
                    style={{bottom: '150px', right: '27px'}}
                  >
                    <EmojiPicker skinTonesDisabled={true} onEmojiClick={onEmojiClick} />
                  </div>
                )}
              </div>
            </div>
            {commentAttachments && (
              <div className='d-flex align-items-center overflow-x-scroll'>
                <Attachments
                  isUploading={isUploading}
                  attachments={commentAttachments}
                  handleDeleteFile={handleDeleteFile}
                />
              </div>
            )}
          </div>
        </div>
        {parentComment?.id && (
          <div className='d-flex w-100 my-3 justify-content-end transition'>
            <Button
              variant='secondary'
              className='mx-1 feed-comment-button'
              onClick={toggleReplyField}
            >
              Cancel
            </Button>
            <button
              type='submit'
              className='btn btn-secondary mx-3 feed-comment-button'
              onClick={saveComment}
              disabled={loading || newComment.trim() === ''}
            >
              {!loading && 'Reply'}
              {loading && (
                <span className='indicator-progress d-block'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        )}
        {!parentComment?.id && (
          <div className='d-flex w-100 my-3 justify-content-end transition'>
            {editedComment && (
              <Button
                variant='secondary'
                className='feed-comment-button'
                onClick={() => setEditingIndex(null)}
              >
                Cancel
              </Button>
            )}
            <button
              type='submit'
              className='btn btn-primary mx-1 feed-comment-button'
              onClick={saveComment}
              disabled={loading}
            >
              {!loading && 'Post'}
              {loading && (
                <span className='indicator-progress d-block'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default FeedCommentsForm
