import {Form} from 'react-bootstrap'

function SelectContentType({
  label,
  value,
  handleOnChange,
}: {
  label: string
  value: string
  handleOnChange: (e: any) => void
}) {
  return (
    <div className={`w-100 mb-7`}>
      <p className='fs-5 fw-bold'>{label}</p>
      <Form.Select onChange={handleOnChange} value={value}>
        <option value={''}>All Members</option>
        {/* Todo: add dynamic value of premium plan */}
        <option value={'2'}>Members with Premium Plan</option>
      </Form.Select>
    </div>
  )
}

export default SelectContentType
