import {useQuery} from '@tanstack/react-query'

import {fetchUnreadMessages} from '../apis/messages'
import {MESSAGES_QUERY_KEY} from '../constants/reactQueryKeys'

const useMessages = () => {
  const unreadMesagesResult = useQuery({
    queryKey: [MESSAGES_QUERY_KEY],
    queryFn: () => fetchUnreadMessages({page: 1, limit: 100}),
  })
  return {unreadMesagesResult}
}

export default useMessages
