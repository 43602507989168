import apiClient from './apiClient'
import {getSelectedCommunityId} from '../modules/common/utils'

interface GetApiParams {
  page: number
  limit?: number
  search?: string
  startDate?: string
  endDate?: string
}

export const fetchEvents = async (params: GetApiParams): Promise<any> => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/events`, {
    params,
  })
  return response.data?.data || []
}

export const postEvent = async (data: any) => {
  const response = await apiClient.post(`/c/${getSelectedCommunityId()}/events`, data)
  return response.data?.data || {}
}

export const deleteEvent = async (eventId: number) => {
  const response = await apiClient.delete(`/c/${getSelectedCommunityId()}/events/${eventId}`)
  return response.data?.data || {}
}

export const deleteEventSeries = async (eventId: number, eventData: any) => {
  const response = await apiClient.delete(
    `/c/${getSelectedCommunityId()}/events/series/${eventId}`,
    {data: eventData}
  )
  return response.data?.data || {}
}

export const putEvent = async (eventId: number, data: any) => {
  const response = await apiClient.put(`/c/${getSelectedCommunityId()}/events/${eventId}`, data)
  return response.data?.data || {}
}

export const putEventSeries = async (eventId: number, data: any) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/events/series/${eventId}`,
    data
  )
  return response.data?.data || {}
}
