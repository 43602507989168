import {useRef, useState} from 'react'
import {Button, Dropdown, Form, Modal} from 'react-bootstrap'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {Editor} from '@tinymce/tinymce-react'
import {useNavigate} from 'react-router-dom'

import InsertLinkModal from '../../../modules/common/InsertLinkModal'
import {useCreateCourseModule, useUpdateCourseModule} from '../../../queries/courses'
import ResourceForm from './ResourceForm'
import {ResourceItem} from './ResourceItem'

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .max(50, 'Course title cannot exceed 50 characters'),
  status: Yup.string(),
  videoUrl: Yup.string().optional(),
  description: Yup.string().optional(),
  transcript: Yup.string().optional(),
})

interface CoursesModuleFormProps {
  show: boolean
  handleClose: any
  course: any
  sectionId: string | number
  courseModule?: {
    id: number | string
    title: string
    description: string
    videoUrl: string
    transcript: string
    status: string
    courseSectionModuleResources: any[]
  }
}

const CoursesModuleForm: React.FC<CoursesModuleFormProps> = ({
  course,
  sectionId,
  show,
  handleClose,
  courseModule = {
    id: '',
    title: 'New Module',
    description: '',
    status: 'published',
    videoUrl: '',
    transcript: '',
    courseSectionModuleResources: [],
  },
}) => {
  const editorRef = useRef<any>(null)
  const navigate = useNavigate()
  const {mutateAsync: createCourseModule} = useCreateCourseModule()
  const {mutateAsync: updateCourseModule} = useUpdateCourseModule()
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [showTranscript, setShowTranscript] = useState(!!courseModule.transcript)
  const [showResourceFormModal, setShowResourceFormModal] = useState(false)
  const [resources, setResources] = useState<any>(courseModule.courseSectionModuleResources)
  const [seclectedResource, setSelectedResource] = useState<any>({})

  const handleAddVideo = () => {
    setShowVideoModal(true)
  }

  const handleAddResource = (newResource: any) => {
    setResources([...resources, newResource])
  }

  const handleEditResource = (resource: any) => {
    setSelectedResource(resource)
    setShowResourceFormModal(true)
  }

  const handleDeleteResource = (resource: any) => {
    const newResources = resources.filter((res: any) => res.id !== resource.id)
    setResources(newResources)
  }

  const handleUpdateResource = (resource: any) => {
    const updatedResources = resources.map((res: any) => {
      if (res.id === resource.id) {
        return resource
      }
      return res
    })
    setResources(updatedResources)
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        aria-labelledby='example-modal-sizes-title-lg'
        size='lg'
        backdrop='static'
      >
        <Modal.Body>
          <Formik
            initialValues={{
              title: courseModule.title,
              status: courseModule.status,
              description: courseModule.description,
              videoUrl: courseModule.videoUrl,
              transcript: courseModule.transcript,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              try {
                const _resources = resources.map((res: any) => {
                  return {
                    resourceLabel: res.resourceLabel,
                    attachment: res.attachment,
                  }
                })
                if (courseModule.id) {
                  await updateCourseModule({
                    courseId: course?.uuid,
                    moduleId: courseModule.id,
                    moduleData: {...values, sectionId, resources: _resources},
                  })
                } else {
                  const module = await createCourseModule({
                    courseId: course?.uuid,
                    moduleData: {...values, sectionId, resources: _resources},
                  })
                  navigate(`?sectionId=${module.sectionId}&moduleId=${module.id}`)
                }
                resetForm()
                handleClose()
              } catch {
              } finally {
                setSubmitting(false)
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId='title'>
                  <Form.Label className='fw-bold'>Module Title:</Form.Label>
                  <Form.Control
                    className='form-control'
                    type='text'
                    placeholder='New Module'
                    name='title'
                    value={values.title}
                    onChange={handleChange}
                    isInvalid={touched.title && !!errors.title}
                    autoFocus={true}
                  />
                  <div className='d-flex align-items-center justify-content-between'>
                    <div>
                      {errors.title && touched.title && (
                        <div className='text-danger'>{errors.title}</div>
                      )}
                    </div>
                    <div>
                      <p className='text-muted mt-3'>
                        {values.title.length} / {50}
                      </p>
                    </div>
                  </div>
                </Form.Group>
                {values.videoUrl ? (
                  <div className='position-relative w-100' style={{maxHeight: '450px'}}>
                    <div className='position-absolute top-0 left-0 w-100 h-100 z-1 pointer-event bg-black-50 opacity-8 rounded'>
                      <i
                        className='position-absolute bi p-3 fs-2 attachment-icon rounded-circle cursor-pointer bi-x'
                        style={{top: '10px', right: '10px', zIndex: '2'}}
                        onClick={() => setFieldValue('videoUrl', '')}
                      ></i>
                    </div>
                    <iframe
                      src={values.videoUrl}
                      title={'Module Video'}
                      style={{height: '450px'}}
                      allowFullScreen
                      className='w-100'
                    ></iframe>
                  </div>
                ) : (
                  <button
                    className='btn btn-light text-gray Video-button mt-5'
                    onClick={handleAddVideo}
                    type='button'
                  >
                    Add Video
                  </button>
                )}
                <div className='mt-4'>
                  <Form.Label className='fw-bold py-3' htmlFor='description'>
                    Description:
                  </Form.Label>
                  <Editor
                    onInit={(_evt, editor) => (editorRef.current = editor)}
                    tinymceScriptSrc={'/tinymce/tinymce.js'}
                    initialValue={courseModule.description}
                    value={values.description}
                    id={'description'}
                    init={{
                      menubar: false,
                      statusbar: false,
                      branding: false,
                      min_height: 50,
                      height: 50,
                      plugins: [
                        'advlist',
                        'autolink',
                        'lists',
                        'link',
                        'image',
                        'charmap',
                        'preview',
                        'anchor',
                        'searchreplace',
                        'visualblocks',
                        'code',
                        'fullscreen',
                        'insertdatetime',
                        'media',
                        'table',
                        'code',
                        'help',
                        'wordcount',
                        'autoresize',
                        'emoticons',
                      ],
                      toolbar:
                        'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat',
                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                    onEditorChange={(e) => {
                      handleChange({target: {name: 'description', value: e}})
                    }}
                  />
                </div>
                <div className='mt-13'>
                  {resources?.length > 0 && (
                    <div>
                      <p className='fw-bold fs-5'>Resources</p>
                      {resources.map((resource: any) => (
                        <ResourceItem
                          key={resource.id}
                          resource={resource}
                          handleDeleteResource={handleDeleteResource}
                          handleEditResource={handleEditResource}
                        />
                      ))}
                    </div>
                  )}
                </div>
                {showTranscript && (
                  <div className='mt-4'>
                    <Form.Label className='fw-bold' htmlFor='transcript'>
                      Transcript:
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      className='form-control'
                      placeholder='Transcript'
                      name='transcript'
                      value={values.transcript}
                      onChange={handleChange}
                      rows={8}
                    />
                  </div>
                )}
                <div className='d-flex justify-content-between align-items-center mt-9'>
                  <div className='d-flex align-items-center'>
                    <Dropdown>
                      <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                        Add
                      </Dropdown.Toggle>

                      <Dropdown.Menu className='py-6 shadow-lg'>
                        <Dropdown.Item
                          className='text-black fw-bold my-3'
                          onClick={() => setShowResourceFormModal(true)}
                        >
                          Add Resource
                        </Dropdown.Item>

                        <Dropdown.Item
                          className='text-black fw-bold my-3'
                          onClick={() => setShowTranscript(!showTranscript)}
                        >
                          {showTranscript ? 'Remove Transcript' : 'Add Transcript'}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className={`form-check form-switch align-items-center mx-3`}>
                      <input
                        className='form-check-input cursor-pointer'
                        type='checkbox'
                        id='flexSwitchCheckDefault'
                        name='isActive'
                        checked={values.status === 'published'}
                        onChange={() => {
                          const newStatus = values.status === 'published' ? 'draft' : 'published'
                          setFieldValue('status', newStatus)
                        }}
                      />
                      <span className='fs-5 fw-bold'>
                        {values.status === 'published' ? 'Publish' : 'Draft'}
                      </span>
                    </div>
                  </div>
                  <div>
                    <Button variant='secondary mx-3' type='button' onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button variant='primary' type='submit' disabled={isSubmitting}>
                      Submit
                    </Button>
                  </div>
                </div>
                <InsertLinkModal
                  handleClose={() => setShowVideoModal(false)}
                  showModal={showVideoModal}
                  onLinkInsert={(link: string) => setFieldValue('videoUrl', link)}
                  type='video'
                />
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {showResourceFormModal && (
        <ResourceForm
          showModal={showResourceFormModal}
          onHideModal={() => {
            setSelectedResource({})
            setShowResourceFormModal(false)
          }}
          handleAddResource={handleAddResource}
          handleUpdateResource={handleUpdateResource}
          resource={seclectedResource}
        />
      )}
    </>
  )
}

export default CoursesModuleForm
