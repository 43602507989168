import {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Formik, Form, Field, FormikHelpers} from 'formik'
import * as Yup from 'yup'

interface InsertLinkModalProps {
  showModal: boolean
  handleClose: () => void
  onLinkInsert: (url: string) => void
  type?: 'link' | 'video'
}

const typeMap: {[key: string]: {modalTitle: string; fieldLabel: string; placeholder: string}} = {
  link: {
    modalTitle: 'Add Link',
    fieldLabel: 'Add Link',
    placeholder: 'Enter a URL',
  },
  video: {
    modalTitle: 'Add Video',
    fieldLabel: 'Add a YouTube, Vimeo, Loom, or Wistia link.',
    placeholder: 'Add Video Link here',
  },
}

const validationSchema = Yup.object({
  link: Yup.string().required('Link must be a valid URL').url('Link must be a valid URL'),
})

const initialValue = {
  link: '',
}

const InsertLinkModal: React.FC<InsertLinkModalProps> = ({
  showModal,
  handleClose,
  onLinkInsert,
  type = 'link',
}) => {
  const [errorMessage, setErrorMessage] = useState('')

  const onSubmitForm = (inputUrl: string) => {
    if (type === 'link') {
      onLinkInsert(inputUrl)
      return handleClose()
    }

    let videoLink = ''

    // YouTube
    const youTubeRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})([?&].*)?$/
    if (youTubeRegex.test(inputUrl)) {
      const match = inputUrl.match(youTubeRegex)
      if (match) {
        const videoId = match[4]
        if (videoId) {
          videoLink = `https://www.youtube.com/embed/${videoId}`
        }
      }
    }

    // Vimeo
    const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/
    if (vimeoRegex.test(inputUrl)) {
      const match = inputUrl.match(vimeoRegex)
      if (match) {
        const videoId = match[4]
        if (videoId) {
          videoLink = `https://player.vimeo.com/video/${videoId}`
        }
      }
    }

    // Wistia
    const wistiaRegex =
      /^(https?:\/\/)?(www\.)?(?:fast\.wistia\.com\/embed\/iframe\/|[^.]+\.wistia\.com\/medias\/)([a-zA-Z0-9]+)$/
    if (wistiaRegex.test(inputUrl)) {
      const match = inputUrl.match(wistiaRegex)
      if (match) {
        const videoId = match[3]
        if (videoId) {
          videoLink = `https://fast.wistia.com/embed/iframe/${videoId}`
        }
      }
    }

    // Loom
    const loomRegex = /^(https?:\/\/)?(www\.)?(loom\.com\/share\/)([a-zA-Z0-9_-]{32})$/
    if (loomRegex.test(inputUrl)) {
      const match = inputUrl.match(loomRegex)
      if (match) {
        const videoId = match[4]
        if (videoId) {
          videoLink = `https://www.loom.com/embed/${videoId}`
        }
      }
    }

    if (videoLink) {
      onLinkInsert(videoLink)
      return handleClose()
    }

    // If not a recognized format or video ID not found, return null
    return setErrorMessage('Invalid Video Link')
  }

  return (
    <div>
      <Modal
        show={showModal}
        onHide={handleClose}
        className='link-modal'
        centered
        style={{backgroundColor: 'rgba(0, 0, 0, 0.445)'}}
      >
        <Modal.Header className='px-4 py-4 border-bottom-0'>
          <Modal.Title>{typeMap[type].modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='py-3'>
          <Formik
            initialValues={initialValue}
            validationSchema={validationSchema}
            onSubmit={(values: {link: string}, {setSubmitting}: FormikHelpers<{link: string}>) => {
              onSubmitForm(values.link)
              setSubmitting(false)
            }}
          >
            {({errors, touched, values}) => (
              <Form>
                <label className='fs-5' htmlFor='link'>
                  {typeMap[type].fieldLabel}
                </label>
                <div>
                  <Field
                    className='form-control my-5'
                    name='link'
                    placeholder={typeMap[type].placeholder}
                  />
                  {errors.link && touched.link && <div className='text-danger'>{errors.link}</div>}
                  {errorMessage && <div className='text-danger'>{errorMessage}</div>}
                </div>
                <div className='d-flex justify-content-end mt-3'>
                  <button
                    disabled={values.link === ''}
                    type='submit'
                    className='my-2 btn btn-primary'
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default InsertLinkModal
