import {useState} from 'react'
import CustomDropDown from '../../../modules/common/CustomDropDown'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import {useAuth} from '../../../modules/auth'

export const ResourceItem = ({
  resource,
  handleDeleteResource,
  handleEditResource,
  canEdit = true,
}: {
  resource: any
  handleEditResource: (resource: any) => void
  handleDeleteResource: (resource: any) => void
  canEdit?: boolean
}) => {
  const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL
  const [showDeleteResourceModal, setShowDeleteResourceModal] = useState(0)
  const {selectedCommunity} = useAuth()

  const resourceItemOptions = [
    {
      label: 'Edit',
      handler: () => handleEditResource(resource),
    },
    {
      label: 'Delete',
      handler: () => setShowDeleteResourceModal(resource.id),
    },
  ]

  return (
    <div className='d-flex module-form mt-5 align-items-center'>
      <div>
        {resource.attachment?.fileType !== 'url' && (
          <i className='fa-solid bg-primary text-white p-2 rounded fs-6 cursor-pointer fa-file'></i>
        )}
        {resource.attachment?.fileType === 'url' && (
          <i className='fa-solid bg-primary text-white p-2 cursor-pointer rounded fs-8 fa-link'></i>
        )}
        <span className='mx-3 fs-5'>
          <a
            href={
              resource.attachment?.fileType === 'url'
                ? resource.attachment?.signedFileName
                : s3BaseUrl + resource.attachment?.signedFileName
            }
            target='_blank'
            rel='noreferrer'
            className='text-decoration-none text-primary  hover:text-decoration-underline'
          >
            {resource.resourceLabel}
          </a>
        </span>
      </div>
      {selectedCommunity?.permissions.canCreateEditCourse && canEdit && (
        <CustomDropDown
          className='mx-3'
          elementId='section-dropdown'
          options={resourceItemOptions}
        />
      )}
      {showDeleteResourceModal === resource?.id && (
        <ConfirmationModal
          showModal={showDeleteResourceModal === resource?.id}
          onHideModal={() => setShowDeleteResourceModal(0)}
          onCancel={() => setShowDeleteResourceModal(0)}
          title='Delete Resource'
          subtitle='Are you sure you want to delete the resource? You can`t undo this'
          onConfirm={() => handleDeleteResource(resource)}
          disableBtns={false}
        />
      )}
    </div>
  )
}
