import React, {useState} from 'react'
import {Row, Col} from 'react-bootstrap'

import CoursesForm from './components/CoursesForm'
import CourseItem from './components/CourseItem'
import {useGetCourses, useUpdateCourseOrder} from '../../queries/courses'
import {useAuth} from '../../modules/auth'

function CoursesIndex() {
  const {selectedCommunity} = useAuth()
  const [showForm, setShowForm] = useState(false)
  const updateCourseMutation = useUpdateCourseOrder()
  const coursesResult = useGetCourses()

  const handleShowForm = () => {
    setShowForm(true)
  }

  const courses = coursesResult.data?.courses || []

  return (
    <div style={{position: 'relative', padding: '1.25rem'}}>
      {updateCourseMutation.isPending && (
        <div
          className='position-absolute w-100 h-100'
          style={{
            top: 0,
            left: 0,
            zIndex: 999,
            backgroundColor: 'rgba(233, 233, 233, 0.156)',
          }}
        />
      )}
      <Row style={{opacity: updateCourseMutation.isPending ? '0.6' : '1'}}>
        {courses?.map((course: any, index: number) => (
          <React.Fragment key={course.id}>
            <CourseItem
              course={course}
              totalCourses={courses.length}
              updateCourseOrder={updateCourseMutation.mutateAsync}
            />
          </React.Fragment>
        ))}
        {selectedCommunity?.permissions.canCreateEditCourse && (
          <Col md={4} className='mb-5'>
            <button className='Course-button rounded' onClick={handleShowForm}>
              <i className='fa-solid fs-4 fa-plus'></i>
              <span className='mx-3 fs-4'>New Course</span>
            </button>
          </Col>
        )}
      </Row>
      <CoursesForm show={showForm} handleClose={() => setShowForm(false)} />
    </div>
  )
}

export default CoursesIndex
