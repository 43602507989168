import {Link} from 'react-router-dom'
import {useGetCommunities} from '../../queries/communitySettings'
import {useAuth} from '../auth'

const UserCommunityCard = () => {
  const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL
  const {data: selectedCommunityDetails} = useGetCommunities()
  const {selectedCommunity} = useAuth()
  return (
    <div
      className={`w-100 rounded border border-secondary rounded`}
      style={{backgroundColor: 'rgb(239,246,255)'}}
    >
      <div className='w-100'>
        <img
          className='w-100 h-50 rounded-top'
          alt=''
          src={`${
            selectedCommunityDetails?.community?.coverPic
              ? `${s3BaseUrl}${selectedCommunityDetails?.community?.coverPic}`
              : '/media/misc/menu-header-bg.jpg'
          }`}
        />
      </div>
      <div className='container mt-7'>
        <Link to={'/feeds/public'} className='fw-bold fs-3 text-primary'>
          {selectedCommunityDetails?.community?.name}
        </Link>
        <div>
          <p className='text-black mt-6 fs-5'>{selectedCommunityDetails?.community?.description}</p>
        </div>
        <div className='d-flex flex-row border-top border-secondary justify-content-between text-center align-items-center py-5 border-bottom border-secondary'>
          <div className='px-9 border-end border-secondary'>
            <h2>{selectedCommunityDetails?.membersCount}</h2>
            Members
          </div>
          <div className='px-8'>
            <h2>{selectedCommunityDetails?.adminsCount}</h2>
            Admin
          </div>
        </div>
        <div>
          {selectedCommunity?.isCommunityAdmin && (
            <Link to={'/community-settings'} className='btn btn-primary w-100 mt-7 mb-8'>
              SETTINGS
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserCommunityCard
