import {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'

import UserMessages from './components/UserMessages'
import {useFetchChat, useSendMessage} from '../../queries/messages'
import MessagesInput from './components/MessagesInput'
import {useAuth} from '../../modules/auth'

import './Messages.css'

const MessagesIndex = () => {
  const routeParams = useParams()
  const {currentUser} = useAuth()
  const [userMessages, setUserMessages] = useState<any>([])
  const {id} = routeParams
  const messagesResult = useFetchChat(id)
  const recipientUser = messagesResult.data?.recipientUser || {}
  const messageContainer: any = useRef(null)

  const [pendingMessage, setPendingMessage] = useState<any>(null)
  const {mutateAsync: postMessage, isPending} = useSendMessage()

  useEffect(() => {
    setUserMessages(messagesResult.data?.messages)
  }, [messagesResult.data?.messages])

  const onSendMessage = async ({message}: {message: string}) => {
    try {
      const newPendingMessage = {
        message,
        sender: currentUser,
        recipientUser,
        recipientId: recipientUser.id,
        userId: currentUser?.id,
      }
      setPendingMessage(newPendingMessage)
      await postMessage({recipientUserId: recipientUser.id, messageData: {message}})
      setUserMessages([...userMessages, newPendingMessage])
    } catch {
    } finally {
      setPendingMessage(null)
    }
  }

  return (
    <div className='card mx-5 px-4'>
      <UserMessages
        userMessages={userMessages}
        recipientUser={recipientUser}
        isFetching={messagesResult.isFetching}
        isRefetching={messagesResult.isRefetching}
        pendingMessage={pendingMessage}
        messageContainer={messageContainer}
      />

      <MessagesInput
        onSendMessage={onSendMessage}
        isPending={isPending}
        messageContainer={messageContainer}
      />
    </div>
  )
}

export {MessagesIndex}
