import Image from '../../../modules/common/Image'

const WelcomeScreen = ({currentUser, recipientUser}: {currentUser: any; recipientUser: any}) => {
  const userCommunity = currentUser?.userCommunities?.map((user: any) => user.name)
  return (
    <div className='d-flex flex-column justify-content-center align-items-center w-100 mt-9'>
      <div className='d-flex align-items-center my-3'>
        <Image src={`${currentUser?.absoluteProfilePath}`} />
        <i className='fa-solid fa-arrows-left-right fs-1 mx-2'></i>
        <Image src={`${recipientUser?.absoluteProfilePath}`} />
      </div>
      <div className='text-center d-flex justify-content-center flex-column align-items-center'>
        <h1 className='new-messages-heading'>
          You and {recipientUser.fullName} have been members of {userCommunity}.
        </h1>
        <h4>Say hi and connect now!</h4>
      </div>
    </div>
  )
}

export default WelcomeScreen
