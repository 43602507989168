import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {toast} from 'react-toastify'

import * as coursesApi from '../apis/courses'
import {COURSES_QUERY_KEY, COURSE_BY_ID_QUERY_KEY} from '../constants/reactQueryKeys'
import {parseError} from '../modules/common/utils'

export const useCreateCourse = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (courseData: any) => coursesApi.postCourse(courseData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COURSES_QUERY_KEY],
      })
      toast.success('Course created successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const useUpdateCourse = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({courseId, courseData}: any) => coursesApi.putCourse(courseId, courseData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COURSES_QUERY_KEY],
      })
      queryClient.invalidateQueries({
        queryKey: [COURSE_BY_ID_QUERY_KEY],
      })
      toast.success('Course updated successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const useUpdateCourseOrder = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({courseId, order}: {courseId: string; order: number}) =>
      coursesApi.putCourseOrder(courseId, order),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COURSES_QUERY_KEY],
      })
      queryClient.invalidateQueries({
        queryKey: [COURSE_BY_ID_QUERY_KEY],
      })
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const useDeleteCourse = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (courseId: number) => coursesApi.deleteCourse(courseId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COURSES_QUERY_KEY],
      })
      toast.success('Course deleted successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const useGetCourses = () => {
  return useQuery({
    queryKey: [COURSES_QUERY_KEY],
    queryFn: () => coursesApi.getCourses({page: 1, limit: 100}),
  })
}

export const useGetCourseById = (courseId: string) => {
  return useQuery({
    queryKey: [COURSE_BY_ID_QUERY_KEY, courseId],
    queryFn: () => coursesApi.getCourse(courseId),
    enabled: !!courseId,
  })
}

export const useCreateCourseSection = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({courseId, sectionData}: any) =>
      coursesApi.postCourseSection(courseId, sectionData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COURSE_BY_ID_QUERY_KEY],
      })
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const useUpdateCourseSection = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({courseId, sectionId, sectionData}: any) =>
      coursesApi.updateCourseSection(courseId, sectionId, sectionData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COURSE_BY_ID_QUERY_KEY],
      })
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const useUpdateCourseSectionOrder = () => {
  return useMutation({
    mutationFn: ({
      courseId,
      sectionId,
      order,
    }: {
      courseId: string
      sectionId: number
      order: number
    }) => coursesApi.updateCourseSectionOrder(courseId, sectionId, order),
    onError: (error: any) => {
      toast.error('An error occurred, while updating the order of section')
      return error
    },
  })
}

export const useDeleteCourseSection = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({courseId, sectionId}: any) => coursesApi.deleteCourseSection(courseId, sectionId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COURSE_BY_ID_QUERY_KEY],
      })
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const useCreateCourseModule = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({courseId, moduleData}: any) => coursesApi.postCourseModule(courseId, moduleData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COURSE_BY_ID_QUERY_KEY],
      })
      toast.success('Module added!')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const useUpdateCourseModule = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({courseId, moduleId, moduleData}: any) =>
      coursesApi.updateCourseModule(courseId, moduleId, moduleData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COURSE_BY_ID_QUERY_KEY],
      })
      toast.success('Module updated!')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const useUpdateCourseModuleOrder = () => {
  return useMutation({
    mutationFn: ({
      courseId,
      moduleId,
      order,
    }: {
      courseId: string
      moduleId: number
      order: number
    }) => coursesApi.updateCourseModuleOrder(courseId, moduleId, order),
    onError: (error: any) => {
      toast.error('An error occurred, while updating the order of module')
      return error
    },
  })
}

export const useUpdateModuleSection = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      courseId,
      moduleId,
      newSectionId,
    }: {
      courseId: string
      moduleId: number
      newSectionId: number
    }) => coursesApi.updateModuleSection(courseId, moduleId, newSectionId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COURSE_BY_ID_QUERY_KEY],
      })
      toast.success('Module section updated')
    },
    onError: (error: any) => {
      toast.error('An error occurred, while changing module section')
      return error
    },
  })
}

export const useDeleteCourseModule = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({courseId, moduleId}: any) => coursesApi.deleteCourseModule(courseId, moduleId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COURSE_BY_ID_QUERY_KEY],
      })
      toast.success('Module deleted!')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const useUpdateCourseModuleProgress = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({courseId, moduleId, progressData}: any) =>
      coursesApi.postCourseModuleProgress(courseId, moduleId, progressData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COURSE_BY_ID_QUERY_KEY],
      })
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}
