import {getSelectedCommunityId} from '../modules/common/utils'
import apiClient from './apiClient'

interface GetApiParams {
  page: number
  limit?: number
}

export const fetchUnreadMessages = async (params: GetApiParams): Promise<any> => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/messages/unread`, {params})
  return response.data?.data || []
}

export const fetchMessagesCount = async () => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/messages/unread-count`)
  return response.data?.data
}

export const fetchChat = async (
  id: string | undefined,
  params: {limit: number; page: number | string}
) => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/messages/${id}`, {params})
  return response.data?.data || {}
}

export const postMessage = async (recipientUserId: number, {message}: {message: string}) => {
  const response = await apiClient.post(
    `/c/${getSelectedCommunityId()}/messages/send/${recipientUserId}`,
    {
      message,
    }
  )
  return response.data?.data || {}
}
