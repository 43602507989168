import {useGetCommunitiesLeaderBoard} from '../../../queries/communitySettings'

function CommunityGamification() {
  const {data: LeaderBoardLevel} = useGetCommunitiesLeaderBoard()
  return (
    <div className='container mt-5 mb-6'>
      <div>
        <h2 className='fw-bold'>Gamification</h2>
        <p className='fs-5 mt-5'>
          Make your group fun! Personalize your group by naming your levels. Incentivize engagement
          by unlocking courses when members reach a higher level.
        </p>
      </div>
      {LeaderBoardLevel?.levelDistribution?.map((level: any, index: any) => (
        <div
          key={index}
          className='d-flex justify-content-between mt-8 align-items-center border rounded p-4 mb-3 bg-light shadow-sm w-100'
        >
          <div className='d-flex flex-column'>
            <h3 className='fw-bold mb-2'>{level.levelName}</h3>
            <div className='text-muted fs-6 fw-bold'>{level.percentage} % of members</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CommunityGamification
