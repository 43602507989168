import {useState} from 'react'

import {useAuth} from '../../modules/auth'
import ResourcesHeader from './components/ResourcesHeader'
import ResourcesList from './components/ResourcesList'

const ResourcesIndex = () => {
  const {selectedCommunity} = useAuth()
  const [showBlock, hideBlock] = useState(false)
  const changeDisplay = () => {
    hideBlock(!showBlock)
  }

  const canManageResources = selectedCommunity?.permissions.canManageResources || false

  return (
    <div className='card'>
      <ResourcesHeader
        changeDisplay={changeDisplay}
        showBlock={showBlock}
        canManageResources={canManageResources}
      />
      <ResourcesList showBlock={showBlock} />
    </div>
  )
}

export default ResourcesIndex
