import {useState, useEffect, useRef} from 'react'
import {useParams} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {Button} from 'react-bootstrap'
import {toast} from 'react-toastify'

import apiClient from '../../../apis/apiClient'
import FetchingSpinner from '../../../modules/common/FetchingSpinner'
import ViewProfileTabs from './ViewProfileTabs'
import {useAuth} from '../../../modules/auth'
import Image from '../../../modules/common/Image'
import LevelsInfo from '../../leaderBoard/Components/LevelsInfo'
import GenericModal from '../../../modules/common/Modal'
import './viewProfile.scss'

export function ViewProfile() {
  const {currentUser, selectedCommunity} = useAuth()
  const [user, setUser] = useState<any>()
  const [currentCommunity, setCurrentCommunity] = useState<any>({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showLevelDetailModal, setShowLevelDetailModal] = useState(false)
  const routeParams = useParams()
  const {id} = routeParams
  const [isFetching, setIsFetching] = useState(false)
  const textInput = useRef<any>(null)

  const saveProfileCover = async (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      return
    }

    const selectedFile = e.target.files[0]
    try {
      setIsSubmitting(true)
      const formData = new FormData()
      formData.append('file', selectedFile)
      const {data} = await apiClient.post(`/users/profile/${id}/profile-cover`, formData)
      const updatedUser = data?.data?.user
      setUser({...user, absoluteCoverPath: updatedUser.absoluteCoverPath})
      setIsFetching(false)
    } catch {
      toast.error('An error occurred while updating cover photo, please try again later', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    setIsFetching(true)
    apiClient.get(`/users/profile/${id}`).then((res) => {
      const {user} = res.data.data
      const currentCommunity =
        user?.userCommunities.find((c: any) => c.communityId === selectedCommunity?.id) ?? {}
      setUser(user)
      setCurrentCommunity(currentCommunity)
      setIsFetching(false)
    })
  }, [id])

  return (
    <div id='kt_profile_details_view'>
      {isFetching && <FetchingSpinner />}
      {!isFetching && (
        <div className='card mb-sm-10'>
          <div>
            <img
              className='w-100 profile-cover-pic'
              style={{maxHeight: '270px', objectFit: 'cover', objectPosition: 'center'}}
              src={`${
                user?.profileCover ? user?.absoluteCoverPath : '/media/misc/menu-header-bg.jpg'
              }`}
              alt=''
            />
            {currentUser?.id === user?.id && (
              <div className='cover-photo d-end p-0'>
                <button
                  className='btn btn-secondary mx-8'
                  onClick={() => textInput && textInput.current && textInput.current.click()}
                >
                  <i className='bi fs-4 p-0 bi-camera-fill'></i>
                  <span className='px-3 fs-12 mt-2 edit-profie-button'>
                    {isSubmitting ? 'Saving...' : 'Edit cover photo'}
                  </span>
                </button>
                <input
                  id='file-input'
                  type='file'
                  accept='image/*'
                  hidden={true}
                  onChange={saveProfileCover}
                  ref={textInput}
                />
              </div>
            )}
          </div>
          <div className='card-body border-card' style={{maxHeight: '185px'}}>
            <div className='d-flex flex-row'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <a href={user?.absoluteProfilePath} target='_blank' rel='noopener noreferrer'>
                  <Image
                    className='profile-image rounded-circle object-center object-cover'
                    src={user?.absoluteProfilePath}
                    alt='avatar'
                  />
                </a>
                <div>
                  <div className='position-relative profile-badge'>
                    <i className='bi bi-shield-fill text-primary' style={{fontSize: '40px'}}></i>
                    <h3 className='position-relative text-white number-level'>
                      {currentCommunity.currentLevel?.levelNumber}
                    </h3>
                  </div>
                  <div className='position-relative level-name'>
                    <div>
                      <Link to={'/leaderboard'} className='text-primary fw-bold'>
                        {currentCommunity.currentLevel?.name}
                      </Link>
                    </div>
                    <div style={{marginLeft: '-40px'}} className='d-flex flex-row'>
                      <p className='text-muted fw-bold'>
                        <strong className='text-primary'>
                          {currentCommunity.pointsRequiredForNextLevel}
                        </strong>{' '}
                        Points to level up
                      </p>
                      <Button
                        variant='transparent'
                        className='border-0 p-0'
                        style={{marginTop: '-12px'}}
                        onClick={() => setShowLevelDetailModal(true)}
                      >
                        <i className='fa-regular mx-2 fs-5 fa-circle-question'></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap view-profile-username'>
                  <div className='d-flex flex-column mx-4'>
                    <div className='d-flex align-items-center mb-2'>
                      <p className='text-gray-800 text-hover-primary fs-1 fw-bolder me-1'>
                        {user?.firstName} {user?.lastName}
                      </p>
                      <p>
                        <i className='bi bi-duotone bi-verify fs-1 text-primary'></i>
                      </p>
                    </div>
                    <div className='cursor-pointer d-flex flex-wrap fw-bold fs-6 pe-2'>
                      <p
                        className='d-flex align-items-center text-gray-800 text-hover-primary me-5'
                        style={{marginTop: '-16px'}}
                      >
                        <span className='mx-1 fw-bold fs-6'>{user?.companyName}</span>
                      </p>
                    </div>
                  </div>
                  {currentUser?.id === user?.id && (
                    <div className='d-flex'>
                      <Link
                        to={'/settings'}
                        className='menu-link px-5 btn btn-primary community-card'
                      >
                        Edit Your Profile
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='profile-detail-tabs px-8'>
            <ViewProfileTabs user={user} />
          </div>
          <GenericModal
            hideHeader
            hideFooter
            title=''
            showModal={showLevelDetailModal}
            onHideModal={() => setShowLevelDetailModal(false)}
            backdrop={true}
          >
            <LevelsInfo communityName={selectedCommunity?.name} />
          </GenericModal>
        </div>
      )}
    </div>
  )
}
