import {useNavigate} from 'react-router-dom'

const CategoriesFilterCard = ({categories, selectedCategoryId, setSelectedCategoryId}: any) => {
  const navigate = useNavigate()

  const handleCategoryChange = (categoryId: string) => {
    setSelectedCategoryId(categoryId)
    navigate(`?categoryId=${categoryId}`)
  }

  const removeCategoryFilter = () => {
    setSelectedCategoryId('')
    navigate('?page=1')
  }

  return (
    <div className='w-100 rounded pb-2 mb-9' style={{backgroundColor: 'rgb(239,246,255)'}}>
      <div className='row'>
        <div className='d-flex justify-content-between align-items-end'>
          <div className='col-7'>
            <button
              onClick={removeCategoryFilter}
              type='button'
              className='m-4 btn btn-primary text-secondary'
            >
              All
            </button>
          </div>
          <div className='col'></div>
          <div className='col'>
            <button
              className={`m-4 border-0 px-6 py-4 rounded ${
                selectedCategoryId ? 'bg-blue-200' : 'bg-primary'
              }`}
            >
              <i className='fa-solid fa-filter text-secondary'></i>
            </button>
          </div>
        </div>
      </div>
      <div className='px-5 fw-bold'>
        <ul className='list-unstyled'>
          <li className='form-item'>
            <div className='form-check'>
              {categories?.map((category: any) => (
                <div key={category.id} className='m-1'>
                  <input
                    className='form-check-input rounded-circle mt-1'
                    type='radio'
                    name='filterOption'
                    id={`filterOption${category.id}`}
                    onChange={() => handleCategoryChange(category.id)}
                    checked={selectedCategoryId === category.id}
                  />
                  <label
                    className='form-check-label text-dark fs-3 cursor-pointer'
                    htmlFor={`filterOption${category.id}`}
                  >
                    {category.name}
                  </label>
                </div>
              ))}
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default CategoriesFilterCard
