import {useState} from 'react'
import moment from 'moment'
import {Button} from 'react-bootstrap'

import {useAuth} from '../../../modules/auth'
import {useFetchThreads, useUpdateThread} from '../../../queries/threads'
import FetchingSpinner from '../../../modules/common/FetchingSpinner'
import NewThreadModal from './NewThreadModal'

import './Users.css'

const UserThreads = ({onClickThread, selectedUser}: any) => {
  const [showModal, setShowModal] = useState(false)
  const {currentUser, selectedCommunity} = useAuth()
  const canManageThreads = selectedCommunity?.permissions.canManageThreads

  const userThreadsResult = useFetchThreads({userId: selectedUser.id || currentUser?.id})
  const threads = userThreadsResult.data
  const {mutateAsync: updateThread} = useUpdateThread()

  return (
    <div className='card px-5 py-4 user-threads'>
      {!canManageThreads && (
        <div className='py-5 mb-3 text-start rounded'>
          <Button variant='primary' onClick={() => setShowModal(true)}>
            Start new chat
          </Button>
        </div>
      )}
      <div>
        <div className='row'>
          <div className={`${canManageThreads ? 'col-3' : 'col-4'}`}>
            <h4>Date</h4>
          </div>
          <div className={`${canManageThreads ? 'col-5' : 'col-4'}`}>
            <h4>Details</h4>
          </div>
          <div className={`${canManageThreads ? 'col-4' : 'col-4'}`}>
            <h4>Status</h4>
          </div>
        </div>
        {userThreadsResult.isFetching && <FetchingSpinner />}
        {!userThreadsResult.isFetching && (
          <div
            className='overflow-x-hidden overflow-y-scroll threads-container'
            style={{height: canManageThreads ? '68vh' : '53vh'}}
          >
            {threads && threads.threads && threads.threads.length > 0 ? (
              threads.threads.map((thread: any) => (
                <div style={{borderTop: '1px solid #E1E3EA'}} key={thread.id} className='pt-2'>
                  <div className='row text-start text-gray'>
                    <div
                      className={`${canManageThreads ? 'col-3' : 'col-4'} cursor-pointer`}
                      onClick={() => onClickThread(selectedUser, thread)}
                    >
                      <p className='fw-5 text-dark fs-6'>
                        {thread.createdAt && moment(thread.createdAt).format('MMM DD, YYYY')}
                      </p>
                    </div>
                    <div
                      className={`${canManageThreads ? 'col-5' : 'col-4'} cursor-pointer`}
                      onClick={() => onClickThread(selectedUser, thread)}
                    >
                      <p className='fw-5 text-dark fs-6'>{thread.subject}</p>
                    </div>
                    <div className='col-2'>
                      <span
                        className={`badge bg-${
                          thread.status === 'open' ? 'danger' : 'success'
                        } text-light`}
                      >
                        {thread.status === 'open' ? 'Open' : 'Resolved'}
                      </span>
                    </div>
                    <div className='col-2'>
                      {canManageThreads && (
                        <div className='dropdown mb-2 col-2'>
                          <button
                            className='bg-transparent border-0'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            <i className='fa-solid fs-1 fa-ellipsis'></i>
                          </button>
                          <ul className='dropdown-menu my-5'>
                            {thread?.status === 'open' && (
                              <li>
                                <span
                                  onClick={() => updateThread({id: thread.id, status: 'resolved'})}
                                  className='dropdown-item cursor-pointer'
                                >
                                  Mark as resolved
                                </span>
                              </li>
                            )}
                            {thread?.status === 'resolved' && (
                              <li>
                                <span
                                  onClick={() => updateThread({id: thread.id, status: 'open'})}
                                  className='dropdown-item cursor-pointer'
                                >
                                  Open
                                </span>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div style={{borderTop: '1px solid #E1E3EA'}} className='pt-2'>
                <p>No threads Found!</p>
              </div>
            )}
          </div>
        )}
      </div>

      {!canManageThreads && showModal && (
        <NewThreadModal
          showChatModal={showModal}
          setShowChatModal={setShowModal}
          selectedUser={currentUser}
        />
      )}
    </div>
  )
}

export default UserThreads
