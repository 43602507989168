import React, {useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useAddFeedLike} from '../../../queries/feeds'
import {useLikeFeedComment} from '../../../queries/feedComments'

interface FeedLikes {
  likes?: any
  feedId: number
  currentUserId?: any
  commentId?: number
}

const RecentFeedLikes: React.FC<FeedLikes> = ({likes, feedId, currentUserId, commentId}) => {
  const hasCurrentUserLiked = likes?.find((like: any) => like.userId === currentUserId)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [liked, setLiked] = useState(false)
  const {mutateAsync: postNewFeedLike} = useAddFeedLike()
  const {mutateAsync: postCommentLike} = useLikeFeedComment()

  const handleLikeClick = async () => {
    if (isSubmitting) return // Prevent multiple clicks while submitting

    try {
      setIsSubmitting(true)
      setLiked(true)
      if (commentId) {
        postCommentLike({feedId, commentId})
      } else {
        await postNewFeedLike(feedId)
      }
      setTimeout(() => {
        setIsSubmitting(false)
        setLiked(false)
      }, 500)
    } catch (err) {
      console.error(err, 'An error occurred')
      setIsSubmitting(false)
      setLiked(false)
    }
  }

  const renderTooltip = (props: any) => (
    <Tooltip id='button-tooltip' {...props}>
      {likes.map((like: any) => (
        <div key={like.id} className='text-decoration-underline'>
          {like.user.fullName}
        </div>
      ))}
      {likes.length === 0 && <div>0 likes</div>}
    </Tooltip>
  )

  return (
    <div>
      <div className='mx-2 cursor-pointer'>
        <i
          onClick={handleLikeClick}
          className={`fa-regular fa-thumbs-up fs-2 ${liked ? 'feed-liked' : ''}`}
          style={{color: hasCurrentUserLiked ? '#009ef7' : '#a1a5b7'}}
        ></i>
        <OverlayTrigger placement='top' delay={{show: 250, hide: 150}} overlay={renderTooltip}>
          <span onClick={handleLikeClick} className='fs-3 mx-3 fw-medium text-gray'>
            {likes.length}
          </span>
        </OverlayTrigger>
      </div>
    </div>
  )
}

export default RecentFeedLikes
