import moment from 'moment'
import React, {useState} from 'react'
import {Form} from 'react-bootstrap'
import DatePicker from 'react-datepicker'

import {RecOptionsProp, weekOptions} from '../../utils.events'

interface RecurringRuleProps {
  startDate: Date | string
  recOptions: RecOptionsProp
  setRecOptions: (value: RecOptionsProp) => void
}

const RecurringRule: React.FC<RecurringRuleProps> = ({startDate, recOptions, setRecOptions}) => {
  const [recEndOption, setRecEndOption] = useState('never')

  const handleFreqChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedFreq = e.target.value as '3' | '2' | '1' | '0'
    setRecOptions({
      ...recOptions,
      freq: selectedFreq,
      bymonthday: selectedFreq === '1' ? [startDay] : undefined, // default for weekly
      bysetpos: undefined,
      byweekday: undefined,
    })
  }
  const startDay = moment(startDate).format('D')
  const dayOfWeek = moment(startDate).format('dddd')
  const dayOfWeekNum = (moment(startDate).day() + 6) % 7
  const getOrdinal = (num: number) => {
    const ordinals = ['first', 'second', 'third', 'fourth', 'fifth']
    return ordinals[num - 1] || `${num}th`
  }
  const weekOfMonth = getOrdinal(Math.ceil(moment(startDate).date() / 7))

  const getOrdinalIndexNum = (num: number) => {
    const ordinals = [1, 2, 3, 4, 5]
    return ordinals[num - 1] || num
  }
  const weekOfMonthNum = getOrdinalIndexNum(Math.ceil(moment(startDate).date() / 7))

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    if (value === 'day') {
      setRecOptions({
        ...recOptions,
        bymonthday: [startDay],
        bysetpos: undefined,
      })
    } else if (value === 'week') {
      setRecOptions({
        ...recOptions,
        bymonthday: undefined,
        bysetpos: [String(weekOfMonthNum)],
        byweekday: [String(dayOfWeekNum)],
      })
    }
  }

  return (
    <div>
      <div className='d-flex align-items-center'>
        <Form.Label>Repeat every</Form.Label>
        <input
          type='number'
          className='form-control w100 mx-3'
          name='interval'
          id='interval'
          defaultValue={1}
          onChange={(e) => setRecOptions({...recOptions, interval: Number(e.target.value)})}
          onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
        />
        <Form.Select
          className='w100'
          aria-label='Repeat frequency'
          onChange={handleFreqChange}
          value={recOptions.freq}
        >
          <option value='3'>Days</option>
          <option value='2'>Weeks</option>
          <option value='1'>Months</option>
          <option value='0'>Years</option>
        </Form.Select>
      </div>
      {recOptions.freq === '2' && (
        <div className='d-flex my-2'>
          {weekOptions.map((option, index) => (
            <Form.Check
              key={index}
              className='m-2 radio-container'
              type='checkbox'
              label={option.label}
              value={option.value}
              checked={recOptions.byweekday?.includes(option.value)}
              onChange={(e) => {
                if (e.target.checked) {
                  setRecOptions({
                    ...recOptions,
                    byweekday: recOptions.byweekday
                      ? recOptions.byweekday.concat(option.value)
                      : [option.value],
                  })
                } else {
                  setRecOptions({
                    ...recOptions,
                    byweekday: recOptions.byweekday?.filter(
                      (week: string) => week !== option.value
                    ),
                  })
                }
              }}
            />
          ))}
        </div>
      )}
      {recOptions.freq === '1' && (
        <div className='mt-4'>
          <Form.Select className='w-65 m-2' onChange={handleMonthChange}>
            <option value='day'>Monthly on day {startDay}</option>
            <option value='week'>{`Monthly on the ${weekOfMonth} ${dayOfWeek}`}</option>
          </Form.Select>
        </div>
      )}

      <div
        className={`d-flex flex-wrap ${
          recEndOption === 'on' ? 'align-items-center' : 'align-items-end'
        }`}
      >
        <div className='mr20'>
          <Form.Group controlId='repeat'>
            <Form.Label>Ends</Form.Label>
            <div>
              <Form.Check
                className='mx-2 mb-3 radio-container'
                type='radio'
                label='Never'
                value='never'
                checked={recEndOption === 'never'}
                onChange={(e) => setRecEndOption(e.target.value)}
              />
              <Form.Check
                className='mx-2 my-3 radio-container'
                type='radio'
                label='On'
                value='on'
                checked={recEndOption === 'on'}
                onChange={(e) => setRecEndOption(e.target.value)}
              />
              <Form.Check
                className='mx-2 my-3 radio-container'
                type='radio'
                label='After'
                value='after'
                checked={recEndOption === 'after'}
                onChange={(e) => setRecEndOption(e.target.value)}
              />
            </div>
          </Form.Group>
        </div>
        {recEndOption === 'on' && (
          <div className='w-50'>
            <Form.Group className='endDate-container'>
              <Form.Label className='required'>End Date</Form.Label>
              <DatePicker
                className='form-control'
                selected={recOptions.until || new Date()}
                onChange={(e: any) => setRecOptions({...recOptions, until: e})}
                name='endDate'
                dateFormat='MM/dd/yyyy'
              />
            </Form.Group>
          </div>
        )}
        {recEndOption === 'after' && (
          <div className='w-50'>
            <Form.Group controlId='occurrences'>
              <Form.Label className='required'>Executions</Form.Label>
              <input
                type='number'
                className='form-control'
                value={recOptions.count}
                placeholder='1'
                onChange={(e) => setRecOptions({...recOptions, count: e.target.value})}
                onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
              />
            </Form.Group>
          </div>
        )}
      </div>
    </div>
  )
}

export default RecurringRule
