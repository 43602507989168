import React, {useState} from 'react'
import classNames from 'classnames'

import styles from './pagination.module.scss'

export interface Props {
  page: number
  totalPages: number
  handlePagination: (page: number) => void
}

export const PaginationComponent: React.FC<Props> = ({page, totalPages, handlePagination}) => {
  const [activePage, setActivePage] = useState(page)

  return (
    <div className={styles.pagination}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '10px',
          marginBottom: '10px',
        }}
        className={styles.paginationWrapper}
      >
        {page !== 1 && (
          <button
            onClick={() => {
              handlePagination(page - 1)
              setActivePage(page - 1)
            }}
            type='button'
            className={classNames(
              [styles.pageItem, styles.sides, 'mx-2 btn btn-sm cursor-pointer fs-3'].join(' '),
              {'btn-primary': activePage === page + 1}
            )}
          >
            &lt;
          </button>
        )}

        <button
          onClick={() => {
            handlePagination(1)
            setActivePage(1)
          }}
          type='button'
          className={classNames(styles.pageItem, 'mx-2 btn btn-sm cursor-pointer fs-3', {
            [styles.active]: activePage === 1,
            'btn-primary': activePage === 1,
          })}
        >
          {1}
        </button>

        {page > 3 && <div className={styles.separator}>...</div>}

        {page === totalPages && totalPages > 3 && (
          <button
            onClick={() => {
              handlePagination(page - 2)
              setActivePage(page - 2)
            }}
            type='button'
            className={classNames(styles.pageItem, 'mx-2 btn btn-sm cursor-pointer fs-3', {
              'btn-primary': activePage === page - 2,
            })}
          >
            {page - 2}
          </button>
        )}

        {page > 2 && (
          <button
            onClick={() => {
              handlePagination(page - 1)
              setActivePage(page - 1)
            }}
            type='button'
            className={classNames(styles.pageItem, 'mx-2 btn btn-sm cursor-pointer fs-3', {
              'btn-primary': activePage === page - 1,
            })}
          >
            {page - 1}
          </button>
        )}

        {page !== 1 && page !== totalPages && (
          <button
            onClick={() => {
              handlePagination(page)
              setActivePage(page)
            }}
            type='button'
            className={classNames(
              styles.pageItem,
              styles.active,
              'mx-2 btn btn-sm cursor-pointer fs-3',
              {
                'btn-primary': activePage === page,
              }
            )}
          >
            {page}
          </button>
        )}

        {page < totalPages - 1 && (
          <button
            onClick={() => {
              handlePagination(page + 1)
              setActivePage(page + 1)
            }}
            type='button'
            className={classNames(styles.pageItem, 'mx-2 btn btn-sm cursor-pointer fs-3', {
              'btn-primary': activePage === page + 1,
            })}
          >
            {page + 1}
          </button>
        )}

        {page === 1 && totalPages > 3 && (
          <button
            onClick={() => {
              handlePagination(page + 2)
              setActivePage(page + 2)
            }}
            type='button'
            className={classNames(styles.pageItem, 'mx-2 btn btn-sm cursor-pointer fs-3', {
              'btn-primary': activePage === page + 2,
            })}
          >
            {page + 2}
          </button>
        )}

        {page < totalPages - 2 && <div className={styles.separator}>...</div>}

        <button
          onClick={() => {
            handlePagination(totalPages)
            setActivePage(totalPages)
          }}
          type='button'
          className={classNames(styles.pageItem, 'mx-2 btn btn-sm cursor-pointer fs-3', {
            [styles.active]: activePage === totalPages,
            'btn-primary': activePage === totalPages,
          })}
        >
          {totalPages}
        </button>

        {page !== totalPages && (
          <button
            onClick={() => {
              handlePagination(page + 1)
              setActivePage(page + 1)
            }}
            type='button'
            className={classNames(
              styles.pageItem,
              'mx-2 btn btn-sm cursor-pointer fs-3',
              styles.sides,
              {
                'btn-primary': activePage === page + 1,
              }
            )}
          >
            &gt;
          </button>
        )}
      </div>
    </div>
  )
}

export const Pagination = PaginationComponent
