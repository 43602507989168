export interface IProfileDetails {
  profilePic: string
  firstName: string
  lastName: string
  companyName: string
  city: string
  state: any[]
  bio: string
  timezone: string
  hasCertificate?: string
  hasWebsite?: string
  certificatesIds: any[]
  userWebsites: any[]
  removeProfileImage: any
}

export interface IFeedForm {
  title: string
  description: string
  budget: number
  location: any[]
  timeFrame: string
  industryIds: any[]
  feedType: string
}

export interface IPricePlanForm {
  firstName: string
  lastName: string
  email: string
}
export interface IFeedBackForm {
  message: string
}

export const feedFormInitValues: IFeedForm = {
  title: '',
  description: '',
  budget: 0,
  location: [],
  timeFrame: '',
  industryIds: [],
  feedType: 'public',
}

export const pricePlanFormInitValues: IPricePlanForm = {
  firstName: '',
  lastName: '',
  email: '',
}

export const feedBackFormInitValues: IFeedBackForm = {
  message: '',
}
export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export interface IConnectedAccounts {
  google: boolean
  github: boolean
  stack: boolean
}

export interface IEmailPreferences {
  successfulPayments: boolean
  payouts: boolean
  freeCollections: boolean
  customerPaymentDispute: boolean
  refundAlert: boolean
  invoicePayments: boolean
  webhookAPIEndpoints: boolean
}

export interface INotifications {
  notifications: {
    email: boolean
    phone: boolean
  }
  billingUpdates: {
    email: boolean
    phone: boolean
  }
  newTeamMembers: {
    email: boolean
    phone: boolean
  }
  completeProjects: {
    email: boolean
    phone: boolean
  }
  newsletters: {
    email: boolean
    phone: boolean
  }
}

export interface IDeactivateAccount {
  confirm: boolean
}

export const profileDetailsInitValues: IProfileDetails = {
  profilePic: '',
  firstName: '',
  lastName: '',
  companyName: '',
  city: '',
  state: [],
  bio: '',
  timezone: '',
  certificatesIds: [],
  userWebsites: [],
  removeProfileImage: false,
}

export const updateEmail: IUpdateEmail = {
  newEmail: 'support@keenthemes.com',
  confirmPassword: '',
}

export const updatePassword: IUpdatePassword = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
  google: true,
  github: true,
  stack: false,
}

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
  notifications: {
    email: true,
    phone: true,
  },
  billingUpdates: {
    email: true,
    phone: true,
  },
  newTeamMembers: {
    email: true,
    phone: false,
  },
  completeProjects: {
    email: false,
    phone: true,
  },
  newsletters: {
    email: false,
    phone: false,
  },
}

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
}

export interface IUser {
  certificate: string
  websites: string
  address: string
  companyName: string
  companySize: string
  createdAt: number
  createdById: number
  email: string
  firstName: string
  id: number
  isActive: boolean
  isAdmin: boolean
  lastName: string
  phoneNumber: string
  profilePic: string
  state: string
  updatedAt: number
  updatedById: string
  username: string
  yearsOfExperience: string
  connectionStatus: string
  absoluteProfilePath: string
  unReadMessages?: []
}
