import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {toast} from 'react-toastify'

import * as notesApi from '../apis/notes'
import {NOTES_QUERY_KEY} from '../constants/reactQueryKeys'
import {useSearchParams} from 'react-router-dom'
import {parseError} from '../modules/common/utils'

export const useCreateNote = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (notesData: any) => notesApi.postNotes(notesData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_QUERY_KEY],
      })
      toast.success('Note created successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const useGetNotes = (moduleId: number | null = null) => {
  const [searchParams] = useSearchParams()
  const page = searchParams.get('page') || 1
  return useQuery({
    queryKey: [NOTES_QUERY_KEY],
    queryFn: () => notesApi.getNotes({page, limit: 30, moduleId}),
  })
}
export const useUpdateNote = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({noteId, notesData}: any) => notesApi.putNote(noteId, notesData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_QUERY_KEY],
      })
      queryClient.invalidateQueries({
        queryKey: [NOTES_QUERY_KEY],
      })
      toast.success('Note updated successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const useDeleteNotes = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (noteId: number) => notesApi.deleteNote(noteId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_QUERY_KEY],
      })
      toast.success('Note deleted successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}
