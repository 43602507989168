import * as Yup from 'yup'
import {toast} from 'react-toastify'
import {Link} from 'react-router-dom'
import {useState} from 'react'
import {useFormik} from 'formik'

import {
  IPricePlanForm,
  pricePlanFormInitValues as initialValues,
} from '../../modules/accounts/components/settings/SettingsModel'
import apiClient from '../../apis/apiClient'

import './PricingIndex.css'

const pricingPlanFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required').max(150),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().required('Email is required').email('Email is invalid'),
})

export default function PricingIndex() {
  const [loading, setLoading] = useState(false)
  const [packageType, setPackageType] = useState('basic')
  const formik = useFormik<IPricePlanForm>({
    initialValues,
    validationSchema: pricingPlanFormSchema,
    onSubmit: async (values) => {
      setLoading(true)
      apiClient
        .post('/public/create-checkout-session', {...values, communityId: 1, packageType})
        .then((res) => {
          const {data} = res
          const {redirectUrl} = data.data
          window.location.href = redirectUrl
        })
        .catch(function (error) {
          toast.error(
            error ? error.response?.data?.message : 'Something went wrong, Please try later',
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          )
          setLoading(false)
        })
    },
  })

  return (
    <div className='py-12 bg-white'>
      <Link to='/'></Link>
      <div className='text-center py-7'>
        <h1 style={{fontSize: '25px'}} className='card-heading text-primary'>
          Join the GovAcha Community
        </h1>
        <h5 className='text-gray'>
          Unlock your full potential in government contracting with the right tools and community
          support.
        </h5>
      </div>
      <div className='text-center'>
        <Link
          to='/auth'
          style={{fontWeight: 600}}
          className='text-info cursor-pointer text-decoration-underline'
        >
          Already have an account?
        </Link>
      </div>

      <div
        className='modal fade'
        id='exampleModal'
        tabIndex={-1}
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-body'>
              <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <div className='d-flex justify-content-center flex-row pricing-table-container align-items-center'>
                  <div className='card2 text-center p-3 mx-2 rounded-3'>
                    <h1>Please fill the form</h1>
                    <div className='my-5 d-flex align-items-end form-card justify-content-center'>
                      <div className='d-block'>
                        <div style={{textAlign: 'start'}}>
                          <label className='fw-bold mx-2 input-label text-gray' htmlFor='fullName'>
                            First Name
                          </label>
                        </div>

                        <input
                          type='text'
                          style={{backgroundColor: '#F1F1F2'}}
                          className='p-3 border-0 m-2 rounded-3'
                          placeholder='First name'
                          {...formik.getFieldProps('firstName')}
                        />
                        {formik.touched.firstName && formik.errors.firstName && (
                          <div className='text-start mx-3 fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.firstName}</div>
                          </div>
                        )}
                      </div>
                      <div className='d-block'>
                        <div style={{textAlign: 'start'}}>
                          <label className='fw-bold mx-2 input-label text-gray' htmlFor='fullName'>
                            Last Name
                          </label>
                        </div>
                        <input
                          type='text'
                          style={{backgroundColor: '#F1F1F2'}}
                          className='p-3 border-0 m-2 rounded-3'
                          placeholder='Second name'
                          {...formik.getFieldProps('lastName')}
                        />
                        {formik.touched.lastName && formik.errors.lastName && (
                          <div className='text-start mx-3 fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.lastName}</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='form-card2 px-2 align-items-center'>
                      <div style={{textAlign: 'start'}}>
                        <label htmlFor='email' className='email-label fw-bold text-gray'>
                          Email
                        </label>
                      </div>

                      <input
                        type='text'
                        style={{backgroundColor: '#F1F1F2'}}
                        className='p-3 border-0 my-2 w-100 rounded-3'
                        placeholder='Email'
                        {...formik.getFieldProps('email')}
                      />
                    </div>
                    {formik.touched.email && formik.errors.email && (
                      <div className='text-start mx-3 email-validator fv-plugins-message-container'>
                        <div className='email-validator fv-help-block'>{formik.errors.email}</div>
                      </div>
                    )}
                    <div className='my-5'>
                      <button type='submit' className='btn mb-2 btn-primary'>
                        {!loading && 'Start your free trial'}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      <div>
                        <small className='text-muted fw-bold mt-6'>
                          Try any Later plan free for 7 days.
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className='container py-5'>
        <div className='row justify-content-center'>
          <div className='col-md-4 col-sm-6 mb-4'>
            <div className='card border border border-secondary mt-20 shadow-sm border-rounded'>
              <div className='card-body p-6'>
                <div className='d-flex align-items-center mb-3'>
                  <p className='fs-1 text-muted fw-bold mb-0'>GovAcha Basic</p>
                  <i className='fa-solid fs-2 mx-3 fa-shield-halved text-muted'></i>
                </div>
                <p
                  className='mb-4 fs-7'
                  style={{color: 'hsl(var(--foreground-light) / var(--tw-text-opacity))'}}
                >
                  {' '}
                  Start your journey with the essentials, perfect for small businesses and
                  newcomers.
                </p>
                <div>
                  <button
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModal'
                    className='btn btn-secondary fw-bold w-100'
                    onClick={() => setPackageType('basic')}
                  >
                    Join Basic Plan
                  </button>
                </div>
                <div className='mt-5 d-flex flex-row'>
                  <h1 className='card-subtitle text-muted fw-bold' style={{fontSize: '4rem'}}>
                    $99
                  </h1>
                  <span className='text-muted fw-bold mt-10'>/ month</span>
                </div>
                <div className='d-flex flex-column mt-10'>
                  <ul className='list-unstyled mb-4'>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-muted me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Community Engagement</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-muted me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Members Directory</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-muted me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Essential Resources</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-muted me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Classroom Access</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-muted me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Event Calendar</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-muted me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Personal Notes</span>
                    </li>
                    <li className='d-flex align-items-center'>
                      <i className='fas fa-check-circle text-muted me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Leaderboard</span>
                    </li>
                  </ul>
                </div>
                <div className='text-center mt-5'>
                  <i className='text-muted'>Join GovAcha Basic – Get Started</i>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4 col-sm-6 mb-4'>
            <div className='card border border border-primary mt-7 shadow-sm border-rounded'>
              <div className='card-body p-6'>
                <div className='d-flex align-items-center mb-3'>
                  <p className='fs-1 text-primary fw-bold mb-0'>Govacha Premium</p>
                  <i className='fa-solid fs-2 mx-3 fa-crown text-primary'></i>
                </div>
                <p
                  className='mb-4 fs-7'
                  style={{color: 'hsl(var(--foreground-light) / var(--tw-text-opacity))'}}
                >
                  {' '}
                  Empower your business with advanced tools, resources, and exclusive community
                  access.
                </p>
                <div>
                  <button
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModal'
                    className='btn btn-primary fw-bold w-100'
                    onClick={() => setPackageType('premium')}
                  >
                    Join Premium Plan
                  </button>
                </div>
                <div className='mt-5 d-flex flex-row'>
                  <h1 className='card-subtitle text-primary fw-bold' style={{fontSize: '4rem'}}>
                    $297
                  </h1>
                  <span className='text-primary fw-bold mt-10'>/ month</span>
                </div>
                <div className='d-flex flex-column mt-10'>
                  <ul className='list-unstyled mb-4'>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>GovAcha Basic Access</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>GovChat</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Pipelines</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Advanced Resources</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Premium Courses</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Priority Support</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Expert Consultation</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Private Feeds</span>
                    </li>
                    <li className='d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Private Community Access</span>
                    </li>
                  </ul>
                </div>
                <div className='text-center mt-5'>
                  <i className='text-primary'>Join GovAcha Premium – Unlock Advanced Features</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
