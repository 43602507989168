import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {toast} from 'react-toastify'

import * as messagesApi from '../apis/messages'
import {MESSAGES_QUERY_KEY} from '../constants/reactQueryKeys'
import {parseError} from '../modules/common/utils'

export const useFetchChat = (id: string | undefined, {page = 1}: {page?: number | string} = {}) => {
  return useQuery({
    queryKey: [MESSAGES_QUERY_KEY, {id, page}],
    queryFn: () => messagesApi.fetchChat(id, {limit: 1000, page}),
    refetchInterval: 30 * 1000,
  })
}

export const useSendMessage = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      recipientUserId,
      messageData,
    }: {
      recipientUserId: number
      messageData: {message: string}
    }) => messagesApi.postMessage(recipientUserId, messageData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [MESSAGES_QUERY_KEY],
      })
    },
    onError: (error) => {
      toast.error(parseError(error))
    },
  })
}
