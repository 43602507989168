import React, {useRef} from 'react'
import {Button, Form} from 'react-bootstrap'
import * as Yup from 'yup'
import {ErrorMessage, Field, FieldProps, Formik} from 'formik'
import {Editor} from '@tinymce/tinymce-react'

import {useCreateNote, useUpdateNote} from '../../../../queries/notes'

export interface NotesForm {
  note: any
  moduleId: number
  setShowNoteForm: any
}

export const defaultNote = {
  id: '',
  title: '',
  description: '',
  moduleId: null,
}

const NoteForm: React.FC<NotesForm> = ({note = defaultNote, moduleId, setShowNoteForm}) => {
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
  })

  const {mutateAsync: createNote} = useCreateNote()
  const {mutateAsync: updateNote} = useUpdateNote()
  const editorRef = useRef<any>(null)
  return (
    <div>
      <Formik
        initialValues={{
          title: note?.title,
          description: note?.description,
          moduleId,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, {setSubmitting, resetForm}) => {
          try {
            const {title, description} = values
            if (!note?.id) {
              await createNote({title, description, moduleId})
            } else {
              await updateNote({noteId: note?.id, notesData: values})
            }
            resetForm()
          } catch {
            // Handle any errors here
          } finally {
            setShowNoteForm(false)
            setSubmitting(false)
          }
        }}
      >
        {({handleSubmit, handleChange, isValid, dirty}) => (
          <Form className='h-75 my-3' onSubmit={handleSubmit}>
            <Form.Group controlId='formGroupTitle'>
              <Field
                name='title'
                type='text'
                placeholder='Title'
                className='border-0 fs-1 fw-bold form-control'
                onChange={handleChange}
              />
              <ErrorMessage name='title' component='div' className='mx-4 mb-3 text-danger' />
            </Form.Group>
            <div className='mt-4'>
              <Field name='description'>
                {({field}: FieldProps<string>) => (
                  <Editor
                    onInit={(evt, editor) => {
                      editorRef.current = editor
                      const editorContainer = editor.targetElm
                      editorContainer.style.border = 'none'
                      editorContainer.style.fontSize = '14px'
                      editorContainer.style.marginLeft = '10px'
                      editorContainer.style.outline = 'none'
                    }}
                    tinymceScriptSrc={'/tinymce/tinymce.js'}
                    id={'description'}
                    value={field.value || ''}
                    init={{
                      menubar: false,
                      statusbar: false,
                      branding: false,
                      toolbar: false,
                      inline: true,
                      placeholder: 'Write something...',
                      plugins: ['quickbars', 'lists'],
                      quickbars_insert_toolbar: '',
                      quickbars_selection_toolbar:
                        'h1 h2 h3 bold italic underline forecolor bullist numlist',
                    }}
                    onEditorChange={(e: any) => {
                      handleChange({target: {name: 'description', value: e}})
                    }}
                  />
                )}
              </Field>
            </div>

            <hr className='text-gray mt-9' />
            <div className='d-flex align-items-end justify-content-end mt-10'>
              <Button
                type='button'
                variant='secondary'
                className='mx-3'
                onClick={() => setShowNoteForm(false)}
              >
                Discard
              </Button>
              <Button type='submit' variant='primary' disabled={!dirty || !isValid}>
                {note?.id ? 'Update Note' : 'Submit'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
export default NoteForm
