import {Fragment, useEffect, useRef} from 'react'

interface ChatInputProps {
  inputText: string
  sendMessage: () => void
  setInputText: (value: string) => void
  isPending: boolean
}

const ChatInput = ({inputText, sendMessage, setInputText, isPending}: ChatInputProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [inputText])

  return (
    <Fragment>
      <div style={{bottom: '5px'}} className='w-100 position-absolute'>
        <div
          style={{
            border: '1px solid #1cc',
            borderRadius: '8px',
            backgroundColor: '#FFFFFF',
          }}
        >
          <textarea
            ref={textareaRef}
            className='fs-4 form-control border-0 p-5 overflow-hidden overflow-y-scroll resize-none'
            style={{width: '94%', maxHeight: '200px'}}
            placeholder='Message GovChat'
            value={inputText}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault()
                if (isPending) return
                sendMessage()
              }
            }}
            rows={1}
            onChange={(e) => setInputText(e.target.value)}
          />
          <button
            className='position-absolute btn btn-primary border-0 p-0 rounded-circle w40 h40'
            style={{bottom: '70%', right: '0.5rem', transform: 'translateY(50%)'}}
            onClick={sendMessage}
            disabled={isPending || inputText.length === 0}
          >
            <i className='bi bi-send-fill fw-bold fs-4 ml5'></i>
          </button>
        </div>
        <p className='text-black fs-6 text-center'>
          GovChat is here to help, but always double-check critical details.
        </p>
      </div>
    </Fragment>
  )
}

export default ChatInput
