import {getSelectedCommunityId} from '../modules/common/utils'
import apiClient from './apiClient'

interface GetFeedApiParams {
  page: number | string
  limit?: number
  feedType?: string
  categoryId?: string | number | null
  userId?: string | null
  isHighlighted?: boolean
}

interface GetGlobalSearchParams {
  page: number | string
  limit?: number
  search: string
}

export const fetchFeeds = async (params: GetFeedApiParams): Promise<any> => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/user-feeds`, {
    params,
  })
  return response.data?.data || []
}

export const deleteFeed = async (id: number) => {
  const response = await apiClient.delete(`/c/${getSelectedCommunityId()}/user-feeds/${id}`)
  return response.data?.data || {}
}

export const postFeed = async (feedData: any) => {
  const response = await apiClient.post(`/c/${getSelectedCommunityId()}/user-feeds`, feedData)
  return response.data?.data || {}
}

export const addFeedHighlight = async (feedid: any, FeedData: any) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/user-feeds/${feedid}/highlight`,
    FeedData
  )
  return response.data?.data || {}
}

export const updateFeed = async (id: any, feedData: any) => {
  const response = await apiClient.put(`/c/${getSelectedCommunityId()}/user-feeds/${id}`, feedData)
  return response.data?.data || {}
}

export const updateEnableComment = async (id: any, feedData: any) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/user-feeds/${id}/enable-comment`,
    feedData
  )
  return response.data?.data || {}
}

export const fetchComments = async (feedId: any, limit: number): Promise<any> => {
  const response = await apiClient.get(
    `/c/${getSelectedCommunityId()}/user-feeds/${feedId}/comments?limit=${limit}`
  )
  return response.data?.data || []
}

export const postFeedComment = async (feedId: number, commentData: any) => {
  const response = await apiClient.post(
    `/c/${getSelectedCommunityId()}/user-feeds/${feedId}/comments`,
    commentData
  )
  return response.data?.data || {}
}

export const deleteFeedComment = async (feedId: number, commentId: number) => {
  const response = await apiClient.delete(
    `/c/${getSelectedCommunityId()}/user-feeds/${feedId}/comments/${commentId}`
  )
  return response.data?.data || {}
}

export const postFeedCommentLike = async (feedId: number, commentId: number) => {
  const response = await apiClient.post(
    `/c/${getSelectedCommunityId()}/user-feeds/${feedId}/comments/${commentId}/likes`
  )
  return response.data?.data || {}
}

export const postFeedLike = async (feedId: number) => {
  const response = await apiClient.post(`/c/${getSelectedCommunityId()}/user-feeds/${feedId}/likes`)
  return response.data?.data || {}
}

export const updateFeedComment = async (feedId: number, commentId: number, commentData: any) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/user-feeds/${feedId}/comments/${commentId}`,
    commentData
  )
  return response.data?.data || {}
}

export const fetchFeedLikes = async (feedId: number): Promise<any> => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/user-feeds/${feedId}/likes`)
  return response.data?.data || []
}

export const deleteFeedLike = async (feedId: number): Promise<any> => {
  const response = await apiClient.delete(
    `/c/${getSelectedCommunityId()}/user-feeds/${feedId}/likes`
  )
  return response.data?.data || {}
}

export const searchInCommunity = async (params: GetGlobalSearchParams): Promise<any> => {
  const response = await apiClient.get(`/communities/${getSelectedCommunityId()}/search`, {
    params,
  })
  return response.data?.data || []
}
