export interface RecOptionsProp {
    freq: '3' | '2' | '1' | '0'
    interval: number // for all options
    bymonthday?: any // for monthly
    byweekday?: any // for weekly
    bysetpos?: any // for monthly
    until?: any // ends on
    count?: number | string // ends on
}

export const weekOptions = [
  {value: '0', label: 'Mon'},
  {value: '1', label: 'Tue'},
  {value: '2', label: 'Wed'},
  {value: '3', label: 'Thu'},
  {value: '4', label: 'Fri'},
  {value: '5', label: 'Sat'},
  {value: '6', label: 'Sun'},
]

export const locationOptions = [
  { value: 'zoom', label: 'Zoom' },
  { value: 'google', label: 'Google Meet' },
  { value: 'link', label: 'Link' },
  { value: 'address', label: 'Address' }
]

export const durationOptions = [
  {value: 1800, label: '0.5 hour'},
  {value: 3600, label: '1 hour'},
  {value: 5400, label: '1.5 hours'},
  {value: 7200, label: '2 hours'},
  {value: 9000, label: '2.5 hours'},
  {value: 10800, label: '3 hours'},
  {value: 12600, label: '3.5 hours'},
  {value: 14400, label: '4 hours'},
  {value: 16200, label: '4.5 hours'},
  {value: 18000, label: '5 hours'},
  {value: 19800, label: '5.5 hours'},
  {value: 21600, label: '6 hours'},
  {value: 23400, label: '6.5 hours'},
  {value: 25200, label: '7 hours'},
  {value: 27000, label: '7.5 hours'},
  {value: 28800, label: '8 hours'},
  {value: 30600, label: '8.5 hours'},
  {value: 32400, label: '9 hours'},
  {value: 34200, label: '9.5 hours'},
  {value: 36000, label: '10 hours'},
  {value: 37800, label: '10.5 hours'},
  {value: 39600, label: '11 hours'},
  {value: 41400, label: '11.5 hours'},
  {value: 43200, label: '12 hours'},
  {value: 45000, label: '12.5 hours'},
  {value: 46800, label: '13 hours'},
  {value: 48600, label: '13.5 hours'},
  {value: 50400, label: '14 hours'},
  {value: 52200, label: '14.5 hours'},
  {value: 54000, label: '15 hours'},
  {value: 55800, label: '15.5 hours'},
  {value: 57600, label: '16 hours'},
  {value: 59400, label: '16.5 hours'},
  {value: 61200, label: '17 hours'},
  {value: 63000, label: '17.5 hours'},
  {value: 64800, label: '18 hours'},
  {value: 66600, label: '18.5 hours'},
  {value: 68400, label: '19 hours'},
  {value: 70200, label: '19.5 hours'},
  {value: 72000, label: '20 hours'},
  {value: 73800, label: '20.5 hours'},
  {value: 75600, label: '21 hours'},
  {value: 77400, label: '21.5 hours'},
  {value: 79200, label: '22 hours'},
  {value: 81000, label: '22.5 hours'},
  {value: 82800, label: '23 hours'},
  {value: 84600, label: '23.5 hours'},
  {value: 86400, label: '24 hours'},
]
