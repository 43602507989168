import {Route, Routes} from 'react-router-dom'
import CoursesIndex from './CoursesIndex'
import CourseDetail from './CourseDetail'

import './CoursesApp.scss'
function CoursesApp() {
  return (
    <Routes>
      <Route index element={<CoursesIndex />} />
      <Route path='/detail/:courseId' element={<CourseDetail />} />
    </Routes>
  )
}

export default CoursesApp
