import axios from 'axios'
import pMemoize from 'p-memoize'
import ExpiryMap from 'expiry-map'

import {setAuth, getAuth, removeAuth} from '../modules/auth/core/AuthHelpers'

async function refreshToken(refreshToken: string | undefined) {
  console.log('refreshing token!!')
  const auth = getAuth()
  const response = await axios.post(`${process.env.REACT_APP_API_URL}auth/refresh-token`, {
    refreshToken,
  })
  const newAccessToken = response.data?.data?.access?.token
  if (!newAccessToken) {
    throw new Error('Token not found')
  }
  if (auth?.data.tokens.access.token) {
    auth.data.tokens.access.token = newAccessToken
    setAuth(auth)
  }

  return newAccessToken
}
const cache = new ExpiryMap(20000)
const memoizeRefreshToken = pMemoize(refreshToken, {cache})

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
  },
})

// Axios request interceptor
apiClient.interceptors.request.use(
  async (config: any) => {
    const auth = getAuth()
    if (auth && auth.data?.tokens?.access?.token) {
      config.headers.Authorization = `Bearer ${auth.data.tokens.access.token}`
    }
    return config
  },
  (err) => Promise.reject(err)
)

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      const auth = getAuth()

      try {
        originalRequest._retry = true
        const newAccessToken = await memoizeRefreshToken(auth?.data?.tokens?.refresh?.token)
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
        return apiClient(originalRequest)
      } catch (refreshError) {
        removeAuth()
        window.location.href = '/auth'
        return Promise.reject(refreshError)
      }
    }

    return Promise.reject(error)
  }
)

export default apiClient
