import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import * as communitiesApi from '../apis/communitySettings'
import {toast} from 'react-toastify'
import {
  COMMUNITY_QUERY_KEY,
  COMMUNITY_LEADERBOARD_QUERY_KEY,
  COMMUNITY_LEADERBOARD_TYPE_QUERY_KEY,
} from '../constants/reactQueryKeys'
import {parseError} from '../modules/common/utils'

export const useUpdateCommunities = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({updateCommunity}: any) => {
      return communitiesApi.putCommunitySettings(updateCommunity)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COMMUNITY_QUERY_KEY],
      })
      toast.success('Community updated successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const useGetCommunities = () => {
  return useQuery({
    queryKey: [COMMUNITY_QUERY_KEY],
    queryFn: () => communitiesApi.getCommunitySettings(),
  })
}

export const useGetCommunitiesLeaderBoard = () => {
  return useQuery({
    queryKey: [COMMUNITY_LEADERBOARD_QUERY_KEY],
    queryFn: () => communitiesApi.getCommunityLeaderBoard(),
  })
}

export const useGetCommunitiesLeaderBoardType = (type: string) => {
  return useQuery({
    queryKey: [COMMUNITY_LEADERBOARD_TYPE_QUERY_KEY, type],
    queryFn: () => communitiesApi.getCommunityLeaderBoardByType(type),
  })
}
