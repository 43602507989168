export default function CloseSvg() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
      <g filter='url(#filter0_d_492_1877)'>
        <rect x='5.5' y='5.5' width='21' height='21' rx='6' fill='white' />
      </g>
      <path
        d='M14.114 18.1213L18.3566 13.8787'
        stroke='black'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.3565 18.1213L14.1138 13.8787'
        stroke='black'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <filter
          id='filter0_d_492_1877'
          x='0.5'
          y='0.5'
          width='31'
          height='31'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_492_1877' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_492_1877'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  )
}
