import './ComingSoon.scss'

const ComingSoon = () => {
  return (
    <>
      <div className='sea'>
        <div className='circle-wrapper'>
          <div className='bubble'></div>
          <div className='submarine-wrapper'>
            <div className='submarine-body'>
              <div className='window'></div>
              <div className='engine'></div>
              <div className='light'></div>
            </div>
            <div className='helix'></div>
            <div className='hat'>
              <div className='leds-wrapper'>
                <div className='periscope'></div>
                <div className='leds'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='text-center mt-5'>
        <h1>
          This <strong className='text-primary'>Feature</strong> is Coming Soon
        </h1>
      </div>
    </>
  )
}

export {ComingSoon}
