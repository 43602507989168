import {useEffect, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment-timezone'
import {useNavigate, useSearchParams} from 'react-router-dom'

import {useAuth} from '../../modules/auth'
import {useFetchEvents} from '../../queries/events'
import EventsForm from './components/EventsForm'
import EventDetailModal from './components/EventDetailModal'
import GenericModal from '../../modules/common/Modal'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import './events.scss'

const EventsIndex = () => {
  const [showCreateEventModal, setShowCreateEventModal] = useState(false)
  const [showEventDetailModal, setShowEventDetailModal] = useState(false)
  const [events, setEvents] = useState<any>([])
  const [selectedOption, setSelectedOption] = useState<'' | 'following' | 'all'>('')
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const [searchParams] = useSearchParams()
  const startDate = searchParams.get('startDate')
  const navigate = useNavigate()
  const localizer = momentLocalizer(moment)
  const {getUserTimezone, selectedCommunity} = useAuth()
  const eventsResult = useFetchEvents()

  const handleEventDetailModalClose = () => {
    setShowEventDetailModal(false)
    setSelectedEvent(null)
  }

  const handleSelectEvent = async (event: any) => {
    setSelectedEvent(event)
    setShowEventDetailModal(true)
  }

  const onNavigate = (newDate: any) => {
    const dateMoment = moment(newDate)
    const startDate = dateMoment.clone().startOf('month').format('YYYY-MM-DD')
    const endDate = dateMoment.clone().endOf('month').format('YYYY-MM-DD')
    navigate(`?startDate=${startDate}&endDate=${endDate}`)
  }

  useEffect(() => {
    eventsResult.refetch()
  }, [startDate])

  useEffect(() => {
    const formattedEvents = eventsResult.data?.events.map((event: any) => {
      const startDate = moment.utc(event.startDate).tz(getUserTimezone())
      return {
        ...event,
        title: `${startDate.format('hh:mm a')} - ${event.title}`,
        originalTitle: event.title,
      }
    })
    setEvents(formattedEvents)
  }, [eventsResult.data?.events])

  return (
    <div className='card p-5'>
      {selectedCommunity?.isCommunityAdmin && (
        <div className='d-flex justify-content-between align-items-center my-3'>
          <h2>
            Calendar <span className='fs-6'>({getUserTimezone()} Time)</span>
          </h2>
          <Button variant='primary' onClick={() => setShowCreateEventModal(true)}>
            Create Event
          </Button>
        </div>
      )}

      <div>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor='startDate'
          endAccessor='endDate'
          onSelectEvent={handleSelectEvent}
          views={['month']}
          onNavigate={onNavigate}
          popup
          style={{height: '80vh'}}
        />
      </div>

      {showCreateEventModal && (
        <GenericModal
          title={`${selectedEvent ? 'Edit Event' : 'Create Event'}`}
          showModal={showCreateEventModal}
          onHideModal={() => {
            setShowCreateEventModal(false)
            setSelectedEvent(null)
          }}
          hideFooter={true}
          modalProps={{
            size: 'lg',
            centered: true,
          }}
        >
          <EventsForm
            actionType={selectedOption}
            selectedEvent={selectedEvent}
            onSubmitEventForm={() => {
              setShowCreateEventModal(false)
              setSelectedEvent(null)
            }}
          />
        </GenericModal>
      )}

      {selectedEvent && (
        <EventDetailModal
          showEventDetailModal={showEventDetailModal}
          handleEventDetailModalClose={handleEventDetailModalClose}
          selectedEvent={selectedEvent}
          selectedCommunity={selectedCommunity}
          showEditEventModal={() => setShowCreateEventModal(true)}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setShowEventDetailModal={setShowEventDetailModal}
        />
      )}
    </div>
  )
}

export default EventsIndex
