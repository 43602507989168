import {ComingSoon} from '../../modules/common/ComingSoon'

const MemberIndex = () => {
  return (
    <>
      <ComingSoon />
    </>
  )
}

export {MemberIndex}
