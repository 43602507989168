import {useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'

import NoteForm from './NoteForm'
import UserCommunityCard from '../../modules/common/UserCommunityCard'
import NoteCard from './NoteCard'
import {useGetNotes} from '../../queries/notes'
import {Pagination} from '../../hooks/Pagination'
import EmptyMessage from '../../modules/common/EmptyMessage'

function NotesIndex() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const page = searchParams.get('page') || 1

  const [showNoteFormModal, setShowNoteFormModal] = useState(false)
  const notesResult = useGetNotes()

  const handleModalShow = () => {
    setShowNoteFormModal(true)
  }

  const handlePage = (page: number) => {
    navigate(`?page=${page}`)
  }

  useEffect(() => {
    notesResult.refetch()
  }, [page])

  let notes = notesResult.data?.notes || []

  return (
    <>
      <div className='kt-content-main'>
        <div className='feeds-container'>
          <div className='d-flex justify-content-between align-items-center w-100'>
            <div>
              <h1 className='text-primary m-0'>Notes</h1>
            </div>
            <div>
              <button className='btn btn-primary' onClick={handleModalShow}>
                <i className='fa-solid fs-5 fa-add'></i>
                <span>New Note</span>
              </button>
            </div>
          </div>
          {!notesResult.isLoading && notes.length === 0 ? (
            <div className='mt-5'>
              <EmptyMessage message={'You have no notes'} />
            </div>
          ) : (
            notes.map((note: any, index: number) => (
              <div key={index}>
                <NoteCard note={note} index={index} />
              </div>
            ))
          )}

          <div className='d-flex mt-3 justify-content-center align-items-center'>
            {notesResult.data?.totalPages > 1 && (
              <Pagination
                page={notesResult.data.page}
                totalPages={notesResult.data.totalPages}
                handlePagination={handlePage}
              />
            )}
          </div>
        </div>
        {showNoteFormModal && (
          <NoteForm show={showNoteFormModal} handleClose={() => setShowNoteFormModal(false)} />
        )}
      </div>
      <div className='kt-content-right'>
        <div className={`kt-content-right_fixed`}>
          <UserCommunityCard />
        </div>
      </div>
    </>
  )
}

export default NotesIndex
