import {Placeholder} from 'react-bootstrap'

const FetchPlaceholder = () => {
  return (
    <div>
      <Placeholder as='span' animation='glow'>
        <Placeholder xs={12} bg='primary' />
      </Placeholder>
      <Placeholder as='span' animation='glow'>
        <Placeholder xs={9} bg='secondary' />
      </Placeholder>
      <Placeholder as='span' animation='glow'>
        <Placeholder xs={8} bg='primary' />
      </Placeholder>
      <Placeholder as='span' animation='glow'>
        <Placeholder xs={5} bg='secondary' />
      </Placeholder>
    </div>
  )
}

export default FetchPlaceholder
