import {useState} from 'react'
import {Modal} from 'react-bootstrap'

import FeedComments from './FeedComments'
import {useAuth} from '../../../modules/auth'
import {FormatedDateDifference} from '../../../modules/common/utils'
import FeedAttachments from './FeedAttachments'
import FeedForm from './FeedForm'
import {useDeleteFeed, useUpdateFeed, useUpdateHighlightFeed} from '../../../queries/feeds'
import {useDisableComment} from '../../../queries/feedComments'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import Image from '../../../modules/common/Image'

interface FeedDetailModalProps {
  feed: any
  showFeedDetailModal: boolean
  handleModalClose: any
}

const FeedDetailModal: React.FC<FeedDetailModalProps> = (props) => {
  const [showDescription, setShowDescription] = useState(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [showEditFeedModal, setShowEditFeedModal] = useState(false)
  const [commentsEnabled, setCommentsEnabled] = useState(props.feed.commentsEnabled)

  const {currentUser, selectedCommunity} = useAuth()
  const {mutateAsync: deleteFeedMutation} = useDeleteFeed()
  const highlightFeedMutation = useUpdateHighlightFeed()
  const updateFeedMutation = useUpdateFeed()
  const updateDisableCommentMutation = useDisableComment()

  const handleFeedPin = async () => {
    updateFeedMutation
      .mutateAsync({
        feedId: props.feed?.id,
        feedData: {
          title: props.feed?.title,
          description: props.feed?.description,
          attachments: props.feed?._attachments,
          isPinned: !props.feed?.isPinned,
        },
      })
      .catch((e: any) => {
        console.error(e, 'could not pin the feed')
      })
  }

  const disableComment = async () => {
    updateDisableCommentMutation
      .mutateAsync({
        feedId: props.feed?.id,
        feedData: {
          commentsEnabled: !commentsEnabled,
        },
      })
      .then(() => {
        setCommentsEnabled(!commentsEnabled)
      })
      .catch((e: any) => {
        console.error(e, 'could not disable comments')
      })
  }

  const handleHighlightedFeed = async () => {
    const newIsHighlighted = !props.feed.isHighlighted
    highlightFeedMutation
      .mutateAsync({
        feedId: props.feed?.id,
        feedData: {
          isHighlighted: newIsHighlighted,
        },
      })
      .catch((e: any) => {
        console.error(e, 'could not Highlight the feed')
      })
  }

  const RenderEditFeedModal = () => {
    return (
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter-edit'
        centered
        show={showEditFeedModal}
        onHide={() => setShowEditFeedModal(false)}
        enforceFocus={false}
        backdrop='static'
      >
        <Modal.Header
          style={{paddingTop: '10px', paddingBottom: '0px'}}
          className='m-0 px-5 border-bottom-0'
        >
          <div className='d-flex'>
            <Image src={`${currentUser?.absoluteProfilePath}`} />
            <div className='d-block mx-2'>
              <h3 className='m-0'>
                {currentUser?.firstName} {currentUser?.lastName}
              </h3>
              <p className='text-gray m-0'>{'updating feed...'}</p>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className='px-5 py-3'>
          <FeedForm feed={props.feed} setShowCreateFeedModal={() => setShowEditFeedModal(false)} />
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <>
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={props.showFeedDetailModal}
        onHide={() => props.handleModalClose()}
      >
        <Modal.Header closeButton className='feed-detail-header'>
          <div className='d-flex justify-content-between align-items-center w-100 mx-2'>
            <div className='d-flex align-items-start'>
              <Image src={`${props.feed?.user?.absoluteProfilePath}`} />
              <div>
                <p className='mx-2 text-primary fw-bold my-0'>
                  {props.feed?.user?.firstName} {props.feed?.user?.lastName}
                  {props.feed?.isHighlighted && (
                    <span className='mx-3 text-muted'>(Highlighted Feed)</span>
                  )}
                </p>
                <p className='mx-2'> Posted {FormatedDateDifference(props.feed?.createdAt)}</p>
              </div>
            </div>
            <div className='d-flex justify-content-end'>
              {(selectedCommunity?.isCommunityAdmin || currentUser?.id === props.feed?.userId) && (
                <div className='dropdown'>
                  <button
                    className='btn btn-secondary btn-sm px-3 py-2'
                    type='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <i className='fa-solid fs-2 feed-menu-icon fa-ellipsis p-0 m-0'></i>
                  </button>
                  <ul className='dropdown-menu'>
                    {currentUser?.id === props.feed?.userId && (
                      <>
                        <li
                          className='dropdown-item cursor-pointer'
                          onClick={() => setDeleteConfirmation(true)}
                        >
                          <i className='fa fa-trash mx-2'></i> Delete
                        </li>
                        <li
                          className='dropdown-item cursor-pointer'
                          onClick={() => {
                            props.handleModalClose()
                            setShowEditFeedModal(true)
                          }}
                        >
                          <i className='fa fa-pencil mx-2'></i> Edit
                        </li>
                      </>
                    )}
                    {selectedCommunity?.isCommunityAdmin && (
                      <li className='dropdown-item cursor-pointer' onClick={handleFeedPin}>
                        <i className='thumbtach-icon fa-solid fa-thumbtack mx-2'></i>{' '}
                        {props.feed?.isPinned ? 'Unpin from feed' : 'Pin to feed'}
                      </li>
                    )}
                    {selectedCommunity?.isCommunityAdmin && (
                      <li className='dropdown-item cursor-pointer' onClick={handleHighlightedFeed}>
                        <i className='fa-solid fa-highlighter mx-2'></i>{' '}
                        {props.feed?.isHighlighted ? 'Unhighlight feed' : 'Highlight feed'}
                      </li>
                    )}
                    {selectedCommunity?.isCommunityAdmin && (
                      <li className='dropdown-item cursor-pointer' onClick={disableComment}>
                        <i className=' fa-solid fa-power-off mx-2'></i>{' '}
                        {commentsEnabled ? 'Disable Comments' : 'Enable Comments'}
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className='h-fit'>
          <div>
            <h1>{props.feed?.title}</h1>
            <div className='my-5'>
              {showDescription && (
                <p className='fs-4' dangerouslySetInnerHTML={{__html: props.feed?.description}}></p>
              )}
              {!showDescription && (
                <div>
                  <p
                    className='fs-4'
                    dangerouslySetInnerHTML={{
                      __html:
                        props.feed?.description?.length > 500
                          ? `${props.feed?.description.slice(0, 500)}...`
                          : props.feed?.description.slice(0, 500),
                    }}
                  ></p>
                </div>
              )}
              {props.feed?.description?.length > 500 && (
                <span
                  onClick={() => setShowDescription(!showDescription)}
                  style={{color: '#3E97FF'}}
                  className='m-0 cursor-pointer fs-5 d-inline'
                >
                  {showDescription ? 'Read Less' : 'Read More'}
                </span>
              )}
              <div className='d-flex align-items-center overflow-x-scroll w-100'>
                <FeedAttachments attachments={props.feed?._attachments} hideDeleteIcon={false} />
              </div>
            </div>
            <div>
              <div className='my-5'>
                <FeedComments feedId={props.feed?.id} commentsEnabled={commentsEnabled} />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {deleteConfirmation && (
        <ConfirmationModal
          showModal={deleteConfirmation}
          onHideModal={() => setDeleteConfirmation(false)}
          disableBtns={false}
          onConfirm={async () => {
            await deleteFeedMutation(props.feed.id)
            setDeleteConfirmation(false)
            props.handleModalClose()
          }}
          onCancel={() => setDeleteConfirmation(false)}
        />
      )}
      {RenderEditFeedModal()}
    </>
  )
}

export default FeedDetailModal
