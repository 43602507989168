import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {toast} from 'react-toastify'

import * as categoriesApi from '../apis/categories'
import {CATEGORIES_QUERY_KEY} from '../constants/reactQueryKeys'
import {parseError} from '../modules/common/utils'

export const useCreateCategory = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (categoriesData: any) => categoriesApi.postCategory(categoriesData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CATEGORIES_QUERY_KEY],
      })
      toast.success('Category created successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const useGetCategories = () => {
  return useQuery({
    queryKey: [CATEGORIES_QUERY_KEY],
    queryFn: () => categoriesApi.getCategories({page: 1, limit: 100}),
  })
}

export const useUpdateCategory = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({categoryId, categoriesData}: any) =>
      categoriesApi.putCategory(categoryId, categoriesData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CATEGORIES_QUERY_KEY],
      })
      toast.success('Category updated successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}

export const useDeleteCategory = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (categoryId: number) => categoriesApi.deleteCategory(categoryId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CATEGORIES_QUERY_KEY],
      })
      toast.success('Category deleted successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
      return error
    },
  })
}
