import {Dropdown} from 'react-bootstrap'

interface Option {
  label: string
  handler: () => void
}

interface CustomDropDownProps {
  elementId: string
  className?: string
  options: Option[]
  dropdownTitle?: React.ReactNode | string
}

const CustomDropdown: React.FC<CustomDropDownProps> = ({
  elementId,
  options,
  className,
  dropdownTitle = <i className='fa-solid fs-3 fw-bold fa-ellipsis cursor-pointer'></i>,
}) => {
  return (
    <Dropdown className={className} onClick={(e) => e.stopPropagation()}>
      <Dropdown.Toggle
        className='hide-dropdown-toggle-icon cursor-pointer'
        id={elementId}
        as={'div'}
      >
        {dropdownTitle}
      </Dropdown.Toggle>
      <Dropdown.Menu className='py-3 shadow'>
        {options.map((option, index) => (
          <Dropdown.Item
            className='text-black fw-bold bg-white'
            key={index}
            onClick={option.handler}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default CustomDropdown
