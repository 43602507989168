const RenderFileIcon = ({fileType, className}: {fileType: any; className: string}) => {
  switch (fileType) {
    case 'application/pdf':
      return <img className={className} src='/media/svg/general/pdf.svg' alt='PDF Icon' />
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <img className={className} src='/media/svg/general/xls.svg' alt='Excel Icon' />
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <img className={className} src='/media/svg/general/doc.svg' alt='Docs Icon' />
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/png':
      return <img className={className} src='/media/svg/general/jpg.svg' alt='Img Icon' />
    case 'text/csv':
      return <img className={className} src='/media/svg/general/csv.svg' alt='Img Icon' />
    case 'folder':
      return <i className='fa-solid fa-folder' style={{fontSize: '140px', color: '#74C0FC'}}></i>
    default:
      return <i className='fa-solid fa-file mb-3' style={{fontSize: '120px', color: '#50BEE8'}}></i>
  }
}

export default RenderFileIcon
