import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem to='/calendar' title='Calendar' icon='/media/svg/general/calendar.svg' />
      <AsideMenuItem
        to='/community'
        title='Member Directory'
        icon='/media/svg/general/address-book.svg'
      />
      <AsideMenuItem to='/leaderboard' title='Leaderboard' icon='/media/svg/general/barcode.svg' />
      <AsideMenuItem to='/resources' title='Resources' icon='/media/svg/general/scan-barcode.svg' />
      <AsideMenuItem to='/notes' title='Notes' icon='/media/svg/general/medal-star.svg' />
    </>
  )
}
