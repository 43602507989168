import {Button, Spinner} from 'react-bootstrap'
import moment from 'moment'
import {Formik, Form, Field} from 'formik'
import {useEffect, useRef} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'

import Image from '../../../modules/common/Image'
import {useFetchThreadMessages, usePostNewThreadMessage} from '../../../queries/threads'

import './Users.css'

const ThreadMessages = ({threadId}: any) => {
  const [searchParams] = useSearchParams()
  const userId = searchParams.get('userId')
  const navigate = useNavigate()
  const threadMessagesResult = useFetchThreadMessages({threadId})
  const threadMessages = threadMessagesResult.data
  const {mutateAsync: postNewThreadMessage, isPending} = usePostNewThreadMessage()

  const initialValues = {
    message: '',
  }

  const messageEndRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({behavior: 'smooth'})
  }

  const onSendNewMessage = async (values: any, {resetForm}: any) => {
    try {
      await postNewThreadMessage({
        id: threadId,
        message: values.message,
      })
      resetForm()
      scrollToBottom()
    } catch (e) {
      console.error('An error occurred while sending a new message', e)
    } finally {
    }
  }

  const showThreadsPage = () => {
    navigate(`?userId=${userId}`)
  }

  useEffect(() => {
    scrollToBottom()
  }, [threadMessages])

  useEffect(() => {
    threadMessagesResult.refetch()
  }, [threadId])

  return (
    <>
      <div className='card py-3 px-4'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            <img className='rounded h40' src={'/media/avatars/IconButton.png'} alt='user-img' />
            <div className='mx-2'>
              <p className='m-0 fw-medium text-gray fs-6'>
                {threadMessages?.messages[0]?.thread?.subject}
              </p>
              <p>
                {moment(threadMessages?.messages[0]?.thread?.createdAt).format(
                  `MMM DD, YYYY [at] hh:mm A`
                )}
              </p>
            </div>
          </div>
          <div>
            <i
              onClick={showThreadsPage}
              className='fa-solid back-arrow rounded bg-secondary fa-arrow-left text-dark mx-3 fs-6 cursor-pointer p-2'
            ></i>
          </div>
        </div>

        <div
          style={{border: '1px solid lightgray', height: '43vh'}}
          className='rounded mb-3 overflow-y-scroll messages-container'
        >
          {threadMessages?.messages.map((messageData: any, index: number) => (
            <div key={index} className='rounded p-2'>
              <div>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex align-items-start'>
                    <Image src={`${messageData.sender?.absoluteProfilePath}`} />
                    <div>
                      <div className='d-flex'>
                        <p className='mx-2 my-0'>{messageData.sender?.fullName}</p>
                        <p className='mx-1 my-0 text-gray'>
                          {threadMessages &&
                            moment(messageData.createdAt).format('MMM DD, YYYY hh:mm:ss')}
                        </p>
                      </div>
                      <p className='mx-2'>{messageData.message}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div ref={messageEndRef} />
        </div>

        <div className='position-relative bottom-0'>
          <Formik initialValues={initialValues} onSubmit={onSendNewMessage}>
            {({values, resetForm}) => (
              <Form>
                <Field
                  as='textarea'
                  name='message'
                  placeholder='Reply..'
                  className='pt-5 outline-none messages-input w-100 resize-none bg-transparent'
                  rows={3}
                  autoFocus={true}
                  onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
                    if (event.key === 'Enter' && !event.shiftKey) {
                      event.preventDefault()
                      if (values.message.trim() !== '') {
                        onSendNewMessage(values, {resetForm})
                      }
                    }
                  }}
                />
                <div className='d-flex justify-content-end'>
                  <div style={{backgroundColor: '#EEF6FF'}} className='px-4 py-3 rounded'>
                    <Button
                      variant='primary'
                      type='submit'
                      disabled={!values.message.trim() || isPending}
                    >
                      {!isPending && 'Send'}
                      {isPending && <Spinner animation='border' />}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default ThreadMessages
