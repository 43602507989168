import React, {useState} from 'react'
import {Button, Card, Offcanvas} from 'react-bootstrap'
import moment from 'moment'

import EmptyMessage from '../../../../modules/common/EmptyMessage'
import {useGetNotes, useDeleteNotes} from '../../../../queries/notes'
import CustomDropdown from '../../../../modules/common/CustomDropDown'
import {ConfirmationModal} from '../../../../modules/common/ConfirmationModal'
import NoteForm from './NoteForm'

interface ModuleNotesProps {
  sectionModule: any
  moduleId: number
  showModuleNotes: any
  setShowModuleNotes: any
}

function truncateText(text: string, maxLength: number) {
  if (text.length <= maxLength) {
    return text
  }
  return text.slice(0, maxLength) + '...'
}

const ModuleNotes: React.FC<ModuleNotesProps> = ({
  sectionModule,
  moduleId,
  setShowModuleNotes,
  showModuleNotes,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [showNoteForm, setShowNoteForm] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [noteToDelete, setNoteToDelete] = useState<any>(null)
  const [noteToEdit, setNoteToEdit] = useState(null)

  const {mutateAsync: deleteNote, isPending: isDeletingNote} = useDeleteNotes()

  const handleClose = () => setShowModuleNotes(false)

  const handleDeleteNote = async () => {
    try {
      await deleteNote(noteToDelete)
      setShowDeleteModal(false)
      setNoteToDelete(null)
    } catch (error) {
      console.error('Error deleting note:', error)
    }
  }

  const notesResult = useGetNotes(Number(moduleId))
  const notes = notesResult.data?.notes || []

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  const confirmDeleteNote = (noteId: any) => {
    setNoteToDelete(noteId)
    setShowDeleteModal(true)
  }

  const handleEditNote = (note: any) => {
    setNoteToEdit(note)
    setShowNoteForm(true)
  }

  return (
    <div>
      <Offcanvas show={showModuleNotes} onHide={handleClose} placement='end' backdrop={false}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Your Notes for "{sectionModule.title}"</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!showNoteForm && (
            <div className='w-75'>
              <Button
                variant='outline-primary'
                className='btn-outline btn-outline-primary mb-5 w-100 rounded-pill'
                onClick={() => {
                  setNoteToEdit(null)
                  setShowNoteForm(true)
                }}
              >
                <i className='fa-solid fa-plus'></i> Add Note
              </Button>
            </div>
          )}
          {showNoteForm && (
            <NoteForm note={noteToEdit} moduleId={moduleId} setShowNoteForm={setShowNoteForm} />
          )}
          {notes.map((note: any) => (
            <div key={note.id}>
              <Card className='notes-card p-5 pb-0 mb-3'>
                <div className='cursor-pointer d-flex justify-content-between align-items-start'>
                  <div className='w-100'>
                    <div className='d-flex justify-content-between'>
                      <div>
                        <h1 className='text-break'>{note.title}</h1>
                      </div>
                    </div>
                    <div onClick={toggleExpand}>
                      <p
                        className='fs-5 mt-5'
                        dangerouslySetInnerHTML={{
                          __html: expanded ? note.description : truncateText(note.description, 200),
                        }}
                      />
                      {note.description.length > 200 && (
                        <button
                          className='expand-btn text-primary bg-transparent border-0 float-right'
                          onClick={toggleExpand}
                        >
                          {expanded ? 'Less' : 'Expand'}
                        </button>
                      )}
                    </div>
                  </div>
                  <div>
                    <CustomDropdown
                      elementId={'note-card'}
                      options={[
                        {
                          label: 'Edit Note',
                          handler: () => handleEditNote(note),
                        },
                        {
                          label: 'Delete Note',
                          handler: () => confirmDeleteNote(note.id),
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className='mt-3 px-4'>
                  <p className='text-end text-gray fw-bold'>{moment(note.createdAt).fromNow()}</p>
                </div>
              </Card>
            </div>
          ))}
          {notes.length === 0 && (
            <div className='mt-5'>
              <EmptyMessage message={'You have no notes'} />
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      {showDeleteModal && (
        <ConfirmationModal
          showModal={showDeleteModal}
          onHideModal={() => setShowDeleteModal(false)}
          onCancel={() => setShowDeleteModal(false)}
          title='Delete Note'
          subtitle='Are you sure you want to delete this note?'
          onConfirm={handleDeleteNote}
          disableBtns={isDeletingNote}
        />
      )}
    </div>
  )
}

export default ModuleNotes
