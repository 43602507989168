import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query'
import {INVITES_QUERY_KEY} from '../constants/reactQueryKeys'
import * as invitesApi from '../apis/invites'
import {toast} from 'react-toastify'
import {parseError} from '../modules/common/utils'

export const useGetInvites = ({
  page,
  role,
  status,
}: {
  page: string | null
  role: string | null
  status: string | null
}) => {
  return useQuery({
    queryKey: [INVITES_QUERY_KEY, {page, role, status}],
    queryFn: () => invitesApi.fetchInvites({page: page || 1, role, status, limit: 2000}),
  })
}

export const useSendInvite = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (inviteData: any) => invitesApi.postInviteUser(inviteData),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [INVITES_QUERY_KEY]})
      toast.success('Invitation sent successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useSendBulkInvites = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (formData: any) => invitesApi.postBulkInvites(formData),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [INVITES_QUERY_KEY]})
      toast.success('Bulk invites sent successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useResendInvites = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (inviteIds: any) => invitesApi.postResendInvites(inviteIds),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [INVITES_QUERY_KEY]})
      toast.success('Invites resent successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useDeleteInvites = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (inviteIds: any) => invitesApi.deleteInvites(inviteIds),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [INVITES_QUERY_KEY]})
      toast.success('Invites deleted successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}
